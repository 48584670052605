import _ from "lodash";
import staffMapper from "@/core/services/helpers/staffMapper.js";
import serviceCategoryMapper from "@/core/services/helpers/serviceCategoryMapper.js";

const placeMapper = {
  /**
   * Map array of place objects
   * @param {Array} places
   * @return {Array}
   */
  mapPlaces: function(places) {
    return places.map(place => {
      return this.mapPlace(place);
    });
  },

  /**
   * Map place object
   * @param {Object} place
   * @return {Object}
   */
  mapPlace: function(place) {
    if (place.staff) {
      place.staff = staffMapper.mapStaff(place.staff);
    }
    if (place.services) {
      place.services = serviceCategoryMapper.mapServiceCategories(
        place.services
      );
    }
    return _.mapKeys(place, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of place objects to options for select component
   * @param {Array} places
   * @return {Array}
   */
  mapPlacesAsOptions(places) {
    let options = [];
    places.forEach(place => {
      let option = {};
      option.text = place.name;
      option.value = place.id;
      options.push(option);
    });
    return options;
  }
};

export default placeMapper;
