import httpService from "@/core/services/http.service.js";
import placeMapper from "@/core/services//helpers/placeMapper.js";

// action types
export const GET_PLACES = "getPlaces";
export const SET_PLACE = "setPlace";
export const GET_CURRENT_PLACE = "getCurrentPlace";

// mutations types
export const SET_PLACES = "setPlaces";
export const SET_CURRENT_PLACE = "setCurrentPlace";

const state = {
  places: [],
  currentPlace: {},
};

const getters = {
  allPlaces(state) {
    return state.places;
  },
  currentPlace(state) {
    return state.currentPlace;
  },
  placesAsOptions(state) {
    return placeMapper.mapPlacesAsOptions(state.places);
  },
  getPlace: (state) => (id) => state.places.find((place) => place.id === id),
};

const actions = {
  [GET_PLACES](context) {
    return httpService
      .get("/api/b2b/places")
      .then((response) => context.commit(SET_PLACES, response.data.data));
  },
  [SET_PLACE](context, place) {
    context.commit(SET_CURRENT_PLACE, place);
  },
};

const mutations = {
  [SET_PLACES](state, places) {
    state.places = placeMapper.mapPlaces(places);
    if (state.places.length > 0) {
      if (state.currentPlace?.id) {
        let currentPlaceIndex = state.places.findIndex(
          (place) => place.id === state.currentPlace.id
        );
        if (currentPlaceIndex !== -1) {
          state.currentPlace = state.places[currentPlaceIndex];
        } else {
          state.currentPlace = state.places[0];
        }
      } else {
        state.currentPlace = state.places[0];
      }
    }
  },
  [SET_CURRENT_PLACE](state, place) {
    state.currentPlace = place;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
