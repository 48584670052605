import _ from "lodash";

const companyMapper = {
  /**
   * Map array of company objects
   * @param {Array} companies
   * @return {Array}
   */
  mapCompanies: function(companies) {
    return companies.map(company => {
      return this.mapCompany(company);
    });
  },

  /**
   * Map company object
   * @param {Object} company
   * @return {Object}
   */
  mapCompany: function(company) {
    return _.mapKeys(company, (value, key) => _.camelCase(key));
  }
};

export default companyMapper;
