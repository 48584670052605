import formHelper from "@/core/services/helpers/formHelper.js";
import httpService from "@/core/services/http.service.js";

// action types
export const INIT_FORM = "initForm";
export const SET_FORM_FIELD = "setFormField";
export const REMOVE_FORM_FIELD = "removeFormField";
export const RESET_FORM = "resetForm";
export const SUBMIT_FORM = "submitForm";
export const SET_FORM_ERRORS = "setErrors";
export const UNSET_FORM_ERRORS = "unsetFormErrors";
export const UNSET_FORM = "unsetForm";

// mutation types
export const PUSH_FORM = "pushForm";
export const PUSH_FORM_FIELD = "pushFormField";
export const UNSET_FORM_FIELD = "unsetFormField";
export const RESET_FORM_FIELDS = "resetFormFileds";
export const PUSH_FORM_ERRORS = "pushFormErrors";
export const CLEAR_FORM_ERRORS = "clearFormErrors";
export const REMOVE_FORM = "removeForm";

const state = {
  forms: []
};

const getters = {
  getForm: state => formKey => state.forms.find(form => form.key === formKey)
};

const actions = {
  [INIT_FORM](context, form) {
    context.commit(PUSH_FORM, form);
  },
  [SET_FORM_FIELD](context, { formKey, fieldName, value }) {
    context.commit(PUSH_FORM, formKey);
    context.commit(PUSH_FORM_FIELD, { formKey, fieldName, value });
  },
  [REMOVE_FORM_FIELD](context, { formKey, fieldName }) {
    context.commit(UNSET_FORM_FIELD, { formKey, fieldName });
  },
  [RESET_FORM](context, formKey) {
    context.commit(RESET_FORM_FIELDS, formKey);
  },
  [SUBMIT_FORM](context, formKey) {
    let form = context.getters.getForm(formKey);
    if (form) {
      form.body = formHelper.getBody(form.fields);
      return httpService.post(form.path, form.body, {
        headers: { formKey: form.key }
      });
    }
  },
  [SET_FORM_ERRORS](context, { key, errors }) {
    context.commit(PUSH_FORM_ERRORS, { key, errors });
  },
  [UNSET_FORM_ERRORS](context, formKey) {
    context.commit(CLEAR_FORM_ERRORS, formKey);
  },
  [UNSET_FORM](context, formKey) {
    context.commit(REMOVE_FORM, formKey);
  }
};

const mutations = {
  [PUSH_FORM](state, { key, mode, path, fields, errors, helpers }) {
    let formIndex = state.forms.findIndex(form => form.key === key);
    if (formIndex !== -1) {
      let forms = state.forms;
      forms[formIndex] = {
        key: key,
        mode: mode,
        path: path,
        fields: fields,
        errors: errors,
        helpers: helpers
      };
      state.forms = [];
      state.forms = forms;
      return;
    }
    let form = {
      key: key,
      mode: mode,
      path: path,
      fields: fields,
      errors: errors,
      helpers: helpers
    };
    state.forms.push(form);
  },
  [PUSH_FORM_FIELD](state, { formKey, fieldName, value }) {
    let forms = state.forms;
    let form = forms.find(form => form.key === formKey);
    state.forms = [];
    form.fields[fieldName] = value;
    state.forms = forms;
  },
  [UNSET_FORM_FIELD](state, { formKey, fieldName }) {
    let forms = state.forms;
    let form = forms.find(form => form.key === formKey);
    state.forms = [];
    delete form.fields[fieldName];
    state.forms = forms;
  },
  [RESET_FORM_FIELDS](state, formKey) {
    let forms = state.forms;
    let form = forms.find(form => form.key === formKey);
    state.forms = [];
    form.fields = {};
    state.forms = forms;
  },
  [PUSH_FORM_ERRORS](state, { key, errors }) {
    let forms = state.forms;
    let form = forms.find(form => form.key === key);
    if (form) {
      state.forms = [];
      form.errors = formHelper.parseErrors(errors);
      state.forms = forms;
    } else {
      throw new Error("Unknown form with key " + key);
    }
  },
  [CLEAR_FORM_ERRORS](state, formKey) {
    let forms = state.forms;
    let form = forms.find(form => form.key === formKey);
    if (form) {
      state.forms = [];
      form.errors = {};
      state.forms = forms;
    }
  },
  [REMOVE_FORM](state, formKey) {
    let forms = state.forms;
    state.forms = [];
    forms.forEach(form => {
      if (form.key !== formKey) {
        state.forms.push(form);
      }
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
