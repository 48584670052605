import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import MockService from "@/core/mock/mock.service";
import aclService from "@/core/services/acl.service";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "leaflet/dist/leaflet.css";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

import { init } from "@/core/services/http.service";

// Sentry.init({
//   Vue,
//   dsn: "https://86031d2020d64151ba6f5fffc87f87d3@o275620.ingest.sentry.io/6159707",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "my-site-url.com", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// pass store to http service
init(store);

// Remove this to disable mock API
MockService.init();

// Global vue mixin
Vue.mixin({
  data() {
    return {
      acl: aclService
    };
  }
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.directive("click-outside", {
  bind() {
    this.event = event => this.vm.$emit(this.expression, event);
    this.el.addEventListener("click", this.stopProp);
    document.body.addEventListener("click", this.event);
  },
  unbind() {
    this.el.removeEventListener("click", this.stopProp);
    document.body.removeEventListener("click", this.event);
  },

  stopProp(event) {
    event.stopPropagation();
  }
});

Vue.prototype.$hasPermission = function(permission) {
  if (store.getters.currentUser) {
    if (store.getters.currentUser.permissions) {
      return store.getters.currentUser.permissions.includes(permission);
    }
    return false;
  }
  return false;
};

Vue.prototype.$hasOnlyBasePermission = function() {
  if(store.getters.currentUser) {
    if(store.getters.currentUser.permissions) {
      return store.getters.currentUser.permissions.length == 1 &&
        store.getters.currentUser.permissions[0] == aclService.permissions.manageOwnCalendar
    }
    return false;
  }
  return false;
}

Vue.prototype.$hasRole = function(role) {
  if (store.getters.currentUser) {
    if (store.getters.currentUser.roles) {
      return store.getters.currentUser.roles.includes(role);
    }
    return false;
  }
  return false;
};
