import _ from "lodash";

const categoryMapper = {
  /**
   * Map array of category objects
   * @param {Array} categories
   * @return {Array}
   */
  mapCategories: function(categories) {
    return categories.map(category => {
      return this.mapCategory(category);
    });
  },

  /**
   * Map category object
   * @param {Object} category
   * @return {Object}
   */
  mapCategory: function(category) {
    return _.mapKeys(category, (value, key) => _.camelCase(key));
  }
};

export default categoryMapper;
