import _ from "lodash";
import moment from "moment";

const formHelper = {
  /**
   * Accept fields object and return form data object
   * @param {Object} fields
   * @return {Object}
   */
  getBody: function(fields) {
    let formData = new FormData();
    for (const [key, value] of Object.entries(fields)) {
      if (key === "_method") {
        formData.append(key, value);
      } else {
        let fieldValue = value;
        if (_.isBoolean(fieldValue)) {
          formData.append(_.snakeCase(key), +fieldValue);
        } else if (_.isObject(fieldValue)) {
          if (fieldValue instanceof Date) {
            formData.append(
              _.snakeCase(key),
              moment(fieldValue).format("YYYY-MM-DD")
            );
          } else if (fieldValue instanceof File || fieldValue instanceof Blob) {
            formData.append(_.snakeCase(key), fieldValue);
          } else {
            this.objectToFormData(fieldValue, formData, _.snakeCase(key));
          }
        } else {
          formData.append(_.snakeCase(key), fieldValue ?? "");
        }
      }
    }

    return formData;
  },

  /**
   * Accept errors object and return parsed errors object
   * @param {Object} errors
   * @return {Object}
   */
  parseErrors: function(errors) {
    let formErrors = {};
    for (const [key, value] of Object.entries(errors)) {
      let errorMessage = "";
      value.forEach(error => {
        errorMessage += error;
      });
      formErrors[_.camelCase(key)] = errorMessage;
    }
    return formErrors;
  },

  /**
   * Append object to form data
   * @param {Object} object
   * @param {FormData} formData
   * @param {String} namespace
   * @return {void}
   */
  objectToFormData: function(object, formData, namespace) {
    let fd = formData || new FormData();
    let fieldKey;
    for (let property in object) {
      if (object.hasOwnProperty(property)) {
        if (namespace) {
          fieldKey = namespace + "[" + _.snakeCase(property) + "]";
        } else {
          fieldKey = _.snakeCase(property);
        }
        // if the property is an object, but not a File,
        // use recursivity.
        if (
          typeof object[property] === "object" &&
          !(object[property] instanceof File)
        ) {
          this.objectToFormData(object[property], fd, fieldKey);
        } else {
          // if it's a string or a File object
          fd.append(fieldKey, object[property]);
        }
      }
    }

    return fd;
  }
};

export default formHelper;
