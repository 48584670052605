import httpService from "@/core/services/http.service.js";
import categoryMapper from "@/core/services/helpers/categoryMapper.js";

// action types
export const GET_CATEGORIES = "getCategories";

// mutations types
export const SET_CATEGORIES = "setCategories";

const state = {
  categories: []
};

const getters = {
  allCategories(state) {
    return state.categories;
  }
};

const actions = {
  [GET_CATEGORIES](context) {
    httpService
      .get("/api/category")
      .then(response => context.commit(SET_CATEGORIES, response.data.data));
  }
};

const mutations = {
  [SET_CATEGORIES](state, categories) {
    state.categories = categoryMapper.mapCategories(categories);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
