import _ from "lodash";

// const staffColors = {
  /**
   * Array of available colors in class names
   * @type {Array}
   *
  colors: [
    "success-dark",
    "danger",
    "primary",
    "warning",
    "success-light",
    "info",
    "info-light",
    "warning-dark",
    "info-gray",
    "primary-light"
  ],

  /**
   * Array of available colors in hex format
   * @type {Array}
   *
  hexColors: [
    "#1A73E8",
    "#F64E60",
    "#1BC5BD",
    "#FFC812",
    "#91E3EE",
    "#E2A6E6",
    "#FF9CBB",
    "#FFBF69",
    "#BBC1E8",
    "#A6E5BD"
  ],*/

  /**
   * Used in getColors method
   * @type {null|Number}
   *
  lastUsedColorIndex: null,

  /**
   * Return array of colors for any number given
   * @param {Number} numOfColorsToReturn
   * @return {Array}
   *
  getColors: function(numOfColorsToReturn) {
    let colors = [];
    for (let i = 0; i < numOfColorsToReturn; i++) {
      if (this.lastUsedColorIndex === null) {
        this.lastUsedColorIndex = 0;
        colors.push(this.colors[0]);
      } else {
        let nextColorIndex = this.lastUsedColorIndex + 1;
        if (nextColorIndex <= this.colors.length - 1) {
          colors.push(this.colors[nextColorIndex]);
          this.lastUsedColorIndex = nextColorIndex;
        } else {
          this.lastUsedColorIndex = 0;
          colors.push(this.colors[0]);
        }
      }
    }
    this.lastUsedColorIndex = null;
    return colors;
  },

  *
   * Return array of hex colors for any number given
   * @param {Number} numOfColorsToReturn
   * @return {Array}
   *
  getHexColors: function(numOfColorsToReturn) {
    let colors = [];
    for (let i = 0; i < numOfColorsToReturn; i++) {
      if (this.lastUsedColorIndex === null) {
        this.lastUsedColorIndex = 0;
        colors.push(this.hexColors[0]);
      } else {
        let nextColorIndex = this.lastUsedColorIndex + 1;
        if (nextColorIndex <= this.hexColors.length - 1) {
          colors.push(this.hexColors[nextColorIndex]);
          this.lastUsedColorIndex = nextColorIndex;
        } else {
          this.lastUsedColorIndex = 0;
          colors.push(this.hexColors[0]);
        }
      }
    }
    this.lastUsedColorIndex = null;
    return colors;
  }*/
// };

const staffMapper = {
  /**
   * Map array of staff member objects
   * @param {Array} staff
   * @param {Boolean} hexColors
   * @return {Array}
   */
  mapStaff: function(staff) {
    // let colors = staffColors.getColors(staff.length);
    // let hexColors = staffColors.getHexColors(staff.length);
    return staff.map(staffMember => {
      staffMember.fullName =
        staffMember.first_name + " " + staffMember.last_name;
      staffMember.initials =
        staffMember.first_name.charAt(0).toUpperCase() +
        staffMember.last_name.charAt(0).toUpperCase();
        // staffMember.hexColor = hexColors[index];
      staffMember.color = staffMember.calendar.color;
      return this.mapStaffMember(staffMember);
    });
  },

  /**
   * Map staff member object
   * @param {Object} staffMember
   * @return {Object}
   */
  mapStaffMember: function(staffMember) {
    let servicesIds = [];
    if (staffMember.services) {
      staffMember.services.forEach(service => servicesIds.push(service.id));
    }
    staffMember.servicesIds = servicesIds;
    staffMember.calendar = _.mapKeys(staffMember.calendar, (value, key) =>
      _.camelCase(key)
    );
    staffMember.calendar.settings = _.mapKeys(
      staffMember.calendar.settings,
      (value, key) => _.camelCase(key)
    );
    return _.mapKeys(staffMember, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of staff member objects as options
   * @param {Array} staff
   * @return {Array}
   */
  mapStaffAsOptions: function(staff) {
    let options = [];
    staff.forEach(staffMember => {
      let option = {};
      option.text = staffMember.fullName;
      option.value = staffMember.calendar.id;
      option.description = staffMember.email;
      option.initials = staffMember.initials;
      option.color = staffMember.color;
      options.push(option);
    });
    return options;
  }
};

export default staffMapper;
