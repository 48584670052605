import _ from "lodash";
import serviceMapper from "@/core/services/helpers/serviceMapper.js";

const serviceCategoryMapper = {
  /**
   * Map array of serviceCategory objects
   * @param {Array} serviceCategories
   * @return {Array}
   */
  mapServiceCategories: function(serviceCategories) {
    return serviceCategories.map(serviceCategory => {
      return this.mapServiceCategory(serviceCategory);
    });
  },

  /**
   * Map serviceCategory object
   * @param {Object} serviceCategory
   * @return {Object}
   */
  mapServiceCategory: function(serviceCategory) {
    serviceCategory.services = serviceMapper.mapServices(
      serviceCategory.services
    );
    return _.mapKeys(serviceCategory, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of serviceCategory objects to options for select component
   * @param {Array} serviceCategories
   * @return {Array}
   */
  mapServiceCategoriesAsOptions(serviceCategories) {
    let options = [];
    serviceCategories.forEach(serviceCategory => {
      let option = {};
      option.text = serviceCategory.name;
      option.options = serviceMapper.mapServicesAsOptions(
        serviceCategory.services
      );
      options.push(option);
    });
    return options;
  }
};

export default serviceCategoryMapper;
