import axios from "axios";
import i18nService from "@/core/services/i18n.service.js";
import { PURGE_AUTH } from "@/core/services/store/auth.module";
import {
  SET_FORM_ERRORS,
  UNSET_FORM_ERRORS
} from "@/core/services/store/form.module";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true
});

export function init(store) {
  http.interceptors.request.use(config => {
    const formKey = config.headers ? config.headers.formKey : null;
    if (formKey) {
      store.dispatch(UNSET_FORM_ERRORS, formKey);
    }

    config.headers['X-Language'] = i18nService.getActiveLanguage();
    
    return config;
  });

  http.interceptors.response.use(
    config => config,
    error => {
      const { response, config } = error;
      const { headers } = config;
      if (response.status === 401 || response.status == 419) {
        store.commit(PURGE_AUTH);
      }
      if (response.status === 422 && headers.formKey) {
        store.dispatch(SET_FORM_ERRORS, {
          key: headers.formKey,
          errors: response.data.errors
        });
      }
      return Promise.reject(error);
    }
  );
}

export default http;
