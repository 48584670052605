import _ from "lodash";
import staffMapper from "./staffMapper";

const statisticMapper = {
  /**
   * Map array of statistic objects
   * @param {Array} statistics
   * @return {Array}
   */
  mapStatistics: function(statistics) {
    return statistics.map(statistic => {
      return this.mapStatistic(statistic);
    });
  },

  /**
   * Map statistic object
   * @param {Object} statistic
   * @return {Object}
   */
  mapStatistic: function(statistic) {
    let mappedStatistic = _.mapKeys(statistic, (value, key) => {
      if (key === "staff_sales" && value) {
        value = staffMapper.mapStaff(value);
        return _.camelCase(key);
      }
      if (_.isArray(value)) {
        value.forEach((element, index) => {
          if (_.isObject(element)) {
            value[index] = _.mapKeys(element, (value, key) => _.camelCase(key));
          }
        });
        return _.camelCase(key);
      }
      if (_.isObject(value)) {
        value = _.mapKeys(value, (val, key) => _.camelCase(key));
      }
      return _.camelCase(key);
    });

    return mappedStatistic;
  }
};

export default statisticMapper;
