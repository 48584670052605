const aclService = {
  roles: {
    owner: "Company Owner",
    staffMember: "Staff Member"
  },
  permissions: {
    manageAccount: "manage account",
    managePlace: "manage place",
    manageBookings: "manage bookings",
    manageOwnCalendar: "manage own calendar"
  }
};

export default aclService;
