import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/calendar",
      component: () => import("@/view/layout/Layout"),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "/calendar",
          name: "calendar",
          component: () => import("@/view/pages/Calendar.vue")
        },
        {
          path: "/clients",
          name: "clients",
          component: () => import("@/view/pages/Clients.vue")
        },
        {
          path: "/clients/profile/:id",
          name: "clientProfile",
          component: () => import("@/view/pages/ClientProfile.vue")
        },
        {
          path: "/services",
          name: "services",
          component: () => import("@/view/pages/Services.vue")
        },
        {
          path: "/subjects",
          name: "subjects",
          component: () => import("@/view/pages/Subjects.vue")
        },
        {
          path: "/subjects/:id",
          name: "subject",
          component: () => import("@/view/pages/Subject.vue")
        },
        {
          path: "/staff",
          name: "staff",
          component: () => import("@/view/pages/Staff.vue")
        },
        {
          path: "/statistics",
          name: "statistics",
          component: () => import("@/view/pages/Statistics.vue")
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/Profile.vue")
        },
        {
          path: "/settings/:tab?",
          name: "settings",
          component: () => import("@/view/pages/Settings.vue")
        }
      ]
    },
    {
      name: "login",
      path: "/login",
      component: () => import("@/view/pages/auth/Login"),
      meta: {
        guest: true
      }
    },
    {
      name: "forgotPassword",
      path: "/forgot-password",
      component: () => import("@/view/pages/auth/ForgotPassword"),
      meta: {
        guest: true
      }
    },
    {
      name: "resetPassword",
      path: "/reset-password/:token?",
      component: () => import("@/view/pages/auth/ResetPassword"),
      meta: {
        guest: true
      }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
    } else {
      next({
        name: "login"
      });
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next({
        name: "calendar"
      });
    } else {
      next();
    }
  }
  next();

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
