// USA
export const locale = {
  login: {
    title: "Welcome back",
    emailLabel: "E-mail",
    emailValidationError: "Email is required and a valid email address.",
    passwordLabel: "Password",
    passwordValidationError: "Password is required.",
    keepMeLabel: "Keep me logged in",
    forgotPassword: "Forgot Password ?",
    loginBtn: "Log In",
    dontHaveAccount: "Dont have an account?",
    signUp: "Sign up",
  },
  forgotPassword: {
    title: "Forgot your password?",
    emailInput: {
      label: "Email",
      description: "Enter your email to get reset password link",
    },
    submitBtn: "Send",
  },
  resetPassword: {
    title: "Reset password",
    passwordInput: {
      label: "New password",
    },
    passwordConfirmationInput: {
      label: "Confirm new password",
    },
    submitBtn: "Reset password",
  },
  menu: {
    calendar: "Calendar",
    clients: "Clients",
    services: "Services",
    staff: "Staff",
    statistics: "Statistics",
    settings: "Settings",
    profile: "Profile",
    contactSupport: "Contact support",
    logout: "Logout",
  },
  calendar: {
    title: "Calendar",
    alert: {
      title: "Welcome to Your Calendar",
      text: "Appointments that customers or you book will appear here. Click on an appointment to see the details and manage it.",
    },
    newEventActions: {
      appointment: "Appointment",
      appointmentMobile: "New Appointment",
      groupAppointment: "Group appointment",
      blockTime: "Block Time",
      close: "Close",
    },
    appointment: {
      cancelAppointment: "Cancel appointment",
      editAppointment: "Edit appointment",
      notesLabel: "Notes",
      noNotesText: "No notes for this appointment",
      employee: "Employee",
    },
    blockTime: {
      removeBlockTime: "Remove block time",
      editBlockTime: "Edit block time",
    },
    calendarEventCard: {
      clientsLabel: "clients"
    },
  },
  calendarFilters: {
    placeFilter: {
      createPlace: "Create new place",
    },
    staffFilter: {
      allStaff: "All staff",
      createStaff: "Create new staff",
    },
    viewFilter: {
      week: "week",
      day: "day",
    },
    mobileViewFilter: {
      title: "Calendar view",
      day: "Day",
      threeDays: "3 Days",
    },
    mobilePlaceAndStaffFilter: {
      title: "Filter",
      placesLabel: "Places",
      staffLabel: "Staff",
      allStaffLabel: "All staff",
    },
  },
  appointmentForm: {
    createTitle: "New appointment",
    createGroupTitle: "New group appointment",
    editTitle: "Edit appointment",
    editGroupTitle: "Edit group appointment",
    createSubmitBtn: "Create appointment",
    editSubmitBtn: "Save",
    noDateLabel: "Add date",
    clientInput: {
      label: "Client",
      placeholder: "Add a client",
      searchPlaceholder: "Search Client",
      noResultsText: "No client available",
      editBtn: "edit details",
      removeBtn: "remove from appointment",
      createBtn: "Create new client",
      details: {
        title: "Client Details",
        emailLabel: "Email",
        phoneLabel: "Phone number",
        notesLabel: "Client notes",
      },
    },
    clientsInputBtn: "Select clients",
    serviceInput: {
      label: "Service",
      placeholder: "Add a service",
      searchPlaceholder: "Search for service",
      noResultsText: "No service available",
      createBtn: "Create new service",
    },
    staffInput: {
      label: "Staff",
      placeholder: "Choose staff",
      searchPlaceholder: "Search staff",
      noResultsText: "No staff available",
      createBtn: "Create new staff",
    },
    startTimeInput: {
      label: "Start time",
      placeholder: "Choose a time",
      noResultsText: "No available slot",
    },
    durationLabel: "Duration",
    notesInput: {
      label: "Appointment notes",
      placeholder: "Add an appointment note (visible only to staff)",
    },
  },
  blockTimeForm: {
    title: "Block Time",
    createSubmitBtn: "Create blocked time",
    editSubmitBtn: "Edit blocked time",
    dateLabel: "Add date",
    staffInput: {
      label: "Staff",
      placeholder: "Choose staff",
      searchPlaceholder: "Search staff",
      noResultsText: "No staff available",
      createBtn: "Create new staff",
    },
    startTimeInput: {
      label: "Start time",
      placeholder: "Start time",
      noResultsText: "No available slot",
    },
    endTimeInput: {
      label: "End time",
      placeholder: "End time",
      noResultsText: "No available slot",
    },
    notesInput: {
      label: "Description",
      placeholder: "Eg. Lunch meeting",
    },
  },
  dateInput: {
    appointmentTitle: "Appointment date",
    blockTimeTitle: "Choose date",
    mobileTitle: "Pick date(s)",
    saveBtn: "Save",
  },
  calendarForm: {
    createTitle: "New staff",
    editTitle: "Edit staff",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    mobileButtons: {
      sendResetPassword: "Send reset password email",
      delete: "Delete staff",
      close: "Close",
    },
    basicSettings: {
      title: "Basic settings",
      profilePictureInput: {
        label: "Profile picture (optional, but highly recommended)",
        description: "This photo will be displayed on your profile page",
        mobileLabel: "Profile picture",
        mobileDescription: "(optional, but highly recommended)",
        uploadBtn: "Upload profile picture",
        deleteBtn: "Delete",
      },
      nameInput: {
        label: "Name",
        placeholder: "Eg. Linda",
      },
      titleInput: {
        label: "Title",
        placeholder: "Eg. Hairstylist",
      },
      personalDetails: {
        label: "Personal details",
        description:
          "This information is only used for internal communication and notifications.",
      },
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      roleInput: {
        label: "Role",
        manageAccountLabel: "Manage account",
        managePlaceLabel: "Manage place",
        manageBookingsLabel: "Manage bookings for place",
        manageCalendarLabel: "Manage own calendar",
      },
    },
    availability: {
      title: "Availability",
      description:
        "Chose between which time clients are able to book services on this calendar",
      editBtn: "Edit",
      removeBtn: "Remove",
      addDayOffBtn: "+ Add specific Day Off",
      closedLabel: "Closed",
    },
    placeAndServices: {
      title: "Place and services",
      placeInput: {
        label: "Which place does this calendar belong to?",
      },
      servicesInput: {
        label: "Which services can be booked with this calendar?",
      },
    },
    advancedSettings: {
      title: "Advanced Settings",
      requiredInfoInput: {
        label: "Which information is required from the client when they book?",
        firstNameLabel: "First name",
        lastNameLabel: "Last name",
        emailLabel: "Email",
        phoneLabel: "Phone number",
        addressLabel: "Address",
        zipCodeLabel: "Postal code",
        cityLabel: "City",
        otherLabel: "Other",
      },
      otherInput: {
        label: 'Alias for "Other"',
        placeholder: "Other",
      },
      cancellationMarginInput: {
        label: "Cancellation margin",
        description:
          "Until how long before the booking is it possible to cancel?",
        locationDescription:
          "Select the location for which you want to edit booking cancelations.",
        daysLabel: "days",
        hoursLabel: "hours",
        minutesLabel: "minutes",
      },
      bookingMarginInput: {
        label: "Booking margin",
        description: "How close to an available time is it possible to book?",
        daysLabel: "days",
        hoursLabel: "hours",
        minutesLabel: "minutes",
      },
      tocInput: {
        label: "Terms and Conditions (optional)",
        description:
          "If you add terms and conditions, clients will have to agree to them by reading and checking a box in order to create a booking.",
      },
    },
    notifications: {
      title: "Notifications",
      toServiceProviderInput: {
        label: "To service provider",
        description:
          'Notifications will be sent to the email that is provided in "Personal Details"',
        bookingConfirmationLabel: "Booking confirmation",
        cancellationConfirmationLabel: "Cancellation confirmation",
      },
      toCustomerInput: {
        label: "To customer",
        description:
          "Notifications will be sent to the email that is provided by the customer",
        bookingConfirmationLabel: "Booking confirmation",
        cancellationConfirmationLabel: "Cancellation confirmation",
        reminderLabel: "Reminder of booking",
        followUpLabel: "Follow-Up",
      },
      followUpInput: {
        label: "Follow-Up",
        description:
          "Incrase the chance of recurring bookings with sendings follow-up email",
        daysLabel: "days after booked time",
      },
    },
  },
  calendarFormModal: {
    createTitle: "Add new staff",
    editTitle: "Edit staff",
    createBtn: "Add staff",
    editBtn: "Save",
    basicInfoCard: {
      title: "Basic info",
      nameInput: {
        label: "Name",
      },
      titleInput: {
        label: "Staff title",
        description: "This title will be visible to clients",
      },
      notesInput: {
        label: "Notes",
        labelDescription: "(optional)",
        placeholder: "Add private notes viewable in staff settings only",
      },
      contactTitle: "Contact",
      contactDescription:
        "Staff contacts are confidential and won't be shared with your clients.",
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      employmentTitle: "Employment",
      employmentStartInput: {
        label: "Start date",
      },
      employmentEndInput: {
        label: "End date",
      },
    },
    placesCard: {
      title: "Works at",
      description: "Choose the locations where this staff member works.",
      placesInput: {
        allLabel: "All locations",
      },
    },
    bookingCard: {
      title: "Booking",
      bookingsEnabledInput: {
        label: "Allow calendar bookings",
        description:
          "Allow this staff member to recive bookings on the calendar.",
      },
    },
    colorCard: {
      title: "Calendar color",
      description:
        "Choose a color to see this staff members appointments in the calendar",
      colorInput: {
        label: "Select color",
      },
    },
    servicesCard: {
      title: "Services",
      description: "Add the services this staff member can offer.",
      servicesInput: {
        allSelectedLabel: "All services",
        countSelectedLabel: "Selected services",
        noSelectedLabel: "No selected services",
        editBtn: "Edit",
      },
    },
    clientsCard: {
      title: "Clients",
      description:
        "Add clients that employee will see even if has only base permissions",
      clientsInput: {
        allSelectedLabel: "All clients",
        countSelectedLabel: "Selected clients",
        noSelectedLabel: "No selected clients",
        editBtn: "Edit",
      },
    },
    permissionsCard: {
      title: "Staff permissions",
    },
    selectServicesModal: {
      title: "Select services",
      searchInput: {
        placeholder: "Search services",
      },
      allServicesLabel: "All services",
      closeBtn: "Cancel",
      saveBtn: "Save",
    },
    selectClientsModal: {
      title: "Select clients",
      searchInput: {
        placeholder: "Search clients",
      },
      allClientsLabel: "All clients",
      closeBtn: "Cancel",
      saveBtn: "Save",
    },
    validation: {
      error: "Field is invalid",
    },
  },
  clients: {
    title: "Clients",
    searchInput: {
      placeholder: "Search by name, email or phone number",
    },
    createClientBtn: "New client",
    clientDropdown: {
      showBtn: "See profile",
      editBtn: "Edit details",
      deleteBtn: "Delete client",
    },
    table: {
      columns: {
        name: "Name",
        phone: "Phone number",
        email: "Email",
      },
      pagination: {
        show: "Showing",
        of: "of",
      },
    },
    mobileSearchInput: {
      placeholder: "Search by client name",
    },
  },
  clientProfile: {
    title: "Client Profile",
    createAppointmentDropdown: {
      toggleBtn: "Scheduling",
      appointmentBtn: "New appointment",
      groupAppointmentBtn: "New group appointment"
    },
    dropdown: {
      editBtn: "Edit details",
      deleteBtn: "Delete client",
    },
    infoCard: {
      email: {
        label: "Email",
      },
      phone: {
        label: "Phone number",
      },
      notes: {
        label: "Client notes",
      },
      notifications: {
        label: "Notifications",
        enabledLabel: "Enabled",
        disabledLabel: "Disabled",
        viaLabel: "Notifications Type",
        emailLabel: "Via Email",
        unknownLabel: "Unknown",
      },
    },
    salesCard: {
      title: "Total sales",
      bookings: {
        all: "All bookings",
        completed: "Completed",
        canceled: "Cancelled",
      },
    },
    mobileButtons: {
      editBtn: "Edit details",
      deleteBtn: "Delete client",
      closeBtn: "Close",
    },
  },
  appointmentList: {
    title: "Previous appointments",
    noAppointmentsLabel: "No previous appointments",
    status: {
      active: "Active",
      finished: "Finished",
      canceled: "Canceled",
    },
  },
  clientForm: {
    createTitle: "Create new client",
    editTitle: "Edit client",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    contactDetails: {
      title: "Contact details",
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      notificationsInput: {
        label: "Notifications",
        description: "Notifications will be sent to the email provided above",
        notificationsLabel: "Notifications",
      },
    },
    aditionalInfo: {
      title: "Aditional information (optional)",
      addressInput: {
        label: "Address",
      },
      cityInput: {
        label: "City",
      },
      zipCodeInput: {
        label: "Zip / Post code",
      },
      notesInput: {
        label: "Client notes",
        placeholder: "Eg. Allergic to nitrile-gloves",
      },
    },
  },
  clientFormModal: {
    createTitle: "New client",
    editTitle: "Edit client",
    saveBtn: "Save",
    closeBtn: "Cancel",
    deleteBtn: "Delete",
    tabs: {
      personalInfo: "Personal Info",
      address: "Address",
    },
    firstNameInput: {
      label: "First name",
    },
    lastNameInput: {
      label: "Last name",
    },
    mobilePhoneInput: {
      label: "Mobile",
    },
    phoneInput: {
      label: "Phone",
    },
    emailInput: {
      label: "Email",
    },
    sendNotificationsByInput: {
      label: "Send notifications by",
      options: {
        email: "Email",
        dontSend: "Don't send notifications",
      },
    },
    notificationsEnabledInput: {
      label: "Accepts marketing notifications",
    },
    genderInput: {
      label: "Gender",
      options: {
        unknown: "Unknown",
        male: "Male",
        female: "Female",
      },
    },
    birthdayInput: {
      label: "Birthday",
      monthPlaceholder: "Month",
      dayPlaceholder: "Day",
      yearPlaceholder: "Year",
    },
    notesInput: {
      label: "Client notes",
    },
    displayNotesInput: {
      label: "Display on all bookings",
    },
    addressInput: {
      label: "Address",
    },
    cityInput: {
      label: "City",
    },
    stateInput: {
      label: "State",
    },
    zipCodeInput: {
      label: "Zip / Post Code",
    },
    mobileSection: {
      notesSection: {
        title: "Client Notes",
        notesInput: {
          label: "Client notes",
        },
        displayNotesInput: {
          label: "Display on all booking",
        },
      },
      additionalInfoSection: {
        title: "Additional Information",
        personalInfoBtn: "Personal info",
        addressDetailsBtn: "Address details",
      },
    },
    validation: {
      error: "Field is required",
    },
  },
  addressDetailsMobileModal: {
    title: "Address details",
    addressInput: {
      label: "Address",
    },
    cityInput: {
      label: "City",
    },
    stateInput: {
      label: "State",
    },
    zipCodeInput: {
      label: "Zip / Post Code",
    },
    saveBtn: "Save",
  },
  personalInfoMobileModal: {
    title: "Personal Info",
    genderInput: {
      label: "Gender",
    },
    birthdayInput: {
      label: "Birthday",
      monthPlaceholder: "Month",
      dayPlaceholder: "Day",
      yearPlaceholder: "Year",
    },
    saveBtn: "Save",
  },
  services: {
    title: "Services",
    createServiceBtn: "Add new",
    table: {
      createServiceBtn: "Add service",
      dropdown: {
        createServiceBtn: "Add new service",
        editCategoryNameBtn: "Edit category name",
        deleteCategoryBtn: "Delete category",
      },
      editBtn: "Edit",
      minutesLabel: "minutes",
      noDescriptionLabel: "No service description avaliable.",
      groupServiceLabel: "Class",
    },
    mobileButtons: {
      createServiceBtn: "Add new service",
      editCategoryNameBtn: "Edit category name",
      deleteCategoryBtn: "Delete category",
      closeBtn: "Close",
    },
    mobileServiceButtons: {
      editServiceBtn: "Edit service",
      deleteServiceBtn: "Delete service",
      closeBtn: "Close",
    },
    tableFilter: {
      services: "Services",
      categories: "Categories",
    },
  },
  mobileServicesFilter: {
    title: "Filter",
    services: "Services",
    categories: "Categories",
  },
  serviceForm: {
    createTitle: "Add Service",
    editTitle: "Edit Service",
    cancelBtn: "Cancel",
    saveBtn: "Save",
    deleteBtn: "Delete",
    nameInput: {
      label: "Service name",
      placeholder: "Eg. Woman Haircut of Full Body Massage",
    },
    categoryInput: {
      label: "Category",
      description:
        "The service is placed inder this category on yout public profile",
      placeholder: "Add a category by typing or choose from previous",
    },
    descriptionInput: {
      label: "Description (optional)",
      placeholder: "Eg. A womans haircut including wash and dry",
    },
    priceInput: {
      label: "Price",
      showPublicLabel: "Show public",
    },
    durationInput: {
      label: "Duration (minutes)",
      showPublicLabel: "Show public",
    },
    serviceTypeInput: {
      label: "Type of Service",
      description:
        "Allow search engine to give customers more accurate results",
    },
    sameTimePeopleInput: {
      label: "Amount of people that can book at the same time",
      description: "Eg. 20 people can book Yoga-session",
      inputLabel: "people",
      toggleBtn: "+ Allow more people to book the same time",
    },
    breakInput: {
      label: "Break after booking this service",
      description:
        "Nobody can book this many minutes after finishing this service",
      inputLabel: "minutes",
      toggleBtn: "+ Add a break after service",
    },
  },
  serviceFormModal: {
    titles: {
      chooseType: "Add an offering to your service menu",
      singleService: "Add a new single service",
    },
    serviceTypes: {
      single: {
        name: "Service",
        description: "Services booked by one client in a single visit",
      },
      session: {
        name: "Class",
        description:
          "Services booked by multiple clients in scheduled sessions",
      },
    },
    comingSoonLabel: "Coming soon",
    saveBtn: "Save",
    basicInfoCard: {
      title: "Basic info",
      description: "Add a service name and choose the treatment type.",
      nameInput: {
        label: "Service name",
      },
      typeInput: {
        label: "Treatment type",
        placeholder: "Select Treatment Type",
        description:
          "Choose the most relevant treatment type. This won't be visible to your clients.",
      },
      categoryInput: {
        label: "Category",
        description:
          "The service is placed inder this category on yout public profile",
        placeholder: "Add a category by typing or choose from previous",
      },
      descriptionInput: {
        label: "Service description",
        placeholder: "Add a short description",
      },
    },
    onlineBookingsCard: {
      title: "Online bookings",
      description:
        "Enable online bookings, choose who the service is available for and add a short description",
      onlineBookingsInput: {
        label: "Enable online bookings",
      },
    },
    staffCard: {
      title: "Staff",
      staffInput: {
        allLabel: "Select All",
      },
    },
    pricingCard: {
      title: "Pricing and Duration",
      description: "Add the pricing options and duration of the service.",
      priceTitle: "Pricing option",
      durationInput: {
        label: "Duration",
      },
      priceTypeInput: {
        label: "Price type",
      },
      priceInput: {
        label: "Price",
      },
      extraTimeTitle: "Extra time",
      extraTimeDescription: "Enable extra time after the service.",
      enableExtraTimeInput: {
        label: "Enable extra time",
      },
      extraTimeCard: {
        title: "Blocked time",
        description: "Block time between appointments",
      },
      breakDurationInput: {
        label: "Duration",
      },
      priceTypeDropdown: {
        free: "Free",
        fixed: "Fixed",
        from: "From",
      },
    },
    sameTimePeopleCard: {
      title: "Number of people in session",
      description: "Set the maximum amount of people that can reserve this service at the same time.",
    },
    validation: {
      error: "Field is invalid",
    },
  },
  staff: {
    title: "Staff",
    createStaffBtn: "New staff",
    table: {
      columns: {
        name: "Name",
        phone: "Phone number",
        email: "Email",
      },
      dropdown: {
        editBtn: "Edit details",
        deleteBtn: "Delete staff",
      },
      pagination: {
        show: "Showing",
        of: "of",
      },
    },
    mobileButtons: {
      editStaffBtn: "Edit staff",
      editWorkingHoursBtn: "Edit working hours",
      deleteStaffBtn: "Delete staff",
      closeBtn: "Close",
    },
  },
  mobileStaffFilter: {
    title: "Filter",
    placesLabel: "Places",
  },
  statistics: {
    title: "Statistics",
    totalSalesLabel: "Total sales",
    totalAppointmentsLabel: "Total appointments",
    completedAppointmentsLabel: "Completed app.",
    canceledAppointmentsLabel: "Canceled app.",
    staffSalesLabel: "Sales by Staff",
    noDataLabel: "No available data",
    topServicesCard: {
      title: "Top Selling Services",
      serviceNameLabel: "Service name",
      totalSalesLabel: "Total sales (sek)",
    },
    charts: {
      currentIncomeLabel: "Current Income",
      pastYearLabel: "Past Year",
    },
  },
  statisticsFilters: {
    placeFilter: {
      allLocationsLabel: "All locations",
    },
    periodFilter: {
      dayLabel: "Day",
      monthLabel: "Month",
      yearLabel: "Year",
    },
    mobilePlaceFilter: {
      title: "Filter",
      allLocationsLabel: "All locations",
    },
    mobilePeriodFilter: {
      title: "Select date",
    },
  },
  settings: {
    title: "Settings",
    tabs: {
      widget: "Widget settings",
      account: "Account settings",
      locations: "Locations",
      billing: "Billing",
    },
    mobileTabsSelect: {
      widget: {
        title: "Widget settings",
        description: "Customize your widget.",
      },
      account: {
        title: "Account settings",
        description:
          "Manage settings such as your business information and online links.",
      },
      locations: {
        title: "Locations",
        description: "Manage multiple locations for your business.",
      },
      billing: {
        title: "Billing",
        description:
          "Manage your plan, payment method and see billing history.",
      },
    },
  },
  widgetTab: {
    previewBtn: "Preview widget",
    saveBtn: "Save",
    backBtn: "Back to widget settings",
    appearance: {
      title: "Appearance",
      logoInput: {
        label: "Company logo:",
        description: "Upload",
      },
      colorInput: {
        label: "Background color:",
      },
      statusInput: {
        label: "Status:",
      },
      messageInput: {
        label: "Message:",
      },
      showReviewsInput: {
        label: "Show reviews:",
      },
    },
    installation: {
      title: "Installation",
      description:
        "It looks like you haven't installed the chat code yet. Choose from one of the installation guides below:",
      jsCard: {
        instructions: {
          step1: "Paste this code just before the </body> tag closes",
        },
        copyBtn: "Copy",
        needHelpLabel: "Need help?",
        contactUsLabel: "Contact Us.",
      },
      wpCard: {
        instructions: {
          step1: "Log in to your WordPress admin panel",
          step2: "Go to Appearance > Theme Editor",
          step3:
            "Under Theme Files (right), click on “Theme Footer” (footer.php)",
          step4: "Paste your Bookybook code before the </body> tag below",
          step5: 'Click "Update file" to save changes',
        },
        copyBtn: "Copy",
        needHelpLabel: "Need help?",
        contactUsLabel: "Contact Us.",
      },
    },
    widget: {
      startBookingLabel: "Start a booking",
      nextFreeTimeLabel: "Next free time left",
      bookingBtn: "Book an appointment with us",
      reviewsLabel: "This is what our customers say about us",
      poweredByLabel: "Powered by",
    },
  },
  accountTab: {
    guide: {
      businessInfo: {
        title: "Business Info",
        description:
          "This information is only used for internal communication. It will not be shown on your profile.",
      },
      onlineLinks: {
        title: "Online Links",
        description:
          "Add your company website and social media links for sharing with clients",
      },
    },
    companyDetailsCard: {
      title: "Company details",
      nameInput: {
        label: "Business name",
      },
      organisationNumberInput: {
        label: "Swedish organisation number",
      },
      addressInput: {
        label: "Address",
      },
      postalNumberInput: {
        label: "Postal number",
      },
      cityInput: {
        label: "City",
      },
      emailInput: {
        label: "Email",
      },
    },
    onlineLinksCard: {
      websiteInput: {
        label: "Website",
      },
      fbPageInput: {
        label: "Facebook page",
      },
      igPageInput: {
        label: "Instagram page",
      },
    },
    saveBtn: "Save",
  },
  locationsTab: {
    allLocationsSection: {
      title: "Locations",
      description: "Manage multiple locations for your business.",
      createPlaceBtn: "Add a new location",
    },
    selectedPlaceSection: {
      dropdown: {
        toggleBtn: "Options",
        editBtn: "Edit location details",
        deleteBtn: "Delete location",
      },
      detailsCard: {
        title: "Location details",
        editBtn: "Edit",
        emailLabel: "Location email address",
        phoneLabel: "Location contact number",
      },
      categoryCard: {
        title: "Business category",
        editBtn: "Edit",
        mainCategoryLabel: "Main",
        additionalCategoryLabel: "Additional",
        addBtn: "+ add",
      },
      openingHoursCard: {
        title: "Opening hours",
        editBtn: "Edit",
        closedLabel: "Closed",
      },
      photosCard: {
        title: "My photos",
        editBtn: "Edit",
        label: "Show your work",
        description:
          "Include pictures of your work (before/after), team, workplace or equipment",
      },
      mobileButtons: {
        editBtn: "Edit location details",
        deleteBtn: "Delete location",
        closeBtn: "Close",
      },
    },
  },
  billingTab: {
    planCard: {
      title: "Plan",
      planName: "Team",
      priceDescription: "/use/month",
      planInfo: "invoiced monthly",
      changePlanBtn: "Change plan",
    },
    paymentMethodCard: {
      title: "Payment method",
      addBtn: "+ add",
      expireLabel: "Expires on",
    },
    billingHistoryCard: {
      title: "Billing history",
      table: {
        columns: {
          date: "Date",
          description: "Description",
          amount: "Amount",
        },
        viewBtn: "View receipt",
      },
    },
  },
  profile: {
    title: "Profile",
    saveBtn: "Save",
    guide: {
      personalDetails: {
        title: "Personal details",
        description:
          "Set your name and contact information. The email adress here is used for your login access.",
      },
      changePassword: {
        title: "Change password",
        description:
          "To make an update, enter your existing password followed by a new one. If you don’t know your existing password, logout and use the Reset Password link on the Log in page.",
      },
      notificationSettings: {
        title: "My notifications settings",
        description:
          "Receive notifications about important activity in your account",
      },
    },
    personalDetailsCard: {
      uploadAvatarBtn: "Upload profile picture",
      firstNameInput: {
        label: "First Name",
      },
      lastNameInput: {
        label: "Last Name",
      },
      phoneInput: {
        label: "Phone number",
      },
      emailInput: {
        label: "Email",
      },
      currentPasswordInput: {
        label: "Current password",
      },
      newPasswordInput: {
        label: "New password",
      },
    },
    notificationsCard: {
      notifyMeInput: {
        label: "Notify me about",
        options: {
          allStaff: "Appointments booked with all staff",
          meOnly: "Appointments booked with me only",
        },
      },
      bookedInput: {
        label: "Booked at",
        allLocationsLabel: "All locations",
        changeBtn: "Change",
        newAppointmentsLabel: "New appointments",
        cancellationLabel: "Cancellations",
      },
    },
    croperModal: {
      title: "Crop image",
      saveBtn: "Apply",
    },
    locationsModal: {
      title: "Select locations",
      allPlacesLabel: "Select all",
      saveBtn: "Select",
    },
  },
  locationInput: {
    title: "Business location",
    label: "Where's your business located?",
  },
  cancelAppointmentModal: {
    title: "Cancel booking",
    messageInput: {
      label: "Message",
      description: "(optional)",
    },
    submitBtn: "Cancel",
  },
  placeFormModal: {
    previousStepBtn: "Previous",
    nextStepBtn: "Next step",
    saveBtn: "Save",
    stepLabel: "Step",
    stepOfLabel: "of",
    createPlaceMode: {
      step1: {
        title: "About your business",
      },
      step2: {
        title: "Choose your main business category",
      },
      step3: {
        title: "Choose secondary business categories",
        description:
          "If your business can be described by more types, choose all that apply",
      },
      step4: {
        title: "Add your location",
      },
    },
    editInfoMode: {
      title: "About your business",
    },
    editCategoriesMode: {
      step1: {
        title: "Choose your main business category",
      },
      step2: {
        title: "Choose secondary business categories",
        description:
          "If your business can be described by more types, choose all that apply",
      },
    },
    basicInfoCard: {
      title: "Basics",
      logoInput: {
        label: "Upload logo (optional)",
        description: "This photo will be displayed on your profile page.",
      },
      nameInput: {
        label: "Company name",
        description: "(displayed on profile page)",
      },
      publicProfileInput: {
        label: "Link to public profile",
      },
      phoneInput: {
        label: "Location contact number",
      },
      emailInput: {
        label: "Location email address",
      },
      descriptionInput: {
        label: "About us",
      },
    },
    validation: {
      error: "Field is required",
      locationError:
        "Location is required.  You must select one of provided locations from dropdown.",
      mainCategoryError: "Main category is required",
    },
  },
  placeImagesModal: {
    title: "My photos",
    card: {
      title: "Upload photos",
    },
    saveBtn: "Save",
  },
  availabilityInput: {
    title: "Availability",
    closedLabel: "Closed",
    addBtn: "+ Add",
    saveBtn: "Save",
  },
  availabilityFormModal: {
    title: "Availability",
    fromInput: {
      label: "From",
    },
    toInput: {
      label: "To",
    },
    closedLabel: "Closed",
    saveBtn: "Save",
    closeBtn: "Close",
  },
  openingHoursForm: {
    title: "Opening hours",
    closedLabel: "Closed",
    saveBtn: "Save",
  },
  mobileClientSelect: {
    title: "Select Client",
    searchPlaceholder: "Search by client name",
  },
  multipleClientSelect: {
    title: "Select Clients",
    searchPlaceholder: "Search by client name",
    selectAllLabel: "Select all clients",
    saveBtn: "Save selected clients",
  },
  mobileServiceSelect: {
    title: "Select Service",
    searchPlaceholder: "Search by service name",
  },
  weekdays: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  changeLanguageModal: {
    title: "Change Language",
    languageInput: {
      label: "Choose Language",
    },
    saveBtn: "Save",
  },
  deleteClientModal: {
    title: "Delete client",
    message: "Are you sure that you want to delete client",
    confirmBtn: "Yes",
    cancelBtn: "No",
  },
  deleteStaffModal: {
    title: "Delete staff",
    message: "Are you sure that you want to delete staff",
    confirmBtn: "Yes",
    cancelBtn: "No",
  },
  defaults: {
    checkboxGroup: {
      chooseAllLabel: "Choose all",
    },
    select: {
      placeholder: "Select option",
      searchPlaceholder: "Search",
      noOptionsText: "No available options",
    },
    dropdown: {
      toggleText: "Toggle dropdown",
      description: "Place your items here",
    },
  },
};

export const law = {
  login: {
    title: "Welcome back",
    emailLabel: "E-mail",
    emailValidationError: "Email is required and a valid email address.",
    passwordLabel: "Password",
    passwordValidationError: "Password is required.",
    keepMeLabel: "Keep me logged in",
    forgotPassword: "Forgot Password ?",
    loginBtn: "Log In",
    dontHaveAccount: "Dont have an account?",
    signUp: "Sign up",
  },
  forgotPassword: {
    title: "Forgot your password?",
    emailInput: {
      label: "Email",
      description: "Enter your email to get reset password link",
    },
    submitBtn: "Send",
  },
  resetPassword: {
    title: "Reset password",
    passwordInput: {
      label: "New password",
    },
    passwordConfirmationInput: {
      label: "Confirm new password",
    },
    submitBtn: "Reset password",
  },
  menu: {
    calendar: "Calendar",
    clients: "Clients",
    services: "Services",
    staff: "Staff",
    statistics: "Statistics",
    settings: "Settings",
    profile: "Profile",
    contactSupport: "Contact support",
    logout: "Logout",
    subjects: "Subjects"
  },
  calendar: {
    title: "Calendar",
    alert: {
      title: "Welcome to Your Calendar Lawyer",
      text: "Appointments that customers or you book will appear here. Click on an appointment to see the details and manage it.",
    },
    newEventActions: {
      appointment: "Appointment",
      appointmentMobile: "New Appointment",
      groupAppointment: "Group appointment",
      blockTime: "Block Time",
      close: "Close",
    },
    appointment: {
      cancelAppointment: "Cancel appointment",
      editAppointment: "Edit appointment",
      notesLabel: "Notes",
      noNotesText: "No notes for this appointment",
      employee: "Employee",
    },
    blockTime: {
      removeBlockTime: "Remove block time",
      editBlockTime: "Edit block time",
    },
    calendarEventCard: {
      clientsLabel: "clients"
    },
  },
  calendarFilters: {
    placeFilter: {
      createPlace: "Create new place",
    },
    staffFilter: {
      allStaff: "All staff",
      createStaff: "Create new staff",
    },
    viewFilter: {
      week: "week",
      day: "day",
    },
    mobileViewFilter: {
      title: "Calendar view",
      day: "Day",
      threeDays: "3 Days",
    },
    mobilePlaceAndStaffFilter: {
      title: "Filter",
      placesLabel: "Places",
      staffLabel: "Staff",
      allStaffLabel: "All staff",
    },
  },
  appointmentForm: {
    createTitle: "New appointment",
    createGroupTitle: "New group appointment",
    editTitle: "Edit appointment",
    editGroupTitle: "Edit group appointment",
    createSubmitBtn: "Create appointment",
    editSubmitBtn: "Save",
    noDateLabel: "Add date",
    clientInput: {
      label: "Client",
      placeholder: "Add a client",
      searchPlaceholder: "Search Client",
      noResultsText: "No client available",
      editBtn: "edit details",
      removeBtn: "remove from appointment",
      createBtn: "Create new client",
      details: {
        title: "Client Details",
        emailLabel: "Email",
        phoneLabel: "Phone number",
        notesLabel: "Client notes",
      },
    },
    clientsInputBtn: "Select clients",
    subjectInput: {
      label: "Subject",
      placeholder: "Add a subject",
      searchPlaceholder: "Search Subjects",
      noResultsText: "No subject available",
      editBtn: "Edit details",
      removeBtn: "remove from appointment",
      createBtn: "Create new subject",
      details: {
        title: "Subject Details",
        subjectNumber: "Subject number",
        courtNumber: "Court number",
        courtName: "Court name",
      },
    },
    serviceInput: {
      label: "Service",
      placeholder: "Add a service",
      searchPlaceholder: "Search for service",
      noResultsText: "No service available",
      createBtn: "Create new service",
    },
    staffInput: {
      label: "Staff",
      placeholder: "Choose staff",
      searchPlaceholder: "Search staff",
      noResultsText: "No staff available",
      createBtn: "Create new staff",
    },
    startTimeInput: {
      label: "Start time",
      placeholder: "Choose a time",
      noResultsText: "No available slot",
    },
    durationLabel: "Duration",
    notesInput: {
      label: "Appointment notes",
      placeholder: "Add an appointment note (visible only to staff)",
    },
  },
  blockTimeForm: {
    title: "Block Time",
    createSubmitBtn: "Create blocked time",
    editSubmitBtn: "Edit blocked time",
    dateLabel: "Add date",
    staffInput: {
      label: "Staff",
      placeholder: "Choose staff",
      searchPlaceholder: "Search staff",
      noResultsText: "No staff available",
      createBtn: "Create new staff",
    },
    startTimeInput: {
      label: "Start time",
      placeholder: "Start time",
      noResultsText: "No available slot",
    },
    endTimeInput: {
      label: "End time",
      placeholder: "End time",
      noResultsText: "No available slot",
    },
    notesInput: {
      label: "Description",
      placeholder: "Eg. Lunch meeting",
    },
  },
  dateInput: {
    appointmentTitle: "Appointment date",
    blockTimeTitle: "Choose date",
    mobileTitle: "Pick date(s)",
    saveBtn: "Save",
  },
  calendarForm: {
    createTitle: "New staff",
    editTitle: "Edit staff",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    mobileButtons: {
      sendResetPassword: "Send reset password email",
      delete: "Delete staff",
      close: "Close",
    },
    basicSettings: {
      title: "Basic settings",
      profilePictureInput: {
        label: "Profile picture (optional, but highly recommended)",
        description: "This photo will be displayed on your profile page",
        mobileLabel: "Profile picture",
        mobileDescription: "(optional, but highly recommended)",
        uploadBtn: "Upload profile picture",
        deleteBtn: "Delete",
      },
      nameInput: {
        label: "Name",
        placeholder: "Eg. Linda",
      },
      titleInput: {
        label: "Title",
        placeholder: "Eg. Hairstylist",
      },
      personalDetails: {
        label: "Personal details",
        description:
          "This information is only used for internal communication and notifications.",
      },
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      roleInput: {
        label: "Role",
        manageAccountLabel: "Manage account",
        managePlaceLabel: "Manage place",
        manageBookingsLabel: "Manage bookings for place",
        manageCalendarLabel: "Manage own calendar",
      },
    },
    availability: {
      title: "Availability",
      description:
        "Chose between which time clients are able to book services on this calendar",
      editBtn: "Edit",
      removeBtn: "Remove",
      addDayOffBtn: "+ Add specific Day Off",
      closedLabel: "Closed",
    },
    placeAndServices: {
      title: "Place and services",
      placeInput: {
        label: "Which place does this calendar belong to?",
      },
      servicesInput: {
        label: "Which services can be booked with this calendar?",
      },
    },
    advancedSettings: {
      title: "Advanced Settings",
      requiredInfoInput: {
        label: "Which information is required from the client when they book?",
        firstNameLabel: "First name",
        lastNameLabel: "Last name",
        emailLabel: "Email",
        phoneLabel: "Phone number",
        addressLabel: "Address",
        zipCodeLabel: "Postal code",
        cityLabel: "City",
        otherLabel: "Other",
      },
      otherInput: {
        label: 'Alias for "Other"',
        placeholder: "Other",
      },
      cancellationMarginInput: {
        label: "Cancellation margin",
        description:
          "Until how long before the booking is it possible to cancel?",
        locationDescription:
          "Select the location for which you want to edit booking cancelations.",
        daysLabel: "days",
        hoursLabel: "hours",
        minutesLabel: "minutes",
      },
      bookingMarginInput: {
        label: "Booking margin",
        description: "How close to an available time is it possible to book?",
        daysLabel: "days",
        hoursLabel: "hours",
        minutesLabel: "minutes",
      },
      tocInput: {
        label: "Terms and Conditions (optional)",
        description:
          "If you add terms and conditions, clients will have to agree to them by reading and checking a box in order to create a booking.",
      },
    },
    notifications: {
      title: "Notifications",
      toServiceProviderInput: {
        label: "To service provider",
        description:
          'Notifications will be sent to the email that is provided in "Personal Details"',
        bookingConfirmationLabel: "Booking confirmation",
        cancellationConfirmationLabel: "Cancellation confirmation",
      },
      toCustomerInput: {
        label: "To customer",
        description:
          "Notifications will be sent to the email that is provided by the customer",
        bookingConfirmationLabel: "Booking confirmation",
        cancellationConfirmationLabel: "Cancellation confirmation",
        reminderLabel: "Reminder of booking",
        followUpLabel: "Follow-Up",
      },
      followUpInput: {
        label: "Follow-Up",
        description:
          "Incrase the chance of recurring bookings with sendings follow-up email",
        daysLabel: "days after booked time",
      },
    },
  },
  calendarFormModal: {
    createTitle: "Add new staff",
    editTitle: "Edit staff",
    createBtn: "Add staff",
    editBtn: "Save",
    basicInfoCard: {
      title: "Basic info",
      nameInput: {
        label: "Name",
      },
      titleInput: {
        label: "Staff title",
        description: "This title will be visible to clients",
      },
      notesInput: {
        label: "Notes",
        labelDescription: "(optional)",
        placeholder: "Add private notes viewable in staff settings only",
      },
      contactTitle: "Contact",
      contactDescription:
        "Staff contacts are confidential and won't be shared with your clients.",
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      employmentTitle: "Employment",
      employmentStartInput: {
        label: "Start date",
      },
      employmentEndInput: {
        label: "End date",
      },
    },
    placesCard: {
      title: "Works at",
      description: "Choose the locations where this staff member works.",
      placesInput: {
        allLabel: "All locations",
      },
    },
    bookingCard: {
      title: "Booking",
      bookingsEnabledInput: {
        label: "Allow calendar bookings",
        description:
          "Allow this staff member to recive bookings on the calendar.",
      },
    },
    colorCard: {
      title: "Calendar color",
      description:
        "Choose a color to see this staff members appointments in the calendar",
      colorInput: {
        label: "Select color",
      },
    },
    servicesCard: {
      title: "Services",
      description: "Add the services this staff member can offer.",
      servicesInput: {
        allSelectedLabel: "All services",
        countSelectedLabel: "Selected services",
        noSelectedLabel: "No selected services",
        editBtn: "Edit",
      },
    },
    clientsCard: {
      title: "Clients",
      description:
        "Add clients that employee will see even if has only base permissions",
      clientsInput: {
        allSelectedLabel: "All clients",
        countSelectedLabel: "Selected clients",
        noSelectedLabel: "No selected clients",
        editBtn: "Edit",
      },
    },
    permissionsCard: {
      title: "Staff permissions",
    },
    selectServicesModal: {
      title: "Select services",
      searchInput: {
        placeholder: "Search services",
      },
      allServicesLabel: "All services",
      closeBtn: "Cancel",
      saveBtn: "Save",
    },
    selectClientsModal: {
      title: "Select clients",
      searchInput: {
        placeholder: "Search clients",
      },
      allClientsLabel: "All clients",
      closeBtn: "Cancel",
      saveBtn: "Save",
    },
    validation: {
      error: "Field is invalid",
    },
  },
  clients: {
    title: "Clients",
    searchInput: {
      placeholder: "Search by name, email or phone number",
    },
    createClientBtn: "New client",
    clientDropdown: {
      showBtn: "See profile",
      editBtn: "Edit details",
      deleteBtn: "Delete client",
    },
    table: {
      columns: {
        name: "Name",
        phone: "Phone number",
        email: "Email",
      },
      pagination: {
        show: "Showing",
        of: "of",
      },
    },
    mobileSearchInput: {
      placeholder: "Search by client name",
    },
  },
  clientProfile: {
    title: "Client Profile",
    createAppointmentDropdown: {
      toggleBtn: "Scheduling",
      appointmentBtn: "New appointment",
      groupAppointmentBtn: "New group appointment"
    },
    dropdown: {
      editBtn: "Edit details",
      deleteBtn: "Delete client",
    },
    infoCard: {
      email: {
        label: "Email",
      },
      phone: {
        label: "Phone number",
      },
      notes: {
        label: "Client notes",
      },
      notifications: {
        label: "Notifications",
        enabledLabel: "Enabled",
        disabledLabel: "Disabled",
        viaLabel: "Notifications Type",
        emailLabel: "Via Email",
        unknownLabel: "Unknown",
      },
    },
    salesCard: {
      title: "Total sales",
      bookings: {
        all: "All bookings",
        completed: "Completed",
        canceled: "Cancelled",
      },
    },
    mobileButtons: {
      editBtn: "Edit details",
      deleteBtn: "Delete client",
      closeBtn: "Close",
    },
  },
  appointmentList: {
    title: "Previous appointments",
    noAppointmentsLabel: "No previous appointments",
    status: {
      active: "Active",
      finished: "Finished",
      canceled: "Canceled",
    },
  },
  clientForm: {
    createTitle: "Create new client",
    editTitle: "Edit client",
    saveBtn: "Save",
    cancelBtn: "Cancel",
    contactDetails: {
      title: "Contact details",
      firstNameInput: {
        label: "First name",
      },
      lastNameInput: {
        label: "Last name",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Phone number",
      },
      notificationsInput: {
        label: "Notifications",
        description: "Notifications will be sent to the email provided above",
        notificationsLabel: "Notifications",
      },
    },
    aditionalInfo: {
      title: "Aditional information (optional)",
      addressInput: {
        label: "Address",
      },
      cityInput: {
        label: "City",
      },
      zipCodeInput: {
        label: "Zip / Post code",
      },
      notesInput: {
        label: "Client notes",
        placeholder: "Eg. Allergic to nitrile-gloves",
      },
    },
  },
  clientFormModal: {
    createTitle: "New client",
    editTitle: "Edit client",
    saveBtn: "Save",
    closeBtn: "Cancel",
    deleteBtn: "Delete",
    tabs: {
      personalInfo: "Personal Info",
      address: "Address",
    },
    firstNameInput: {
      label: "First name",
    },
    lastNameInput: {
      label: "Last name",
    },
    mobilePhoneInput: {
      label: "Mobile",
    },
    phoneInput: {
      label: "Phone",
    },
    emailInput: {
      label: "Email",
    },
    sendNotificationsByInput: {
      label: "Send notifications by",
      options: {
        email: "Email",
        dontSend: "Don't send notifications",
      },
    },
    notificationsEnabledInput: {
      label: "Accepts marketing notifications",
    },
    genderInput: {
      label: "Gender",
      options: {
        unknown: "Unknown",
        male: "Male",
        female: "Female",
      },
    },
    birthdayInput: {
      label: "Birthday",
      monthPlaceholder: "Month",
      dayPlaceholder: "Day",
      yearPlaceholder: "Year",
    },
    notesInput: {
      label: "Client notes",
    },
    displayNotesInput: {
      label: "Display on all bookings",
    },
    addressInput: {
      label: "Address",
    },
    cityInput: {
      label: "City",
    },
    stateInput: {
      label: "State",
    },
    zipCodeInput: {
      label: "Zip / Post Code",
    },
    mobileSection: {
      notesSection: {
        title: "Client Notes",
        notesInput: {
          label: "Client notes",
        },
        displayNotesInput: {
          label: "Display on all booking",
        },
      },
      additionalInfoSection: {
        title: "Additional Information",
        personalInfoBtn: "Personal info",
        addressDetailsBtn: "Address details",
      },
    },
    validation: {
      error: "Field is required",
    },
  },
  addressDetailsMobileModal: {
    title: "Address details",
    addressInput: {
      label: "Address",
    },
    cityInput: {
      label: "City",
    },
    stateInput: {
      label: "State",
    },
    zipCodeInput: {
      label: "Zip / Post Code",
    },
    saveBtn: "Save",
  },
  personalInfoMobileModal: {
    title: "Personal Info",
    genderInput: {
      label: "Gender",
    },
    birthdayInput: {
      label: "Birthday",
      monthPlaceholder: "Month",
      dayPlaceholder: "Day",
      yearPlaceholder: "Year",
    },
    saveBtn: "Save",
  },
  services: {
    title: "Services",
    createServiceBtn: "Add new",
    table: {
      createServiceBtn: "Add service",
      dropdown: {
        createServiceBtn: "Add new service",
        editCategoryNameBtn: "Edit category name",
        deleteCategoryBtn: "Delete category",
      },
      editBtn: "Edit",
      minutesLabel: "minutes",
      noDescriptionLabel: "No service description avaliable.",
      groupServiceLabel: "Class",
    },
    mobileButtons: {
      createServiceBtn: "Add new service",
      editCategoryNameBtn: "Edit category name",
      deleteCategoryBtn: "Delete category",
      closeBtn: "Close",
    },
    mobileServiceButtons: {
      editServiceBtn: "Edit service",
      deleteServiceBtn: "Delete service",
      closeBtn: "Close",
    },
    tableFilter: {
      services: "Services",
      categories: "Categories",
    },
  },
  mobileServicesFilter: {
    title: "Filter",
    services: "Services",
    categories: "Categories",
  },
  serviceForm: {
    createTitle: "Add Service",
    editTitle: "Edit Service",
    cancelBtn: "Cancel",
    saveBtn: "Save",
    deleteBtn: "Delete",
    nameInput: {
      label: "Service name",
      placeholder: "Eg. Woman Haircut of Full Body Massage",
    },
    categoryInput: {
      label: "Category",
      description:
        "The service is placed inder this category on yout public profile",
      placeholder: "Add a category by typing or choose from previous",
    },
    descriptionInput: {
      label: "Description (optional)",
      placeholder: "Eg. A womans haircut including wash and dry",
    },
    priceInput: {
      label: "Price",
      showPublicLabel: "Show public",
    },
    durationInput: {
      label: "Duration (minutes)",
      showPublicLabel: "Show public",
    },
    serviceTypeInput: {
      label: "Type of Service",
      description:
        "Allow search engine to give customers more accurate results",
    },
    sameTimePeopleInput: {
      label: "Amount of people that can book at the same time",
      description: "Eg. 20 people can book Yoga-session",
      inputLabel: "people",
      toggleBtn: "+ Allow more people to book the same time",
    },
    breakInput: {
      label: "Break after booking this service",
      description:
        "Nobody can book this many minutes after finishing this service",
      inputLabel: "minutes",
      toggleBtn: "+ Add a break after service",
    },
  },
  serviceFormModal: {
    titles: {
      chooseType: "Add an offering to your service menu",
      singleService: "Add a new single service",
    },
    serviceTypes: {
      single: {
        name: "Service",
        description: "Services booked by one client in a single visit",
      },
      session: {
        name: "Class",
        description:
          "Services booked by multiple clients in scheduled sessions",
      },
    },
    comingSoonLabel: "Coming soon",
    saveBtn: "Save",
    basicInfoCard: {
      title: "Basic info",
      description: "Add a service name and choose the treatment type.",
      nameInput: {
        label: "Service name",
      },
      typeInput: {
        label: "Treatment type",
        placeholder: "Select Treatment Type",
        description:
          "Choose the most relevant treatment type. This won't be visible to your clients.",
      },
      categoryInput: {
        label: "Category",
        description:
          "The service is placed inder this category on yout public profile",
        placeholder: "Add a category by typing or choose from previous",
      },
      descriptionInput: {
        label: "Service description",
        placeholder: "Add a short description",
      },
    },
    onlineBookingsCard: {
      title: "Online bookings",
      description:
        "Enable online bookings, choose who the service is available for and add a short description",
      onlineBookingsInput: {
        label: "Enable online bookings",
      },
    },
    staffCard: {
      title: "Staff",
      staffInput: {
        allLabel: "Select All",
      },
    },
    pricingCard: {
      title: "Pricing and Duration",
      description: "Add the pricing options and duration of the service.",
      priceTitle: "Pricing option",
      durationInput: {
        label: "Duration",
      },
      priceTypeInput: {
        label: "Price type",
      },
      priceInput: {
        label: "Price",
      },
      extraTimeTitle: "Extra time",
      extraTimeDescription: "Enable extra time after the service.",
      enableExtraTimeInput: {
        label: "Enable extra time",
      },
      extraTimeCard: {
        title: "Blocked time",
        description: "Block time between appointments",
      },
      breakDurationInput: {
        label: "Duration",
      },
      priceTypeDropdown: {
        free: "Free",
        fixed: "Fixed",
        from: "From",
      },
    },
    sameTimePeopleCard: {
      title: "Number of people in session",
      description: "Set the maximum amount of people that can reserve this service at the same time.",
    },
    validation: {
      error: "Field is invalid",
    },
  },
  staff: {
    title: "Staff",
    createStaffBtn: "New staff",
    table: {
      columns: {
        name: "Name",
        phone: "Phone number",
        email: "Email",
      },
      dropdown: {
        editBtn: "Edit details",
        deleteBtn: "Delete staff",
      },
      pagination: {
        show: "Showing",
        of: "of",
      },
    },
    mobileButtons: {
      editStaffBtn: "Edit staff",
      editWorkingHoursBtn: "Edit working hours",
      deleteStaffBtn: "Delete staff",
      closeBtn: "Close",
    },
  },
  mobileStaffFilter: {
    title: "Filter",
    placesLabel: "Places",
  },
  statistics: {
    title: "Statistics",
    totalSalesLabel: "Total sales",
    totalAppointmentsLabel: "Total appointments",
    completedAppointmentsLabel: "Completed app.",
    canceledAppointmentsLabel: "Canceled app.",
    staffSalesLabel: "Sales by Staff",
    noDataLabel: "No available data",
    topServicesCard: {
      title: "Top Selling Services",
      serviceNameLabel: "Service name",
      totalSalesLabel: "Total sales (sek)",
    },
    charts: {
      currentIncomeLabel: "Current Income",
      pastYearLabel: "Past Year",
    },
  },
  statisticsFilters: {
    placeFilter: {
      allLocationsLabel: "All locations",
    },
    periodFilter: {
      dayLabel: "Day",
      monthLabel: "Month",
      yearLabel: "Year",
    },
    mobilePlaceFilter: {
      title: "Filter",
      allLocationsLabel: "All locations",
    },
    mobilePeriodFilter: {
      title: "Select date",
    },
  },
  settings: {
    title: "Settings",
    tabs: {
      widget: "Widget settings",
      account: "Account settings",
      locations: "Locations",
      billing: "Billing",
    },
    mobileTabsSelect: {
      widget: {
        title: "Widget settings",
        description: "Customize your widget.",
      },
      account: {
        title: "Account settings",
        description:
          "Manage settings such as your business information and online links.",
      },
      locations: {
        title: "Locations",
        description: "Manage multiple locations for your business.",
      },
      billing: {
        title: "Billing",
        description:
          "Manage your plan, payment method and see billing history.",
      },
    },
  },
  widgetTab: {
    previewBtn: "Preview widget",
    saveBtn: "Save",
    backBtn: "Back to widget settings",
    appearance: {
      title: "Appearance",
      logoInput: {
        label: "Company logo:",
        description: "Upload",
      },
      colorInput: {
        label: "Background color:",
      },
      statusInput: {
        label: "Status:",
      },
      messageInput: {
        label: "Message:",
      },
      showReviewsInput: {
        label: "Show reviews:",
      },
    },
    installation: {
      title: "Installation",
      description:
        "It looks like you haven't installed the chat code yet. Choose from one of the installation guides below:",
      jsCard: {
        instructions: {
          step1: "Paste this code just before the </body> tag closes",
        },
        copyBtn: "Copy",
        needHelpLabel: "Need help?",
        contactUsLabel: "Contact Us.",
      },
      wpCard: {
        instructions: {
          step1: "Log in to your WordPress admin panel",
          step2: "Go to Appearance > Theme Editor",
          step3:
            "Under Theme Files (right), click on “Theme Footer” (footer.php)",
          step4: "Paste your Bookybook code before the </body> tag below",
          step5: 'Click "Update file" to save changes',
        },
        copyBtn: "Copy",
        needHelpLabel: "Need help?",
        contactUsLabel: "Contact Us.",
      },
    },
    widget: {
      startBookingLabel: "Start a booking",
      nextFreeTimeLabel: "Next free time left",
      bookingBtn: "Book an appointment with us",
      reviewsLabel: "This is what our customers say about us",
      poweredByLabel: "Powered by",
    },
  },
  accountTab: {
    guide: {
      businessInfo: {
        title: "Business Info",
        description:
          "This information is only used for internal communication. It will not be shown on your profile.",
      },
      onlineLinks: {
        title: "Online Links",
        description:
          "Add your company website and social media links for sharing with clients",
      },
    },
    companyDetailsCard: {
      title: "Company details",
      nameInput: {
        label: "Business name",
      },
      organisationNumberInput: {
        label: "Swedish organisation number",
      },
      addressInput: {
        label: "Address",
      },
      postalNumberInput: {
        label: "Postal number",
      },
      cityInput: {
        label: "City",
      },
      emailInput: {
        label: "Email",
      },
    },
    onlineLinksCard: {
      websiteInput: {
        label: "Website",
      },
      fbPageInput: {
        label: "Facebook page",
      },
      igPageInput: {
        label: "Instagram page",
      },
    },
    saveBtn: "Save",
  },
  locationsTab: {
    allLocationsSection: {
      title: "Locations",
      description: "Manage multiple locations for your business.",
      createPlaceBtn: "Add a new location",
    },
    selectedPlaceSection: {
      dropdown: {
        toggleBtn: "Options",
        editBtn: "Edit location details",
        deleteBtn: "Delete location",
      },
      detailsCard: {
        title: "Location details",
        editBtn: "Edit",
        emailLabel: "Location email address",
        phoneLabel: "Location contact number",
      },
      categoryCard: {
        title: "Business category",
        editBtn: "Edit",
        mainCategoryLabel: "Main",
        additionalCategoryLabel: "Additional",
        addBtn: "+ add",
      },
      openingHoursCard: {
        title: "Opening hours",
        editBtn: "Edit",
        closedLabel: "Closed",
      },
      photosCard: {
        title: "My photos",
        editBtn: "Edit",
        label: "Show your work",
        description:
          "Include pictures of your work (before/after), team, workplace or equipment",
      },
      mobileButtons: {
        editBtn: "Edit location details",
        deleteBtn: "Delete location",
        closeBtn: "Close",
      },
    },
  },
  billingTab: {
    planCard: {
      title: "Plan",
      planName: "Team",
      priceDescription: "/use/month",
      planInfo: "invoiced monthly",
      changePlanBtn: "Change plan",
    },
    paymentMethodCard: {
      title: "Payment method",
      addBtn: "+ add",
      expireLabel: "Expires on",
    },
    billingHistoryCard: {
      title: "Billing history",
      table: {
        columns: {
          date: "Date",
          description: "Description",
          amount: "Amount",
        },
        viewBtn: "View receipt",
      },
    },
  },
  profile: {
    title: "Profile",
    saveBtn: "Save",
    guide: {
      personalDetails: {
        title: "Personal details",
        description:
          "Set your name and contact information. The email adress here is used for your login access.",
      },
      changePassword: {
        title: "Change password",
        description:
          "To make an update, enter your existing password followed by a new one. If you don’t know your existing password, logout and use the Reset Password link on the Log in page.",
      },
      notificationSettings: {
        title: "My notifications settings",
        description:
          "Receive notifications about important activity in your account",
      },
    },
    personalDetailsCard: {
      uploadAvatarBtn: "Upload profile picture",
      firstNameInput: {
        label: "First Name",
      },
      lastNameInput: {
        label: "Last Name",
      },
      phoneInput: {
        label: "Phone number",
      },
      emailInput: {
        label: "Email",
      },
      currentPasswordInput: {
        label: "Current password",
      },
      newPasswordInput: {
        label: "New password",
      },
    },
    notificationsCard: {
      notifyMeInput: {
        label: "Notify me about",
        options: {
          allStaff: "Appointments booked with all staff",
          meOnly: "Appointments booked with me only",
        },
      },
      bookedInput: {
        label: "Booked at",
        allLocationsLabel: "All locations",
        changeBtn: "Change",
        newAppointmentsLabel: "New appointments",
        cancellationLabel: "Cancellations",
      },
    },
    croperModal: {
      title: "Crop image",
      saveBtn: "Apply",
    },
    locationsModal: {
      title: "Select locations",
      allPlacesLabel: "Select all",
      saveBtn: "Select",
    },
  },
  locationInput: {
    title: "Business location",
    label: "Where's your business located?",
  },
  cancelAppointmentModal: {
    title: "Cancel booking",
    messageInput: {
      label: "Message",
      description: "(optional)",
    },
    submitBtn: "Cancel",
  },
  placeFormModal: {
    previousStepBtn: "Previous",
    nextStepBtn: "Next step",
    saveBtn: "Save",
    stepLabel: "Step",
    stepOfLabel: "of",
    createPlaceMode: {
      step1: {
        title: "About your business",
      },
      step2: {
        title: "Choose your main business category",
      },
      step3: {
        title: "Choose secondary business categories",
        description:
          "If your business can be described by more types, choose all that apply",
      },
      step4: {
        title: "Add your location",
      },
    },
    editInfoMode: {
      title: "About your business",
    },
    editCategoriesMode: {
      step1: {
        title: "Choose your main business category",
      },
      step2: {
        title: "Choose secondary business categories",
        description:
          "If your business can be described by more types, choose all that apply",
      },
    },
    basicInfoCard: {
      title: "Basics",
      logoInput: {
        label: "Upload logo (optional)",
        description: "This photo will be displayed on your profile page.",
      },
      nameInput: {
        label: "Company name",
        description: "(displayed on profile page)",
      },
      publicProfileInput: {
        label: "Link to public profile",
      },
      phoneInput: {
        label: "Location contact number",
      },
      emailInput: {
        label: "Location email address",
      },
      descriptionInput: {
        label: "About us",
      },
    },
    validation: {
      error: "Field is required",
      locationError:
        "Location is required.  You must select one of provided locations from dropdown.",
      mainCategoryError: "Main category is required",
    },
  },
  placeImagesModal: {
    title: "My photos",
    card: {
      title: "Upload photos",
    },
    saveBtn: "Save",
  },
  availabilityInput: {
    title: "Availability",
    closedLabel: "Closed",
    addBtn: "+ Add",
    saveBtn: "Save",
  },
  availabilityFormModal: {
    title: "Availability",
    fromInput: {
      label: "From",
    },
    toInput: {
      label: "To",
    },
    closedLabel: "Closed",
    saveBtn: "Save",
    closeBtn: "Close",
  },
  openingHoursForm: {
    title: "Opening hours",
    closedLabel: "Closed",
    saveBtn: "Save",
  },
  mobileClientSelect: {
    title: "Select Client",
    searchPlaceholder: "Search by client name",
  },
  multipleClientSelect: {
    title: "Select Clients",
    searchPlaceholder: "Search by client name",
    selectAllLabel: "Select all clients",
    saveBtn: "Save selected clients",
  },
  mobileServiceSelect: {
    title: "Select Service",
    searchPlaceholder: "Search by service name",
  },
  weekdays: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  changeLanguageModal: {
    title: "Change Language",
    languageInput: {
      label: "Choose Language",
    },
    saveBtn: "Save",
  },
  deleteClientModal: {
    title: "Delete client",
    message: "Are you sure that you want to delete client",
    confirmBtn: "Yes",
    cancelBtn: "No",
  },
  deleteStaffModal: {
    title: "Delete staff",
    message: "Are you sure that you want to delete staff",
    confirmBtn: "Yes",
    cancelBtn: "No",
  },
  defaults: {
    checkboxGroup: {
      chooseAllLabel: "Choose all",
    },
    select: {
      placeholder: "Select option",
      searchPlaceholder: "Search",
      noOptionsText: "No available options",
    },
    dropdown: {
      toggleText: "Toggle dropdown",
      description: "Place your items here",
    },
  },
  subjects: {
    title: "Subjects",
    searchInput: {
      placeholder: "Search by court or subject number",
    },
    createSubjectBtn: "New subject",
    subjectDropdown: {
      showBtn: "See subject",
      editBtn: "Edit details",
      deleteBtn: "Delete subject",
    },
    table: {
      columns: {
        type: "Type",
        subjectNumber: "Subject number",
        courtNumber: "Court number",
        courtName: "Court Name",
        clientName: "Client"
      },
      pagination: {
        show: "Showing",
        of: "of",
      },
    },
    mobileSearchInput: {
      placeholder: "Search by subject number",
    },
  },
  subjectFormModal: {
    createTitle: "New subject",
    editTitle: "Edit subject",
    saveBtn: "Save",
    closeBtn: "Cancel",
    deleteBtn: "Delete",
    subjectNumber: {
      label: "Subject number",
    },
    courtNumber: {
      label: "Court number",
    },
    mobilePhoneInput: {
      label: "Mobile",
    },
    type: {
      label: "Type",
      options: {
        culpability: "Culpability",
        litigation: "Litigation"
      }
    },
    courtName: {
      label: "Court",
    },
    subjectInput: {
      searchPlaceholder: "Search by court name",
    },
    clientInput: {
      label: "Select client",
      placeholder: "Select client",
      searchPlaceholder: "Search client by name",
      noResultsText: "No results",

    },
    clientType: {
      label: "Select Client type",
      options: {
        defendant: "Defendant",
        prosecutor: "Prosecutor"
      },
    },
    otherClient: {
      label: "Input other client",
      firstNameInput: "First name",
      lastNameInput: "Last name",
      mobilePhoneInput: "Phone",
    },
    validation: {
      error: "Field is required",
    },
  },
  deleteSubjectModal: {
    title: "Delete subject",
    message: "Are you sure that you want to delete subject and all of its data",
    confirmBtn: "Yes",
    cancelBtn: "No",
  },
  subject: {
    title: "Subject",
    createAppointmentDropdown: {
      toggleBtn: "Schedule New",
      appointmentBtn: "New appointment",
      groupAppointmentBtn: "New group appointment"
    },
    dropdown: {
      editBtn: "Edit details",
      deleteBtn: "Delete subject",
    },
    infoCard: {
      email: {
        label: "Email",
      },
      phone: {
        label: "Phone number",
      },
      type: {
        label: "Client type",
        options: {
          culpability: "Culpability",
          litigation: "Litigation"
        }
      },
      clientType: {
        label: "Client type",
        options: {
          defendant: "Defendant",
          prosecutor: "Prosecutor"
        },
      },
      courtNumber: {
        label: "Court number",
      },
      courtName: {
        label: "Court name",
      },
      otherClient: {
        label: "Information about the other client",
        firstNameInput: "First name",
        lastNameInput: "Last name",
        mobilePhoneInput: "Phone",
      },
    },
    salesCard: {
      title: "Total sales",
      bookings: {
        all: "All bookings",
        completed: "Completed",
        canceled: "Cancelled",
      },
    },
    mobileButtons: {
      editBtn: "Edit details",
      deleteBtn: "Delete subject",
      closeBtn: "Close",
    },
  },
};
