import _ from "lodash";
import appointmentMapper from "@/core/services/helpers/appointmentMapper.js";

const clientMapper = {
  /**
   * Map array of client objects
   * @param {Array} clients
   * @return {Array}
   */
  mapClients: function(clients) {
    return clients.map(client => {
      return this.mapClient(client);
    });
  },

  /**
   * Map client object
   * @param {Object} client
   * @return {Object}
   */
  mapClient: function(client) {
    client.fullName = client.first_name + " " + client.last_name;
    client.initials =
      client.first_name.charAt(0).toUpperCase() +
      client.last_name.charAt(0).toUpperCase();
    if (client.appointments) {
      client.appointments = appointmentMapper.mapAppointments(
        client.appointments
      );
    }
    return _.mapKeys(client, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of client objects to options for select component
   * @param {Array} clients
   * @return {Array}
   */
  mapClientsAsOptions(clients) {
    let options = [];
    clients.forEach(client => {
      let option = {};
      option.text = client.fullName;
      option.value = client.id;
      option.description = client.email;
      option.initials = client.initials;
      options.push(option);
    });
    return options;
  }
};

export default clientMapper;
