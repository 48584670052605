import httpService from "@/core/services/http.service.js";
import serviceCategoryMapper from "@/core/services/helpers/serviceCategoryMapper.js";

// action types
export const GET_SERVICE_CATEGORIES = "getServiceCategories";

// mutations types
export const SET_SERVICE_CATEGORIES = "setServiceCategories";

const state = {
  serviceCategories: []
};

const getters = {
  allServiceCategories(state) {
    return state.serviceCategories;
  },
  getServiceCategory: state => id =>
    state.serviceCategories.find(serviceCategory => serviceCategory.id === id)
};

const actions = {
  [GET_SERVICE_CATEGORIES](context) {
    return httpService
      .get("/api/b2b/services")
      .then(response =>
        context.commit(SET_SERVICE_CATEGORIES, response.data.data)
      );
  }
};

const mutations = {
  [SET_SERVICE_CATEGORIES](state, serviceCategories) {
    state.serviceCategories = serviceCategoryMapper.mapServiceCategories(
      serviceCategories
    );
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
