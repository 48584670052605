import _ from "lodash";
import clientMapper from "@/core/services/helpers/clientMapper.js";
import serviceMapper from "@/core/services/helpers/serviceMapper.js";
import subjectMapper from "@/core/services/helpers/subjectMapper.js";

const appointmentMapper = {
  /**
   * Map array of appointment objects
   * @param {Array} appointments
   * @return {Array}
   */
  mapAppointments: function(appointments) {
    return appointments.map(appointment => {
      return this.mapAppointment(appointment);
    });
  },

  /**
   * Map appointment object
   * @param {Object} appointment
   * @return {Object}
   */
  mapAppointment: function(appointment) {
    if (appointment.client) {
      appointment.client = clientMapper.mapClient(appointment.client);
    }
    if (appointment.service) {
      appointment.service = serviceMapper.mapService(appointment.service);
    }
    if (appointment.subject) {
      appointment.subject = subjectMapper.mapSubject(appointment.subject);
    }
    if (appointment.from) {
      appointment.from = appointment.from.slice(0, -3);
    }
    if (appointment.to) {
      appointment.to = appointment.to.slice(0, -3);
    }
    if (appointment.clients) {
      appointment.clients = clientMapper.mapClients(appointment.clients);
    }
    return _.mapKeys(appointment, (value, key) => _.camelCase(key));
  }
};

export default appointmentMapper;
