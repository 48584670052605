import _ from "lodash";

const subjectMapper = {
  /**
   * Map array of subject objects
   * @param {Array} subjects
   * @return {Array}
   */
  mapSubjects: function(subjects) {
    return subjects.map(subject => {
      return this.mapSubject(subject);
    });
  },

  /**
   * Map subject object
   * @param {Object} subject
   * @return {Object}
   */
  mapSubject: function(subject) {
    return _.mapKeys(subject, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of subject objects to options for select component
   * @param {Array} subjects
   * @return {Array}
   */
  mapSubjectsAsOptions(subjects) {
    let options = [];
    subjects.forEach(subject => {
      let option = {};
      option.value = subject.id;
      option.type = subject.type;
      option.courtNumber = subject.courtNumber;
      option.text = subject.courtName;
      option.subjectNumber = subject.subjectNumber;
      option.clientId = subject.clientId;
      option.clientType = subject.clientType;
      option.otherClient = subject.otherClient;
      options.push(option);
    });
    return options;
  }
};

export default subjectMapper;
