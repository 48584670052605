// USA
export const locale = {
  login: {
        title: "Willkommen zurück",
        emailLabel: "E-Mail",
        emailValidationError: "E-Mail ist erforderlich und muss eine gültige E-Mail-Adresse sein.",
        passwordLabel: "Passwort",
        passwordValidationError: "Passwort ist erforderlich.",
        keepMeLabel: "Angemeldet bleiben",
        forgotPassword: "Passwort vergessen?",
        loginBtn: "Einloggen",
        dontHaveAccount: "Sie haben noch keinen Account?",
        signUp: "Registrieren",
      },
      forgotPassword: {
        title: "Passwort vergessen?",
        emailInput: {
          label: "E-Mail",
          description: "Geben Sie Ihre E-Mail-Adresse ein, um den Link zum Zurücksetzen des Passworts zu erhalten.",
        },
        submitBtn: "Senden",
      },
      resetPassword: {
        title: "Passwort zurücksetzen",
        passwordInput: {
          label: "Neues Passwort",
        },
        passwordConfirmationInput: {
          label: "Neues Passwort bestätigen",
        },
        submitBtn: "Passwort zurücksetzen",
      },
      menu: {
        calendar: "Kalender",
        clients: "Kunden",
        services: "Dienstleistungen",
        staff: "Personal",
        statistics: "Statistiken",
        settings: "Einstellungen",
        profile: "Profil",
        contactSupport: "Kontaktiere den Support",
        logout: "Ausloggen",
      },
      calendar: {
        title: "Kalender",
        alert: {
          title: "Willkommen in Ihrem Kalender",
          text: "Termine, die Kunden oder Sie buchen, werden hier angezeigt. Klicken Sie auf einen Termin, um die Details anzuzeigen und zu verwalten.",
        },
        newEventActions: {
          appointment: "Termin",
          appointmentMobile: "Neuer Termin",
          groupAppointment: "Gruppentermin",
          blockTime: "Blockzeit",
          close: "Schließen",
        },
        appointment: {
          cancelAppointment: "Termin absagen",
          editAppointment: "Termin bearbeiten",
          notesLabel: "Notizen",
          noNotesText: "Keine Notizen für diesen Termin",
          employee: "Mitarbeiter",
        },
        blockTime: {
          removeBlockTime: "Blockzeit entfernen",
          editBlockTime: "Blockzeit bearbeiten",
        },
        calendarEventCard: {
          clientsLabel: "Kunden"
        },
      },
      calendarFilters: {
        placeFilter: {
          createPlace: "Neuen Ort erstellen",
        },
        staffFilter: {
          allStaff: "Alle Mitarbeiter",
          createStaff: "Neuen Mitarbeiter erstellen",
        },
        viewFilter: {
          week: "Woche",
          day: "Tag",
        },
        mobileViewFilter: {
          title: "Kalenderansicht",
          day: "Tag",
          threeDays: "3 Tage",
        },
        mobilePlaceAndStaffFilter: {
          title: "Filter",
          placesLabel: "Orte",
          staffLabel: "Mitarbeiter",
          allStaffLabel: "Alle Mitarbeiter",
        },
      },
      appointmentForm: {
        createTitle: "Neuer Termin",
        createGroupTitle: "Neuer Gruppentermin",
        editTitle: "Termin bearbeiten",
        editGroupTitle: "Gruppentermin bearbeiten",
        createSubmitBtn: "Termin erstellen",
        editSubmitBtn: "Speichern",
        noDateLabel: "Datum hinzufügen",
        clientInput: {
          label: "Kunde",
          placeholder: "Kunde hinzufügen",
          searchPlaceholder: "Kunde suchen",
          noResultsText: "Kein Kunde verfügbar",
          editBtn: "Details bearbeiten",
          removeBtn: "Von Termin entfernen",
          createBtn: "Neuen Kunden erstellen",
          details: {
            title: "Kundendetails",
            emailLabel: "E-Mail",
            phoneLabel: "Telefonnummer",
            notesLabel: "Kundennotizen",
          },
        },
        clientsInputBtn: "Kunden auswählen",
        serviceInput: {
          label: "Dienstleistung",
          placeholder: "Dienstleistung hinzufügen",
          searchPlaceholder: "Dienstleistung suchen",
          noResultsText: "Keine Dienstleistung verfügbar",
          createBtn: "Neue Dienstleistung erstellen",
        },
        staffInput: {
          label: "Mitarbeiter",
          placeholder: "Mitarbeiter auswählen",
          searchPlaceholder: "Mitarbeiter suchen",
          noResultsText: "Kein Mitarbeiter verfügbar",
          createBtn: "Neuen Mitarbeiter erstellen",
        },
        startTimeInput: {
          label: "Startzeit",
          placeholder: "Zeit auswählen",
          noResultsText: "Kein verfügbarer Zeitraum",
        },
        durationLabel: "Dauer",
        notesInput: {
          label: "Terminnotizen",
          placeholder: "Terminnotiz hinzufügen (nur für Mitarbeiter sichtbar)",
        },
      },
      blockTimeForm: {
        title: "Blockzeit",
        createSubmitBtn: "Blockierte Zeit erstellen",
        editSubmitBtn: "Blockierte Zeit bearbeiten",
        dateLabel: "Datum hinzufügen",
        staffInput: {
          label: "Mitarbeiter",
          placeholder: "Mitarbeiter auswählen",
          searchPlaceholder: "Mitarbeiter suchen",
          noResultsText: "Kein Mitarbeiter verfügbar",
          createBtn: "Neuen Mitarbeiter erstellen",
        },
        startTimeInput: {
          label: "Startzeit",
          placeholder: "Startzeit",
          noResultsText: "Kein verfügbarer Zeitraum",
        },
        endTimeInput: {
          label: "Endzeit",
          placeholder: "Endzeit",
          noResultsText: "Kein verfügbarer Zeitraum",
        },
        notesInput: {
          label: "Beschreibung",
          placeholder: "z. B. Mittagstreffen",
        },
      },
      dateInput: {
        appointmentTitle: "Termin Datum",
        blockTimeTitle: "Datum auswählen",
        mobileTitle: "Datum auswählen",
        saveBtn: "Speichern",
      },
      calendarForm: {
        createTitle: "Neuer Mitarbeiter",
        editTitle: "Mitarbeiter bearbeiten",
        saveBtn: "Speichern",
        cancelBtn: "Abbrechen",
        mobileButtons: {
          sendResetPassword: "Passwort zurücksetzen E-Mail senden",
          delete: "Mitarbeiter löschen",
          close: "Schließen",
        },
        basicSettings: {
          title: "Grundeinstellungen",
          profilePictureInput: {
            label: "Profilbild (optional, aber sehr empfohlen)",
            description: "Dieses Foto wird auf Ihrer Profilseite angezeigt",
            mobileLabel: "Profilbild",
            mobileDescription: "(optional, aber sehr empfohlen)",
            uploadBtn: "Profilbild hochladen",
            deleteBtn: "Löschen",
          },
          nameInput: {
            label: "Name",
            placeholder: "z. B. Linda",
          },
          titleInput: {
            label: "Titel",
            placeholder: "z. B. Friseur",
          },
          personalDetails: {
            label: "Persönliche Informationen",
            description:
              "Diese Informationen werden nur für interne Kommunikation und Benachrichtigungen verwendet.",
          },
          firstNameInput: {
            label: "Vorname",
          },
          lastNameInput: {
            label: "Nachname",
          },
          emailInput: {
            label: "E-Mail",
          },
          phoneInput: {
            label: "Telefonnummer",
          },
          roleInput: {
            label: "Rolle",
            manageAccountLabel: "Konto verwalten",
            managePlaceLabel: "Ort verwalten",
            manageBookingsLabel: "Buchungen für den Ort verwalten",
            manageCalendarLabel: "Eigenen Kalender verwalten",
          },
        },
        availability: {
          title: "Verfügbarkeit", // USA
          description:
            "Wählen Sie aus, zu welcher Zeit Kunden Dienstleistungen in diesem Kalender buchen können",
          editBtn: "Bearbeiten",
          removeBtn: "Entfernen",
          addDayOffBtn: "+ Spezifischen freien Tag hinzufügen",
          closedLabel: "Geschlossen",
        },
        placeAndServices: {
          title: "Ort und Dienstleistungen",
          placeInput: {
            label: "Zu welchem Ort gehört dieser Kalender?",
          },
          servicesInput: {
            label: "Welche Dienstleistungen können mit diesem Kalender gebucht werden?",
          },
        },
        advancedSettings: {
          title: "Erweiterte Einstellungen",
          requiredInfoInput: {
            label: "Welche Informationen sind erforderlich, wenn der Kunde bucht?",
            firstNameLabel: "Vorname",
            lastNameLabel: "Nachname",
            emailLabel: "E-Mail",
            phoneLabel: "Telefonnummer",
            addressLabel: "Adresse",
            zipCodeLabel: "Postleitzahl",
            cityLabel: "Stadt",
            otherLabel: "Andere",
          },
          otherInput: {
            label: 'Alias für "Andere"',
            placeholder: "Andere",
          },
          cancellationMarginInput: {
            label: "Stornierungsspanne",
            description:
              "Wie lange vor der Buchung ist eine Stornierung möglich?",
            locationDescription:
              "Wählen Sie den Ort aus, für den Sie die Buchungsstornierungen bearbeiten möchten.",
            daysLabel: "Tage",
            hoursLabel: "Stunden",
            minutesLabel: "Minuten",
          },
          bookingMarginInput: {
            label: "Buchungsspanne",
            description: "Wie nah an einer verfügbaren Zeit kann gebucht werden?",
            daysLabel: "Tage",
            hoursLabel: "Stunden",
            minutesLabel: "Minuten",
          },
          tocInput: {
            label: "Allgemeine Geschäftsbedingungen (optional)",
            description:
              "Wenn Sie Allgemeine Geschäftsbedingungen hinzufügen, müssen die Kunden diesen zustimmen, indem sie sie lesen und ein Kontrollkästchen ankreuzen, um eine Buchung zu erstellen.",
          },
        },
        notifications: {
          title: "Benachrichtigungen",
          toServiceProviderInput: {
            label: "An Dienstleister",
            description:
              'Benachrichtigungen werden an die E-Mail-Adresse gesendet, die in "Persönliche Informationen" angegeben ist',
            bookingConfirmationLabel: "Buchungsbestätigung",
            cancellationConfirmationLabel: "Stornierungsbestätigung",
          },
          toCustomerInput: {
            label: "An Kunden",
            description:
              "Benachrichtigungen werden an die E-Mail-Adresse gesendet, die vom Kunden angegeben wurde",
            bookingConfirmationLabel: "Buchungsbestätigung",
            cancellationConfirmationLabel: "Stornierungsbestätigung",
            reminderLabel: "Erinnerung an Buchung",
            followUpLabel: "Nachverfolgung",
          },
          followUpInput: {
            label: "Nachverfolgung",
            description:
              "Steigern Sie die Wahrscheinlichkeit wiederkehrender Buchungen, indem Sie eine Nachverfolgungsmail senden",
            daysLabel: "Tage nach der gebuchten Zeit",
          },
        },
      },
      calendarFormModal: {
        createTitle: "Neues Personal hinzufügen",
        editTitle: "Personal bearbeiten",
        createBtn: "Personal hinzufügen",
        editBtn: "Speichern",
        basicInfoCard: {
          title: "Grundinformationen",
          nameInput: {
            label: "Name",
          },
          titleInput: {
            label: "Bezeichnung des Personals",
            description: "Diese Bezeichnung wird für Kunden sichtbar sein",
          },
          notesInput: {
            label: "Notizen",
            labelDescription: "(optional)",
            placeholder: "Private Notizen hinzufügen, die nur im Personalbereich angezeigt werden",
          },
          contactTitle: "Kontakt",
          contactDescription:
            "Kontaktdaten des Personals sind vertraulich und werden nicht mit Ihren Kunden geteilt.",
          firstNameInput: {
            label: "Vorname",
          },
          lastNameInput: {
            label: "Nachname",
          },
          emailInput: {
            label: "E-Mail",
          },
          phoneInput: {
            label: "Telefonnummer",
          },
          employmentTitle: "Beschäftigung",
          employmentStartInput: {
            label: "Beginndatum",
          },
          employmentEndInput: {
            label: "Enddatum",
          },
        },
        placesCard: {
          title: "Arbeitet in",
          description: "Wählen Sie die Standorte aus, an denen dieses Personal arbeitet.",
          placesInput: {
            allLabel: "Alle Standorte",
          },
        },
        bookingCard: {
          title: "Buchung",
          bookingsEnabledInput: {
            label: "Kalenderbuchungen zulassen",
            description:
              "Ermöglichen Sie diesem Personal, Buchungen im Kalender zu erhalten.",
          },
        },
        colorCard: {
          title: "Kalenderfarbe",
          description:
            "Wählen Sie eine Farbe aus, um die Termine dieses Personals im Kalender anzuzeigen",
          colorInput: {
            label: "Farbe auswählen",
          },
        },
        servicesCard: {
          title: "Dienstleistungen",
          description: "Fügen Sie die Dienstleistungen hinzu, die dieses Personal anbieten kann.",
          servicesInput: {
            allSelectedLabel: "Alle Dienstleistungen",
            countSelectedLabel: "Ausgewählte Dienstleistungen",
            noSelectedLabel: "Keine ausgewählten Dienstleistungen",
            editBtn: "Bearbeiten",
          },
        },
        clientsCard: {
          title: "Kunden",
          description:
            "Fügen Sie Kunden hinzu, die das Personal sehen wird, selbst wenn es nur über Grundberechtigungen verfügt",
          clientsInput: {
            allSelectedLabel: "Alle Kunden",
            countSelectedLabel: "Ausgewählte Kunden",
            noSelectedLabel: "Keine ausgewählten Kunden",
            editBtn: "Bearbeiten",
          },
        },
        permissionsCard: {
          title: "Berechtigungen des Personals",
        },
        selectServicesModal: {
          title: "Dienstleistungen auswählen",
          searchInput: {
            placeholder: "Dienstleistungen suchen",
          },
          allServicesLabel: "Alle Dienstleistungen",
          closeBtn: "Abbrechen",
          saveBtn: "Speichern",
        },
        selectClientsModal: {
          title: "Kunden auswählen",
          searchInput: {
            placeholder: "Kunden suchen",
          },
          allClientsLabel: "Alle Kunden",
          closeBtn: "Abbrechen",
          saveBtn: "Speichern",
        },
        validation: {
          error: "Feld ist ungültig",
        },
      },
      clients: {
        title: "Kunden",
        searchInput: {
          placeholder: "Nach Name, E-Mail oder Telefonnummer suchen",
        },
        createClientBtn: "Neuer Kunde",
        clientDropdown: {
          showBtn: "Profil anzeigen",
          editBtn: "Details bearbeiten",
          deleteBtn: "Kunden löschen",
        },
        table: {
          columns: {
            name: "Name",
            phone: "Telefonnummer",
            email: "E-Mail",
          },
          pagination: {
            show: "Anzeigen",
            of: "von",
          },
        },
        mobileSearchInput: {
          placeholder: "Nach Kundenname suchen",
        },
      },
      clientProfile: {
        title: "Kundenprofil",
        createAppointmentDropdown: {
          toggleBtn: "Terminplanung",
          appointmentBtn: "Neuer Termin",
          groupAppointmentBtn: "Neuer Gruppentermin"
        },
        dropdown: {
          editBtn: "Details bearbeiten",
          deleteBtn: "Kunden löschen",
        },
        infoCard: {
          email: {
            label: "E-Mail",
          },
          phone: {
            label: "Telefonnummer",
          },
          notes: {
            label: "Kundennotizen",
          },
          notifications: {
            label: "Benachrichtigungen",
            enabledLabel: "Aktiviert",
            disabledLabel: "Deaktiviert",
            viaLabel: "Benachrichtigungstyp",
            emailLabel: "Per E-Mail",
            unknownLabel: "Unbekannt",
          },
        },
        salesCard: {
          title: "Gesamtverkäufe",
          bookings: {
            all: "Alle Buchungen",
            completed: "Abgeschlossen",
            canceled: "Abgebrochen",
          },
        },
        mobileButtons: {
          editBtn: "Details bearbeiten",
          deleteBtn: "Kunden löschen",
          closeBtn: "Schließen",
        },
      },
    appointmentList: {
        title: "Vorherige Termine",
        noAppointmentsLabel: "Keine vorherigen Termine",
        status: {
          active: "Aktiv",
          finished: "Abgeschlossen",
          canceled: "Abgesagt",
        },
      },
      clientForm: {
        createTitle: "Neuen Kunden erstellen",
        editTitle: "Kunden bearbeiten",
        saveBtn: "Speichern",
        cancelBtn: "Abbrechen",
        contactDetails: {
          title: "Kontaktdaten",
          firstNameInput: {
            label: "Vorname",
          },
          lastNameInput: {
            label: "Nachname",
          },
          emailInput: {
            label: "E-Mail",
          },
          phoneInput: {
            label: "Telefonnummer",
          },
          notificationsInput: {
            label: "Benachrichtigungen",
            description: "Benachrichtigungen werden an die oben angegebene E-Mail-Adresse gesendet",
            notificationsLabel: "Benachrichtigungen",
          },
        },
        aditionalInfo: {
          title: "Zusätzliche Informationen (optional)",
          addressInput: {
            label: "Adresse",
          },
          cityInput: {
            label: "Stadt",
          },
          zipCodeInput: {
            label: "PLZ / Postleitzahl",
          },
          notesInput: {
            label: "Kundenhinweise",
            placeholder: "z. B. allergisch gegen Nitrilhandschuhe",
          },
        },
      },
      clientFormModal: {
        createTitle: "Neuer Kunde",
        editTitle: "Kunden bearbeiten",
        saveBtn: "Speichern",
        closeBtn: "Abbrechen",
        deleteBtn: "Löschen",
        tabs: {
          personalInfo: "Persönliche Informationen",
          address: "Adresse",
        },
        firstNameInput: {
          label: "Vorname",
        },
        lastNameInput: {
          label: "Nachname",
        },
        mobilePhoneInput: {
          label: "Mobiltelefon",
        },
        phoneInput: {
          label: "Telefon",
        },
        emailInput: {
          label: "E-Mail",
        },
        sendNotificationsByInput: {
          label: "Benachrichtigungen senden über",
          options: {
            email: "E-Mail",
            dontSend: "Keine Benachrichtigungen senden",
          },
        },
        notificationsEnabledInput: {
          label: "Akzeptiert Marketing-Benachrichtigungen",
        },
        genderInput: {
          label: "Geschlecht",
          options: {
            unknown: "Unbekannt",
            male: "Männlich",
            female: "Weiblich",
          },
        },
        birthdayInput: {
          label: "Geburtstag",
          monthPlaceholder: "Monat",
          dayPlaceholder: "Tag",
          yearPlaceholder: "Jahr",
        },
        notesInput: {
          label: "Kundenhinweise",
        },
        displayNotesInput: {
          label: "Auf allen Buchungen anzeigen",
        },
        addressInput: {
          label: "Adresse",
        },
        cityInput: {
          label: "Stadt",
        },
        stateInput: {
          label: "Bundesland",
        },
        zipCodeInput: {
          label: "PLZ / Postleitzahl",
        },
        mobileSection: {
          notesSection: {
            title: "Kundenhinweise",
            notesInput: {
              label: "Kundenhinweise",
            },
            displayNotesInput: {
              label: "Auf allen Buchungen anzeigen",
            },
          },
          additionalInfoSection: {
            title: "Zusätzliche Informationen",
            personalInfoBtn: "Persönliche Informationen",
            addressDetailsBtn: "Adressdetails",
          },
        },
        validation: {
          error: "Feld ist erforderlich",
        },
      },
      addressDetailsMobileModal: {
        title: "Adressdetails",
        addressInput: {
          label: "Adresse",
        },
        cityInput: {
          label: "Stadt",
        },
        stateInput: {
          label: "Bundesland",
        },
        zipCodeInput: {
          label: "PLZ / Postleitzahl",
        },
        saveBtn: "Speichern",
      },
      personalInfoMobileModal: {
        title: "Persönliche Informationen",
        genderInput: {
          label: "Geschlecht",
        },
        birthdayInput: {
          label: "Geburtstag",
          monthPlaceholder: "Monat",
          dayPlaceholder: "Tag",
          yearPlaceholder: "Jahr",
        },
        saveBtn: "Speichern",
      },
      services: {
        title: "Dienstleistungen",
        createServiceBtn: "Neue hinzufügen",
        table: {
          createServiceBtn: "Dienstleistung hinzufügen",
          dropdown: {
            createServiceBtn: "Neue Dienstleistung hinzufügen",
            editCategoryNameBtn: "Kategorienamen bearbeiten",
            deleteCategoryBtn: "Kategorie löschen",
          },
          editBtn: "Bearbeiten",
          minutesLabel: "Minuten",
          noDescriptionLabel: "Keine Dienstleistungsbeschreibung verfügbar.",
          groupServiceLabel: "Klasse",
        },
        mobileButtons: {
          createServiceBtn: "Neue Dienstleistung hinzufügen",
          editCategoryNameBtn: "Kategorienamen bearbeiten",
          deleteCategoryBtn: "Kategorie löschen",
          closeBtn: "Schließen",
        },
        mobileServiceButtons: {
          editServiceBtn: "Dienstleistung bearbeiten",
          deleteServiceBtn: "Dienstleistung löschen",
          closeBtn: "Schließen",
        },
        tableFilter: {
          services: "Dienstleistungen",
          categories: "Kategorien",
        },
      },
      mobileServicesFilter: {
        title: "Filter",
        services: "Dienstleistungen",
        categories: "Kategorien",
      },
      serviceForm: {
        createTitle: "Dienstleistung hinzufügen",
        editTitle: "Dienstleistung bearbeiten",
        cancelBtn: "Abbrechen",
        saveBtn: "Speichern",
        deleteBtn: "Löschen",
        nameInput: {
          label: "Dienstleistungsname",
          placeholder: "z. B. Damen-Haarschnitt oder Ganzkörpermassage",
        },
        categoryInput: {
          label: "Kategorie",
          description:
            "Die Dienstleistung wird in dieser Kategorie auf Ihrem öffentlichen Profil platziert",
          placeholder: "Kategorie durch Eingabe auswählen oder aus vorherigen auswählen",
        },
        descriptionInput: {
          label: "Beschreibung (optional)",
          placeholder: "z. B. Damen-Haarschnitt inklusive Waschen und Föhnen",
        },
        priceInput: {
          label: "Preis",
          showPublicLabel: "Öffentlich anzeigen",
        },
        durationInput: {
          label: "Dauer (Minuten)",
          showPublicLabel: "Öffentlich anzeigen",
        },
        serviceTypeInput: {
          label: "Art der Dienstleistung",
          description:
            "Ermöglichen Sie Suchmaschinen, den Kunden genauere Ergebnisse zu liefern",
        },
        sameTimePeopleInput: {
          label: "Anzahl der Personen, die gleichzeitig buchen können",
          description: "z. B. 20 Personen können an einer Yoga-Sitzung teilnehmen",
          inputLabel: "Personen",
          toggleBtn: "+ Mehr Personen zur gleichen Zeit buchen lassen",
        },
        breakInput: {
          label: "Pause nach Buchung dieser Dienstleistung",
          description:
            "Niemand kann diese vielen Minuten nach Abschluss dieser Dienstleistung buchen",
          inputLabel: "Minuten",
          toggleBtn: "+ Pause nach der Dienstleistung hinzufügen",
        },
      },
      serviceFormModal: {
        titles: {
          chooseType: "Fügen Sie Ihrem Dienstleistungsmenü ein Angebot hinzu",
          singleService: "Eine neue einzelne Dienstleistung hinzufügen",
        },
        serviceTypes: {
          single: {
            name: "Dienstleistung",
            description: "Dienstleistungen, die von einem Kunden bei einem einzigen Besuch gebucht werden",
          },
          session: {
            name: "Klasse",
            description:
              "Dienstleistungen, die von mehreren Kunden in geplanten Sitzungen gebucht werden",
          },
        },
        comingSoonLabel: "Demnächst verfügbar",
        saveBtn: "Speichern",
        basicInfoCard: {
          title: "Grundlegende Informationen",
          description: "Fügen Sie einen Dienstleistungsnamen hinzu und wählen Sie die Behandlungsart aus.",
          nameInput: {
            label: "Dienstleistungsname",
          },
          typeInput: {
            label: "Behandlungsart",
            placeholder: "Behandlungsart auswählen",
            description:
              "Wählen Sie die relevanteste Behandlungsart aus. Diese wird für Ihre Kunden nicht sichtbar sein.",
          },
          categoryInput: {
            label: "Kategorie",
            description:
              "Die Dienstleistung wird in dieser Kategorie auf Ihrem öffentlichen Profil platziert",
            placeholder: "Kategorie durch Eingabe auswählen oder aus vorherigen auswählen",
          },
          descriptionInput: {
            label: "Dienstleistungsbeschreibung",
            placeholder: "Kurzbeschreibung hinzufügen",
          },
        },
        onlineBookingsCard: {
          title: "Online-Buchungen",
          description:
            "Aktivieren Sie Online-Buchungen, wählen Sie aus, für wen die Dienstleistung verfügbar ist, und fügen Sie eine kurze Beschreibung hinzu",
          onlineBookingsInput: {
            label: "Online-Buchungen aktivieren",
          },
        },
        staffCard: {
          title: "Mitarbeiter",
          staffInput: {
            allLabel: "Alle auswählen",
          },
        },
        pricingCard: {
          title: "Preisgestaltung und Dauer",
          description: "Fügen Sie die Preisoptionen und die Dauer der Dienstleistung hinzu.",
          priceTitle: "Preisoption",
          durationInput: {
            label: "Dauer",
          },
          priceTypeInput: {
            label: "Preistyp",
          },
          priceInput: {
            label: "Preis",
          },
          extraTimeTitle: "Zusätzliche Zeit",
          extraTimeDescription: "Aktivieren Sie zusätzliche Zeit nach der Dienstleistung.",
          enableExtraTimeInput: {
            label: "Zusätzliche Zeit aktivieren",
          },
          extraTimeCard: {
            title: "Blockierte Zeit",
            description: "Blockieren Sie Zeit zwischen den Terminen",
          },
          breakDurationInput: {
            label: "Dauer",
          },
          priceTypeDropdown: {
            free: "Kostenlos",
            fixed: "Festpreis",
            from: "Ab",
          },
        },
        sameTimePeopleCard: {
          title: "Anzahl der Personen in der Sitzung",
          description: "Legen Sie die maximale Anzahl von Personen fest, die diese Dienstleistung gleichzeitig buchen können.",
        },
        validation: {
          error: "Feld ist ungültig",
        },
      },
      staff: {
        title: "Mitarbeiter",
        createStaffBtn: "Neuer Mitarbeiter",
        table: {
          columns: {
            name: "Name",
            phone: "Telefonnummer",
            email: "E-Mail",
          },
          dropdown: {
            editBtn: "Details bearbeiten",
            deleteBtn: "Mitarbeiter löschen",
          },
          pagination: {
            show: "Anzeigen",
            of: "von",
          },
        },
        mobileButtons: {
          editStaffBtn: "Mitarbeiter bearbeiten",
          editWorkingHoursBtn: "Arbeitszeiten bearbeiten",
          deleteStaffBtn: "Mitarbeiter löschen",
          closeBtn: "Schließen",
        },
      },
      mobileStaffFilter: {
        title: "Filter",
        placesLabel: "Orte",
      },
      statistics: {
        title: "Statistiken",
        totalSalesLabel: "Gesamtumsatz",
        totalAppointmentsLabel: "Gesamttermine",
        completedAppointmentsLabel: "Abgeschlossene Termine",
        canceledAppointmentsLabel: "Abgesagte Termine",
        staffSalesLabel: "Umsatz nach Mitarbeitern",
        noDataLabel: "Keine verfügbaren Daten",
        topServicesCard: {
          title: "Top-Verkaufsdienstleistungen",
          serviceNameLabel: "Dienstleistungsname",
          totalSalesLabel: "Gesamtumsatz (SEK)",
        },
        charts: {
            currentIncomeLabel: "Aktuelles Einkommen",
            pastYearLabel: "Vergangenes Jahr",
        },
      }, 
    statisticsFilters: {
        placeFilter: {
            allLocationsLabel: "Alle Standorte",
        },
        periodFilter: {
            dayLabel: "Tag",
            monthLabel: "Monat",
            yearLabel: "Jahr",
        },
    mobilePlaceFilter: {
    title: "Filter",
    allLocationsLabel: "Alle Standorte",
    },
    mobilePeriodFilter: {
    title: "Datum auswählen",
    },
    },
    settings: {
    title: "Einstellungen",
    tabs: {
    widget: "Widget-Einstellungen",
    account: "Kontoeinstellungen",
    locations: "Standorte",
    billing: "Abrechnung",
    },
    mobileTabsSelect: {
    widget: {
    title: "Widget-Einstellungen",
    description: "Passen Sie Ihr Widget an.",
    },
    account: {
    title: "Kontoeinstellungen",
    description:
    "Verwalten Sie Einstellungen wie Ihre Geschäftsdaten und Online-Links.",
    },
    locations: {
    title: "Standorte",
    description: "Verwalten Sie mehrere Standorte für Ihr Unternehmen.",
    },
    billing: {
    title: "Abrechnung",
    description:
    "Verwalten Sie Ihren Tarif, die Zahlungsmethode und sehen Sie die Abrechnungshistorie ein.",
    },
    },
    },
    widgetTab: {
    previewBtn: "Widget-Vorschau",
    saveBtn: "Speichern",
    backBtn: "Zurück zu den Widget-Einstellungen",
    appearance: {
    title: "Erscheinungsbild",
    logoInput: {
    label: "Firmenlogo:",
    description: "Hochladen",
    },
    colorInput: {
    label: "Hintergrundfarbe:",
    },
    statusInput: {
    label: "Status:",
    },
    messageInput: {
    label: "Nachricht:",
    },
    showReviewsInput: {
    label: "Bewertungen anzeigen:",
    },
    },
    installation: {
    title: "Installation",
    description:
    "Es sieht so aus, als hätten Sie den Chat-Code noch nicht installiert. Wählen Sie eine der folgenden Installationsanleitungen aus:",
    jsCard: {
    instructions: {
    step1: "Fügen Sie diesen Code kurz vor dem Schließen des </body>-Tags ein",
    },
    copyBtn: "Kopieren",
    needHelpLabel: "Benötigen Sie Hilfe?",
    contactUsLabel: "Kontaktieren Sie uns.",
    },
    wpCard: {
    instructions: {
    step1: "Melden Sie sich in Ihrem WordPress-Admin-Panel an",
    step2: "Gehen Sie zu Aussehen > Theme-Editor",
    step3:
    "Klicken Sie unter Theme-Dateien (rechts) auf „Theme Footer“ (footer.php)",
    step4: "Fügen Sie Ihren Bookybook-Code vor dem </body>-Tag ein",
    step5: 'Klicken Sie auf "Datei aktualisieren", um die Änderungen zu speichern',
    },
    copyBtn: "Kopieren",
    needHelpLabel: "Benötigen Sie Hilfe?",
    contactUsLabel: "Kontaktieren Sie uns.",
    },
    },
    widget: {
    startBookingLabel: "Buchen Sie einen Termin",
    nextFreeTimeLabel: "Nächste freie Zeit verbleibend",
    bookingBtn: "Vereinbaren Sie einen Termin mit uns",
    reviewsLabel: "Das sagen unsere Kunden über uns",
    poweredByLabel: "Bereitgestellt von",
    },
    },
    accountTab: {
    guide: {
    businessInfo: {
    title: "Unternehmensinformationen",
    description:
    "Diese Informationen werden nur für interne Kommunikation verwendet. Sie werden nicht auf Ihrem Profil angezeigt.",
    },
    onlineLinks: {
    title: "Online-Links",
    description:
    "Fügen Sie Ihre Unternehmenswebsite und Social-Media-Links hinzu, um sie mit Kunden zu teilen.",
    },
    },
    companyDetailsCard: {
    title: "Unternehmensdetails",
    nameInput: {
    label: "Unternehmensname",
    },
    organisationNumberInput: {
    label: "Schwedische Organisationsnummer",
    },
    addressInput: {
    label: "Adresse",
    },
    postalNumberInput: {
    label: "Postleitzahl",
    },
    cityInput: {
    label: "Stadt",
    },
    emailInput: {
    label: "E-Mail",
    },
    },
    onlineLinksCard: {
    websiteInput: {
    label: "Website",
    },
    fbPageInput: {
    label: "Facebook-Seite",
    },
    igPageInput: {
    label: "Instagram-Seite",
    },
    },
    saveBtn: "Speichern",
    },
    locationsTab: {
    allLocationsSection: {
    title: "Standorte",
    description: "Verwalten Sie mehrere Standorte für Ihr Unternehmen.",
    createPlaceBtn: "Einen neuen Standort hinzufügen",
    },
    selectedPlaceSection: {
    dropdown: {
    toggleBtn: "Optionen",
    editBtn: "Standortdetails bearbeiten",
    deleteBtn: "Standort löschen",
    },
    detailsCard: {
    title: "Standortdetails",
    editBtn: "Bearbeiten",
    emailLabel: "E-Mail-Adresse des Standorts",
    phoneLabel: "Kontaktnummer des Standorts",
    },
    categoryCard: {
    title: "Unternehmenskategorie",
    editBtn: "Bearbeiten",
    mainCategoryLabel: "Hauptkategorie",
    additionalCategoryLabel: "Zusätzliche Kategorie",
    addBtn: "+ hinzufügen",
    },
    openingHoursCard: {
    title: "Öffnungszeiten",
    editBtn: "Bearbeiten",
    closedLabel: "Geschlossen",
    },
    photosCard: {
    title: "Meine Fotos",
    editBtn: "Bearbeiten",
    label: "Zeigen Sie Ihre Arbeit",
    description:
    "Fügen Sie Bilder Ihrer Arbeit (vorher/nachher), des Teams, des Arbeitsplatzes oder der Ausrüstung hinzu",
    },
    mobileButtons: {
    editBtn: "Standortdetails bearbeiten",
    deleteBtn: "Standort löschen",
    closeBtn: "Schließen",
    },
    },
    },
    billingTab: {
    planCard: {
    title: "Tarif",
    planName: "Team",
    priceDescription: "/ Verwendung/Monat",
    planInfo: "monatlich in Rechnung gestellt",
    changePlanBtn: "Tarif ändern",
    },
    paymentMethodCard: {
    title: "Zahlungsmethode",
    addBtn: "+ hinzufügen",
    expireLabel: "Läuft ab am",
    },
    billingHistoryCard: {
    title: "Abrechnungshistorie",
    table: {
    columns: {
    date: "Datum",
    description: "Beschreibung",
    amount: "Betrag",
    },
    viewBtn: "Rechnung anzeigen",
    },
    },
    },
    profile: {
    title: "Profil",
    saveBtn: "Speichern",
    guide: {
    personalDetails: {
    title: "Persönliche Daten",
    description:
    "Legen Sie Ihren Namen und Ihre Kontaktinformationen fest. Die E-Mail-Adresse hier wird für Ihren Login-Zugang verwendet.",
    },
    changePassword: {
    title: "Passwort ändern",
    description:
    "Um ein Update durchzuführen, geben Sie Ihr vorhandenes Passwort gefolgt von einem neuen ein. Wenn Sie Ihr vorhandenes Passwort nicht kennen, melden Sie sich ab und verwenden Sie den Link „Passwort zurücksetzen“ auf der Anmeldeseite.",
    },
    notificationSettings: {
    title: "Meine Benachrichtigungseinstellungen",
    description:
    "Erhalten Sie Benachrichtigungen über wichtige Aktivitäten in Ihrem Konto",
    },
    },
    personalDetailsCard: {
    uploadAvatarBtn: "Profilbild hochladen",
    firstNameInput: {
    label: "Vorname",
    },
    lastNameInput: {
    label: "Nachname",
    },
    phoneInput: {
    label: "Telefonnummer",
    },
    emailInput: {
    label: "E-Mail",
    },
    currentPasswordInput: {
    label: "Aktuelles Passwort",
    },
    newPasswordInput: {
    label: "Neues Passwort",
    },
    },
    notificationsCard: {
    notifyMeInput: {
    label: "Benachrichtigen Sie mich über",
    options: {
    allStaff: "Termine, die mit allen Mitarbeitern gebucht wurden",
    meOnly: "Termine, die nur mit mir gebucht wurden",
    },
    },
    bookedInput: {
    label: "Gebucht am",
    allLocationsLabel: "Alle Standorte",
    changeBtn: "Ändern",
    newAppointmentsLabel: "Neue Termine",
    cancellationLabel: "Stornierungen",
    },
    },
    croperModal: {
    title: "Bild zuschneiden",
    saveBtn: "Anwenden",
    },
    locationsModal: {
    title: "Standorte auswählen",
    allPlacesLabel: "Alle auswählen",
    saveBtn: "Auswählen",
    },
    },
    locationInput: {
    title: "Geschäftsstandort",
    label: "Wo befindet sich Ihr Unternehmen?",
    },
    cancelAppointmentModal: {
    title: "Termin absagen",
    messageInput: {
    label: "Nachricht",
    description: "(optional)",
    },
    submitBtn: "Absagen",
    },
    placeFormModal: {
    previousStepBtn: "Zurück",
    nextStepBtn: "Nächster Schritt",
    saveBtn: "Speichern",
    stepLabel: "Schritt",
    stepOfLabel: "von",
    createPlaceMode: {
    step1: {
    title: "Über Ihr Unternehmen",
    },
    step2: {
    title: "Hauptgeschäftskategorie auswählen",
    },
    step3: {
    title: "Sekundäre Geschäftskategorien auswählen",
    description:
    "Wenn Ihr Unternehmen durch mehrere Typen beschrieben werden kann, wählen Sie alle zutreffenden aus",
    },
    step4: {
    title: "Ihren Standort hinzufügen",
    },
    },
    editInfoMode: {
    title: "Über Ihr Unternehmen",
    },
    editCategoriesMode: {
    step1: {
    title: "Hauptgeschäftskategorie auswählen",
    },
    step2: {
    title: "Sekundäre Geschäftskategorien auswählen",
    description:
    "Wenn Ihr Unternehmen durch mehrere Typen beschrieben werden kann, wählen Sie alle zutreffenden aus",
    },
    },
    basicInfoCard: {
    title: "Grundlagen", logoInput: {
      label: "Logo hochladen (optional)",
      description: "Dieses Foto wird auf Ihrer Profilseite angezeigt.",
    },
    nameInput: {
      label: "Firmenname",
      description: "(auf Profilseite angezeigt)",
    },
    publicProfileInput: {
      label: "Link zum öffentlichen Profil",
    },
    phoneInput: {
      label: "Standortkontaktnummer",
    },
    emailInput: {
      label: "E-Mail-Adresse des Standorts",
    },
    descriptionInput: {
      label: "Über uns",
    },
    },
    validation: {
    error: "Feld ist erforderlich",
    locationError:
      "Standort ist erforderlich. Sie müssen einen der bereitgestellten Standorte auswählen.",
    mainCategoryError: "Hauptkategorie ist erforderlich",
    },
    },
    placeImagesModal: {
    title: "Meine Fotos",
    card: {
    title: "Fotos hochladen",
    },
    saveBtn: "Speichern",
    },
    availabilityInput: {
    title: "Verfügbarkeit",
    closedLabel: "Geschlossen",
    addBtn: "+ Hinzufügen",
    saveBtn: "Speichern",
    },
    availabilityFormModal: {
    title: "Verfügbarkeit",
    fromInput: {
    label: "Von",
    },
    toInput: {
    label: "Bis",
    },
    closedLabel: "Geschlossen",
    saveBtn: "Speichern",
    closeBtn: "Schließen",
    },
    openingHoursForm: {
    title: "Öffnungszeiten",
    closedLabel: "Geschlossen",
    saveBtn: "Speichern",
    },
    mobileClientSelect: {
    title: "Kunde auswählen",
    searchPlaceholder: "Nach Kundennamen suchen",
    },
    multipleClientSelect: {
    title: "Kunden auswählen",
    searchPlaceholder: "Nach Kundennamen suchen",
    selectAllLabel: "Alle Kunden auswählen",
    saveBtn: "Ausgewählte Kunden speichern",
    },
    mobileServiceSelect: {
    title: "Dienstleistung auswählen",
    searchPlaceholder: "Nach Dienstleistungsname suchen",
    },
    weekdays: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    },
    changeLanguageModal: {
    title: "Sprache ändern",
    languageInput: {
    label: "Sprache auswählen",
    },
    saveBtn: "Speichern",
    },
    deleteClientModal: {
    title: "Kunden löschen",
    message: "Sind Sie sicher, dass Sie den Kunden löschen möchten",
    confirmBtn: "Ja",
    cancelBtn: "Nein",
    },
    deleteStaffModal: {
    title: "Mitarbeiter löschen",
    message: "Sind Sie sicher, dass Sie den Mitarbeiter löschen möchten",
    confirmBtn: "Ja",
    cancelBtn: "Nein",
    },
    defaults: {
    checkboxGroup: {
    chooseAllLabel: "Alle auswählen",
    },
    select: {
    placeholder: "Option auswählen",
    searchPlaceholder: "Suchen",
    noOptionsText: "Keine verfügbaren Optionen",
    },
    dropdown: {
    toggleText: "Dropdown umschalten",
    description: "Platzieren Sie Ihre Elemente hier",
    },
    },
};
     
export const law = {
    login: {
        title: "Willkommen zurück",
        emailLabel: "E-Mail",
        emailValidationError: "E-Mail ist erforderlich und muss eine gültige E-Mail-Adresse sein.",
        passwordLabel: "Passwort",
        passwordValidationError: "Passwort ist erforderlich.",
        keepMeLabel: "Angemeldet bleiben",
        forgotPassword: "Passwort vergessen?",
        loginBtn: "Anmelden",
        dontHaveAccount: "Sie haben noch kein Konto?",
        signUp: "Registrieren",
    },
    forgotPassword: {
        title: "Passwort vergessen?",
        emailInput: {
            label: "E-Mail",
            description: "Geben Sie Ihre E-Mail-Adresse ein, um den Link zum Zurücksetzen des Passworts zu erhalten",
        },
        submitBtn: "Senden",
    },
    resetPassword: {
    title: "Passwort zurücksetzen",
    passwordInput: {
    label: "Neues Passwort",
    },
    passwordConfirmationInput: {
    label: "Neues Passwort bestätigen",
    },
    submitBtn: "Passwort zurücksetzen",
    },
    menu: {
    calendar: "Kalender",
    clients: "Kunden",
    services: "Dienstleistungen",
    staff: "Mitarbeiter",
    statistics: "Statistiken",
    settings: "Einstellungen",
    profile: "Profil",
    contactSupport: "Support kontaktieren",
    logout: "Abmelden",
    subjects: "Themen"
    },
    calendar: {
    title: "Kalender",
    alert: {
    title: "Willkommen in Ihrem Anwaltskalender",
    text: "Termine, die Kunden oder Sie buchen, werden hier angezeigt. Klicken Sie auf einen Termin, um die Details anzuzeigen und zu verwalten.",
    },
    newEventActions: {
    appointment: "Termin",
    appointmentMobile: "Neuer Termin",
    groupAppointment: "Gruppentermin",
    blockTime: "Blockzeit",
    close: "Schließen",
    },
    appointment: {
    cancelAppointment: "Termin absagen",
    editAppointment: "Termin bearbeiten",
    notesLabel: "Notizen",
    noNotesText: "Keine Notizen für diesen Termin",
    employee: "Mitarbeiter",
    },
    blockTime: {
    removeBlockTime: "Blockzeit entfernen",
    editBlockTime: "Blockzeit bearbeiten",
    },
    calendarEventCard: {
    clientsLabel: "Kunden"
    },
    },
    calendarFilters: {
    placeFilter: {
    createPlace: "Neuen Ort erstellen",
    },
    staffFilter: {
    allStaff: "Alle Mitarbeiter",
    createStaff: "Neuen Mitarbeiter erstellen",
    },
    viewFilter: {
    week: "Woche",
    day: "Tag",
    },
    mobileViewFilter: {
    title: "Kalenderansicht",
    day: "Tag",
    threeDays: "3 Tage",
    },
    mobilePlaceAndStaffFilter: {
    title: "Filter",
    placesLabel: "Orte",
    staffLabel: "Mitarbeiter",
    allStaffLabel: "Alle Mitarbeiter",
    },
    },
    appointmentForm: {
    createTitle: "Neuer Termin",
    createGroupTitle: "Neuer Gruppentermin",
    editTitle: "Termin bearbeiten",
    editGroupTitle: "Gruppentermin bearbeiten",
    createSubmitBtn: "Termin erstellen",
    editSubmitBtn: "Speichern",
    noDateLabel: "Datum hinzufügen",
    clientInput: {
    label: "Kunde",
    placeholder: "Kunden hinzufügen",
    searchPlaceholder: "Kunde suchen",
    noResultsText: "Kein Kunde verfügbar",
    editBtn: "Details bearbeiten",
    removeBtn: "Vom Termin entfernen",
    createBtn: "Neuen Kunden erstellen",
    details: {
    title: "Kundendetails",
    emailLabel: "E-Mail",
    phoneLabel: "Telefonnummer",
    notesLabel: "Kundennotizen",
    },
    },
    clientsInputBtn: "Kunden auswählen",
    subjectInput: {
    label: "Thema",
    placeholder: "Thema hinzufügen",
    searchPlaceholder: "Themen suchen",
    noResultsText: "Kein Thema verfügbar",
    editBtn: "Details bearbeiten",
    removeBtn: "Vom Termin entfernen",
    createBtn: "Neues Thema erstellen",
    details: {
    title: "Themendetails",
    subjectNumber: "Themennummer",
    courtNumber: "Gerichtsnummer",
    courtName: "Gerichtsname",
    },
    },
    serviceInput: {
    label: "Dienstleistung",
    placeholder: "Dienstleistung hinzufügen",
    searchPlaceholder: "Dienstleistung suchen",
    noResultsText: "Keine Dienstleistung verfügbar",
    createBtn: "Neue Dienstleistung erstellen",
    },
    staffInput: {
    label: "Mitarbeiter",
    placeholder: "Mitarbeiter auswählen",
    searchPlaceholder: "Mitarbeiter suchen",
    noResultsText: "Kein Mitarbeiter verfügbar",
    createBtn: "Neuen Mitarbeiter erstellen",
    },
    startTimeInput: {
    label: "Startzeit",
    placeholder: "Uhrzeit auswählen",
    noResultsText: "Kein verfügbarer Zeitpunkt",
    },
    durationLabel: "Dauer",
    notesInput: {
    label: "Terminnotizen",
    placeholder: "Terminnotiz hinzufügen (nur für Mitarbeiter sichtbar)",
    },
    },
    blockTimeForm: {
    title: "Blockzeit",
    createSubmitBtn: "Blockzeit erstellen",
    editSubmitBtn: "Blockzeit bearbeiten",
    dateLabel: "Datum hinzufügen",
    staffInput: {
    label: "Mitarbeiter",
    placeholder: "Mitarbeiter auswählen",
    searchPlaceholder: "Mitarbeiter suchen",
    noResultsText: "Kein Mitarbeiter verfügbar",
    createBtn: "Neuen Mitarbeiter erstellen",
    },
    startTimeInput: {
    label: "Startzeit",
    placeholder: "Startzeit auswählen",
    noResultsText: "Kein verfügbarer Zeitpunkt",
    },
    endTimeInput: {
    label: "Endzeit",
    placeholder: "Endzeit auswählen",
    noResultsText: "Kein verfügbarer Zeitpunkt",
    },
    notesInput: {
    label: "Beschreibung",
    placeholder: "z. B. Mittagstreffen",
    },
    },
    dateInput: {
    appointmentTitle: "Termindatum",
    blockTimeTitle: "Datum auswählen",
    mobileTitle: "Datum auswählen",
    saveBtn: "Speichern",
    },
    calendarForm: {
        createTitle: "Neuer Mitarbeiter",
        editTitle: "Mitarbeiter bearbeiten",
        saveBtn: "Speichern",
        cancelBtn: "Abbrechen",
        mobileButtons: {
            sendResetPassword: "E-Mail zum Zurücksetzen des Passworts senden",
            delete: "Mitarbeiter löschen",
            close: "Schließen",
        },
        basicSettings: {
            title: "Grundeinstellungen",
            profilePictureInput: {
                label: "Profilbild (optional, aber sehr empfohlen)",
                description: "Dieses Foto wird auf Ihrer Profilseite angezeigt",
                mobileLabel: "Profilbild",
                mobileDescription: "(optional, aber sehr empfohlen)",
                uploadBtn: "Profilbild hochladen",
                deleteBtn: "Löschen",
            },
            nameInput: {
                label: "Name",
                placeholder: "z. B. Linda",
            },
            titleInput: {
                label: "Titel",
                placeholder: "z. B. Friseur",
            },
            personalDetails: {
                label: "Persönliche Angaben",
                description:
                "Diese Informationen werden nur für interne Kommunikation und Benachrichtigungen verwendet.",
            },
            firstNameInput: {
                label: "Vorname",
            },
            lastNameInput: {
                label: "Nachname",
            },
            emailInput: {
                label: "E-Mail",
            },
            phoneInput: {
                label: "Telefonnummer",
            },
            roleInput: {
                label: "Rolle",
                manageAccountLabel: "Konto verwalten",
                managePlaceLabel: "Ort verwalten",
                manageBookingsLabel: "Buchungen für Ort verwalten",
                manageCalendarLabel: "Eigenen Kalender verwalten",
            },
        },
    availability: {
    title: "Verfügbarkeit",
    description:
    "Wählen Sie aus, zu welchen Zeiten Kunden Dienstleistungen auf diesem Kalender buchen können",
    editBtn: "Bearbeiten",
    removeBtn: "Entfernen",
    addDayOffBtn: "+ Spezifischen freien Tag hinzufügen",
    closedLabel: "Geschlossen",
    },
    placeAndServices: {
    title: "Ort und Dienstleistungen",
    placeInput: {
    label: "Zu welchem Ort gehört dieser Kalender?",
    },
    servicesInput: {
    label: "Welche Dienstleistungen können mit diesem Kalender gebucht werden?",
    },
    },
    advancedSettings: {
    title: "Erweiterte Einstellungen",
    requiredInfoInput: {
    label: "Welche Informationen sind vom Kunden bei der Buchung erforderlich?",
    firstNameLabel: "Vorname",
    lastNameLabel: "Nachname",
    emailLabel: "E-Mail",
    phoneLabel: "Telefonnummer",
    addressLabel: "Adresse",
    zipCodeLabel: "Postleitzahl",
    cityLabel: "Stadt",
    otherLabel: "Andere",
    },
    otherInput: {
    label: 'Alias für "Andere"',
    placeholder: "Andere",
    },
    cancellationMarginInput: {
    label: "Stornierungsfrist",
    description:
    "Bis zu welchem Zeitpunkt vor der Buchung ist eine Stornierung möglich?",
    locationDescription:
    "Wählen Sie den Ort aus, für den Sie die Buchungsstornierungen bearbeiten möchten.",
    daysLabel: "Tage",
    hoursLabel: "Stunden",
    minutesLabel: "Minuten",
    },
    bookingMarginInput: {
    label: "Buchungsfrist",
    description: "Wie kurz vor einem verfügbaren Zeitpunkt kann gebucht werden?",
    daysLabel: "Tage",
    hoursLabel: "Stunden",
    minutesLabel: "Minuten",
    },
    tocInput: {
    label: "Allgemeine Geschäftsbedingungen (optional)",
    description:
    "Wenn Sie Allgemeine Geschäftsbedingungen hinzufügen, müssen die Kunden diese lesen und durch Ankreuzen eines Kontrollkästchens akzeptieren, um eine Buchung zu erstellen.",
    },
    },
    notifications: {
    title: "Benachrichtigungen",
    toServiceProviderInput: { 
      label: "An Dienstleister",
      description: "Benachrichtigungen werden an die E-Mail-Adresse gesendet, die in 'Persönliche Details' angegeben ist",
      bookingConfirmationLabel: "Buchungsbestätigung",
      cancellationConfirmationLabel: "Stornierungsbestätigung"
    },
    toCustomerInput: {
      label: "An Kunden",
      description: "Benachrichtigungen werden an die E-Mail-Adresse gesendet, die vom Kunden angegeben wird",
      bookingConfirmationLabel: "Buchungsbestätigung",
      cancellationConfirmationLabel: "Stornierungsbestätigung",
      reminderLabel: "Erinnerung an die Buchung",
      followUpLabel: "Nachverfolgung"
    },
    followUpInput: {
      label: "Nachverfolgung",
      description: "Erhöhen Sie die Chance auf wiederkehrende Buchungen durch das Senden von Nachverfolgungs-E-Mails",
      daysLabel: "Tage nach gebuchter Zeit"
    },
},
    },
    
    calendarFormModal: {
      createTitle: "Neuen Mitarbeiter hinzufügen",
      editTitle: "Mitarbeiter bearbeiten",
      createBtn: "Mitarbeiter hinzufügen",
      editBtn: "Speichern",
      basicInfoCard: {
        title: "Grundlegende Informationen",
        nameInput: {
          label: "Name"
        },
        titleInput: {
          label: "Mitarbeiter-Titel",
          description: "Dieser Titel ist für Kunden sichtbar"
        },
        notesInput: {
          label: "Notizen",
          labelDescription: "(optional)",
          placeholder: "Private Notizen, die nur in den Mitarbeiter-Einstellungen sichtbar sind"
        },
        contactTitle: "Kontakt",
        contactDescription: "Mitarbeiterkontakte sind vertraulich und werden nicht mit Ihren Kunden geteilt.",
        firstNameInput: {
          label: "Vorname"
        },
        lastNameInput: {
          label: "Nachname"
        },
        emailInput: {
          label: "E-Mail"
        },
        phoneInput: {
          label: "Telefonnummer"
        },
        employmentTitle: "Beschäftigung",
        employmentStartInput: {
          label: "Startdatum"
        },
        employmentEndInput: {
          label: "Enddatum"
        }
      },
      placesCard: {
        title: "Arbeitet bei",
        description: "Wählen Sie die Standorte aus, an denen dieses Mitarbeitermitglied arbeitet.",
        placesInput: {
          allLabel: "Alle Standorte"
        }
      },
      bookingCard: {
        title: "Buchung",
        bookingsEnabledInput: {
          label: "Kalenderbuchungen erlauben",
          description: "Erlauben Sie diesem Mitarbeiter, Buchungen im Kalender zu empfangen."
        }
      },
      colorCard: {
        title: "Kalenderfarbe",
        description: "Wählen Sie eine Farbe aus, um die Termine dieses Mitarbeitermitglieds im Kalender anzuzeigen.",
        colorInput: {
          label: "Farbe auswählen"
        }
      },
      servicesCard: {
        title: "Dienstleistungen",
        description: "Fügen Sie die Dienstleistungen hinzu, die dieses Mitarbeitermitglied anbieten kann.",
        servicesInput: {
          allSelectedLabel: "Alle Dienstleistungen",
          countSelectedLabel: "Ausgewählte Dienstleistungen",
          noSelectedLabel: "Keine ausgewählten Dienstleistungen",
          editBtn: "Bearbeiten"
        }
      },
      clientsCard: {
        title: "Kunden",
        description: "Fügen Sie Kunden hinzu, die der Mitarbeiter sehen wird, auch wenn er nur über Grundberechtigungen verfügt.",
        clientsInput: {
          allSelectedLabel: "Alle Kunden",
          countSelectedLabel: "Ausgewählte Kunden",
          noSelectedLabel: "Keine ausgewählten Kunden",
          editBtn: "Bearbeiten"
        }
      },
      permissionsCard: {
        title: "Mitarbeiterberechtigungen"
      },
      selectServicesModal: {
        title: "Dienstleistungen auswählen",
        searchInput: {
          placeholder: "Dienstleistungen suchen"
        },
        allServicesLabel: "Alle Dienstleistungen",
        closeBtn: "Abbrechen",
        saveBtn: "Speichern"
      },
      selectClientsModal: {
        title: "Kunden auswählen",
        searchInput: {
          placeholder: "Kunden suchen"
        },
        allClientsLabel: "Alle Kunden",
        closeBtn: "Abbrechen",
        saveBtn: "Speichern"
      },
      validation: {
        error: "Feld ist ungültig"
      }
    },
    clients: {
      title: "Kunden",
      searchInput: {
        placeholder: "Nach Name, E-Mail oder Telefonnummer suchen"
      },
      createClientBtn: "Neuer Kunde",
      clientDropdown: {
        showBtn: "Profil anzeigen",
        editBtn: "Details bearbeiten",
        deleteBtn: "Kunden löschen"
      },
      table: {
        columns: {
          name: "Name",
          phone: "Telefonnummer",
          email: "E-Mail"
        },
        pagination: {
          show: "Anzeigen",
          of: "von"
        }
      },
      mobileSearchInput: {
        placeholder: "Nach Kundenname suchen"
      }
    },
    clientProfile: {
      title: "Kundenprofil",
      createAppointmentDropdown: {
        toggleBtn: "Planung",
        appointmentBtn: "Neuer Termin",
        groupAppointmentBtn: "Neue Gruppenbuchung"
      },
      dropdown: {
        editBtn: "Details bearbeiten",
        deleteBtn: "Kunden löschen"
      },
      infoCard: {
        email: {
          label: "E-Mail"
        },
        phone: {
          label: "Telefonnummer"
        },
        notes: {
          label: "Kundennotizen"
        },
        notifications: {
          label: "Benachrichtigungen",
          enabledLabel: "Aktiviert",
          disabledLabel: "Deaktiviert",
          viaLabel: "Benachrichtigungstyp",
          emailLabel: "Per E-Mail",
          unknownLabel: "Unbekannt"
        }
      },
      salesCard: {
        title: "Gesamtverkauf",
        bookings: {
          all: "Alle Buchungen",
          completed: "Abgeschlossen",
          canceled: "Storniert"
        }
      },
      mobileButtons: {
        editBtn: "Details bearbeiten",
        deleteBtn: "Kunden löschen",
        closeBtn: "Schließen"
      }
    },
    appointmentList: {
      title: "Vorherige Termine",
      noAppointmentsLabel: "Keine vorherigen Termine",
      status: {
        active: "Aktiv",
        finished: "Abgeschlossen",
        canceled: "Storniert"
      }
    },
    clientForm: {
      createTitle: "Neuen Kunden erstellen",
      editTitle: "Kunden bearbeiten",
      saveBtn: "Speichern",
      cancelBtn: "Abbrechen",
      contactDetails: {
        title: "Kontaktdaten",
        firstNameInput: {
          label: "Vorname"
        },
        lastNameInput: {
          label: "Nachname"
        },
        emailInput: {
          label: "E-Mail"
        },
        phoneInput: {
          label: "Telefonnummer"
        },
        notificationsInput: {
          label: "Benachrichtigungen",
          description: "Benachrichtigungen werden an die oben angegebene E-Mail-Adresse gesendet",
          notificationsLabel: "Benachrichtigungen"
        }
      },
      aditionalInfo: {
        title: "Zusätzliche Informationen (optional)",
        addressInput: {
          label: "Adresse"
        },
        cityInput: {
          label: "Stadt"
        },
        zipCodeInput: {
          label: "PLZ / Postleitzahl"
        },
        notesInput: {
          label: "Kundennotizen",
          placeholder: "z.B. Allergisch gegen Nitrilhandschuhe"
        }
      }
    },
    clientFormModal: {
      createTitle: "Neuer Kunde",
      editTitle: "Kunden bearbeiten",
      saveBtn: "Speichern",
      closeBtn: "Abbrechen",
      deleteBtn: "Löschen",
      tabs: {
        personalInfo: "Persönliche Informationen",
        address: "Adresse"
      },
      firstNameInput: {
        label: "Vorname"
      },
      lastNameInput: {
        label: "Nachname"
      },
      mobilePhoneInput: {
        label: "Mobiltelefon"
      },
      phoneInput: {
        label: "Telefon"
      },
      emailInput: {
        label: "E-Mail"
      },
      sendNotificationsByInput: {
        label: "Benachrichtigungen senden über",
        options: {
          email: "E-Mail",
          dontSend: "Keine Benachrichtigungen senden"
        }
      },
      notificationsEnabledInput: {
        label: "Marketing-Benachrichtigungen akzeptieren"
      },
      genderInput: {
        label: "Geschlecht",
        options: {
          unknown: "Unbekannt",
          male: "Männlich",
          female: "Weiblich"
        }
      },
      birthdayInput: {
        label: "Geburtstag",
        monthPlaceholder: "Monat",
        dayPlaceholder: "Tag",
        yearPlaceholder: "Jahr"
      },
      notesInput: {
        label: "Kundennotizen"
      },
      displayNotesInput: {
        label: "In allen Buchungen anzeigen"
      },
      addressInput: {
        label: "Adresse"
      },
      cityInput: {
        label: "Stadt"
      },
      stateInput: {
        label: "Bundesland"
      },
      zipCodeInput: {
        label: "PLZ / Postleitzahl"
      },
      mobileSection: {
        notesSection: {
          title: "Kundennotizen",
          notesInput: {
            label: "Kundennotizen"
          },
          displayNotesInput: {
            label: "In allen Buchungen anzeigen"
          }
        },
        additionalInfoSection: {
          title: "Zusätzliche Informationen",
          personalInfoBtn: "Persönliche Informationen",
          addressDetailsBtn: "Adressdetails"
        }
      },
      validation: {
        error: "Dieses Feld ist erforderlich"
      }
    },
    addressDetailsMobileModal: {
      title: "Adressdetails",
      addressInput: {
        label: "Adresse"
      },
        cityInput: {
          label: "Stadt"
        },
        stateInput: {
          label: "Bundesland"
        },
        zipCodeInput: {
          label: "PLZ / Postleitzahl"
        },
        saveBtn: "Speichern"
    },
    personalInfoMobileModal: {
      title: "Persönliche Informationen",
        genderInput: {
          label: "Geschlecht"
        },
        birthdayInput: {
          label: "Geburtstag",
          monthPlaceholder: "Monat",
          dayPlaceholder: "Tag",
          yearPlaceholder: "Jahr"
        },
        saveBtn: "Speichern"
    },
    services: {
      title: "Dienstleistungen",
      createServiceBtn: "Neue hinzufügen",
      table: {
        createServiceBtn: "Dienstleistung hinzufügen",
        dropdown: {
          createServiceBtn: "Neue Dienstleistung hinzufügen",
          editCategoryNameBtn: "Kategorie-Namen bearbeiten",
          deleteCategoryBtn: "Kategorie löschen"
        },
        editBtn: "Bearbeiten",
        minutesLabel: "Minuten",
        noDescriptionLabel: "Keine Dienstleistungsbeschreibung verfügbar.",
        groupServiceLabel: "Klasse"
      },
      mobileButtons: {
        createServiceBtn: "Neue Dienstleistung hinzufügen",
        editCategoryNameBtn: "Kategorie-Namen bearbeiten",
        deleteCategoryBtn: "Kategorie löschen",
        closeBtn: "Schließen"
      },
      mobileServiceButtons: {
        editServiceBtn: "Dienstleistung bearbeiten",
        deleteServiceBtn: "Dienstleistung löschen",
        closeBtn: "Schließen"
      },
      tableFilter: {
        services: "Dienstleistungen",
        categories: "Kategorien"
      }
    },
    mobileServicesFilter: {
      title: "Filter",
      services: "Dienstleistungen",
      categories: "Kategorien"
    },
    serviceForm: {
      createTitle: "Dienstleistung hinzufügen",
      editTitle: "Dienstleistung bearbeiten",
      cancelBtn: "Abbrechen",
      saveBtn: "Speichern",
      deleteBtn: "Löschen",
      nameInput: {
        label: "Dienstleistungsname",
        placeholder: "Z.B. Damenhaarschnitt oder Ganzkörpermassage"
      },
      categoryInput: {
        label: "Kategorie",
        description: "Die Dienstleistung wird unter dieser Kategorie in Ihrem öffentlichen Profil angezeigt",
        placeholder: "Fügen Sie eine Kategorie hinzu, indem Sie tippen oder aus früheren auswählen"
      },
      descriptionInput: {
        label: "Beschreibung (optional)",
        placeholder: "Z.B. Ein Damenhaarschnitt inklusive Waschen und Föhnen"
      },
      priceInput: {
        label: "Preis",
        showPublicLabel: "Öffentlich anzeigen"
      },
      durationInput: {
        label: "Dauer (Minuten)",
        showPublicLabel: "Öffentlich anzeigen"
      },
      serviceTypeInput: {
        label: "Art der Dienstleistung",
        description: "Erlauben Sie Suchmaschinen, Kunden genauere Ergebnisse zu liefern"
      },
      sameTimePeopleInput: {
        label: "Anzahl der Personen, die gleichzeitig buchen können",
        description: "Z.B. 20 Personen können eine Yogastunde buchen",
        inputLabel: "Personen",
        toggleBtn: "+ Mehr Personen können gleichzeitig buchen"
      },
      breakInput: {
        label: "Pause nach Buchung dieser Dienstleistung",
        description: "Niemand kann diese Minuten nach Abschluss dieser Dienstleistung buchen",
        inputLabel: "Minuten",
        toggleBtn: "+ Nach der Dienstleistung eine Pause hinzufügen"
      }
    },
    serviceFormModal: {
      titles: {
        chooseType: "Fügen Sie Ihrem Dienstleistungsmenü ein Angebot hinzu",
        singleService: "Eine neue Einzeldienstleistung hinzufügen"
      },
      serviceTypes: {
        single: {
          name: "Dienstleistung",
          description: "Dienstleistungen, die von einem einzelnen Kunden in einem einzigen Besuch gebucht werden"
        },
        session: {
          name: "Klasse",
          description: "Dienstleistungen, die von mehreren Kunden in geplanten Sitzungen gebucht werden"
        }
      },
      comingSoonLabel: "Demnächst verfügbar",
      saveBtn: "Speichern",
      basicInfoCard: {
        title: "Grundlegende Informationen",
        description: "Fügen Sie einen Dienstleistungsnamen hinzu und wählen Sie den Behandlungstyp aus.",
        nameInput: {
          label: "Dienstleistungsnamen"
        },
        typeInput: {
          label: "Behandlungstyp",
          placeholder: "Behandlungstyp auswählen",
          description: "Wählen Sie den relevantesten Behandlungstyp aus. Dies wird für Ihre Kunden nicht sichtbar sein."
        },
        categoryInput: {
          label: "Kategorie",
          description: "Die Dienstleistung wird unter dieser Kategorie in Ihrem öffentlichen Profil angezeigt",
          placeholder: "Fügen Sie eine Kategorie hinzu, indem Sie tippen oder aus früheren auswählen"
        },
        descriptionInput: {
          label: "Dienstleistungsbeschreibung",
          placeholder: "Kurze Beschreibung hinzufügen"
        }
      },
      onlineBookingsCard: {
        title: "Online-Buchungen",
        description: "Aktivieren Sie Online-Buchungen, wählen Sie aus, für wen die Dienstleistung verfügbar ist, und fügen Sie eine kurze Beschreibung hinzu",
        onlineBookingsInput: {
          label: "Online-Buchungen aktivieren"
        }
      },
      staffCard: {
        title: "Mitarbeiter",
        staffInput: {
          allLabel: "Alle auswählen"
        }
      },
      pricingCard: {
        title: "Preisgestaltung und Dauer",
        description: "Fügen Sie die Preisoptionen und die Dauer der Dienstleistung hinzu.",
        priceTitle: "Preisoption",
        durationInput: {
          label: "Dauer"
        },
        priceTypeInput: {
          label: "Preistyp"
        },
        priceInput: {
          label: "Preis"
        },
        extraTimeTitle: "Zusätzliche Zeit",
        extraTimeDescription: "Aktivieren Sie zusätzliche Zeit nach der Dienstleistung.",
        enableExtraTimeInput: {
          label: "Zusätzliche Zeit aktivieren"
        },
        extraTimeCard: {
          title: "Blockierte Zeit",
          description: "Blockieren Sie die Zeit zwischen den Terminen"
        },
        breakDurationInput: {
          label: "Dauer"
        },
        priceTypeDropdown: {
          free: "Kostenlos",
          fixed: "Festpreis",
          from: "Ab"
        }
      },
      sameTimePeopleCard: {
        title: "Anzahl der Personen in der Sitzung",
        description: "Legen Sie die maximale Anzahl der Personen fest, die diese Dienstleistung gleichzeitig buchen können."
      },
      validation: {
        error: "Dieses Feld ist ungültig"
      }
    },
    staff: {
      title: "Mitarbeiter",
      createStaffBtn: "Neue Mitarbeiter",
      table: {
        columns: {
          name: "Name",
          phone: "Telefonnummer",
          email: "E-Mail"
        },
        dropdown: {
          editBtn: "Details bearbeiten",
          deleteBtn: "Mitarbeiter löschen"
        },
        pagination: {
          show: "Anzeigen",
          of: "von"
        }
      },
      mobileButtons: {
        editStaffBtn: "Mitarbeiter bearbeiten",
        editWorkingHoursBtn: "Arbeitszeiten bearbeiten",
        deleteStaffBtn: "Mitarbeiter löschen",
        closeBtn: "Schließen"
      }
    },
    mobileStaffFilter: {
      title: "Filter",
      placesLabel: "Standorte"
    },
    statistics: {
      title: "Statistiken",
      totalSalesLabel: "Gesamtumsatz",
      totalAppointmentsLabel: "Gesamte Termine",
      completedAppointmentsLabel: "Abgeschlossene Termine",
      canceledAppointmentsLabel: "Abgesagte Termine",
      staffSalesLabel: "Umsatz nach Mitarbeitern",
      noDataLabel: "Keine verfügbaren Daten",
      topServicesCard: {
        title: "Top-verkaufte Dienstleistungen",
        serviceNameLabel: "Dienstleistungsname",
        totalSalesLabel: "Gesamtumsatz (SEK)"
      },
      charts: {
        currentIncomeLabel: "Aktuelles Einkommen",
        pastYearLabel: "Vergangenes Jahr"
      }
    },
    statisticsFilters: {
      placeFilter: {
        allLocationsLabel: "Alle Standorte"
      },
      periodFilter: {
        dayLabel: "Tag",
        monthLabel: "Monat",
        yearLabel: "Jahr"
      },
      mobilePlaceFilter: {
        title: "Filter",
        allLocationsLabel: "Alle Standorte"
      },
      mobilePeriodFilter: {
        title: "Datum auswählen"
      }
    },
    settings: {
      title: "Einstellungen",
      tabs: {
        widget: "Widget-Einstellungen",
        account: "Kontoeinstellungen",
        locations: "Standorte",
        billing: "Abrechnung"
      },
      mobileTabsSelect: {
        widget: {
          title: "Widget-Einstellungen",
          description: "Passen Sie Ihr Widget an."
        },
        account: {
          title: "Kontoeinstellungen",
          description: "Verwalten Sie Einstellungen wie Unternehmensinformationen und Online-Links."
        },
        locations: {
          title: "Standorte",
          description: "Verwalten Sie mehrere Standorte für Ihr Unternehmen."
        },
        billing: {
          title: "Abrechnung",
          description: "Verwalten Sie Ihren Plan, Ihre Zahlungsmethode und sehen Sie die Abrechnungshistorie ein."
        }
      }
    },
    widgetTab: {
      previewBtn: "Widget-Vorschau",
      saveBtn: "Speichern",
      backBtn: "Zurück zu den Widget-Einstellungen",
      appearance: {
        title: "Erscheinungsbild",
        logoInput: {
          label: "Firmenlogo:",
          description: "Hochladen"
        },
        colorInput: {
          label: "Hintergrundfarbe:"
        },
        statusInput: {
          label: "Status:"
        },
        messageInput: {
          label: "Nachricht:"
        },
        showReviewsInput: {
          label: "Bewertungen anzeigen:"
        }
      },
      installation: {
        title: "Installation",
        description: "Es sieht so aus, als hätten Sie den Chat-Code noch nicht installiert. Wählen Sie eine der Installationsanleitungen unten aus:",
        jsCard: {
          instructions: {
            step1: "Fügen Sie diesen Code kurz vor dem schließenden </body>-Tag ein"
          },
          copyBtn: "Kopieren",
          needHelpLabel: "Brauchen Sie Hilfe?",
          contactUsLabel: "Kontaktiere uns."
        },
        wpCard: {
          instructions: {
            step1: "Melden Sie sich in Ihrem WordPress-Admin-Panel an",
            step2: "Gehen Sie zu Aussehen > Design-Editor",
            step3: "Klicken Sie unter Designdateien (rechts) auf „Theme-Footer“ (footer.php)",
            step4: "Fügen Sie Ihren Bookybook-Code vor dem schließenden </body>-Tag unten ein",
            step5: 'Klicken Sie auf "Datei aktualisieren", um die Änderungen zu speichern'
          },
          copyBtn: "Kopieren",
          needHelpLabel: "Brauchen Sie Hilfe?",
          contactUsLabel: "Kontaktiere uns."
        }
      },
      widget: {
        startBookingLabel: "Buchen Sie einen Termin",
        nextFreeTimeLabel: "Nächste freie Zeit verfügbar",
        bookingBtn: "Vereinbaren Sie einen Termin bei uns",
        reviewsLabel: "Das sagen unsere Kunden über uns",
        poweredByLabel: "Powered by"
      }
    },
    accountTab: {
      guide: {
        businessInfo: {
          title: "Unternehmensinformationen",
          description: "Diese Informationen werden nur für interne Kommunikation verwendet. Sie werden nicht auf Ihrem Profil angezeigt."
        },
        onlineLinks: {
          title: "Online-Links",
          description: "Fügen Sie Ihre Firmenwebsite und Links zu sozialen Medien hinzu, um sie mit Kunden zu teilen"
        }
      },
      companyDetailsCard: {
        title: "Firmendetails",
        nameInput: {
          label: "Firmenname"
        },
        organisationNumberInput: {
          label: "Schwedische Organisationsnummer"
        },
        addressInput: {
          label: "Adresse"
        },
        postalNumberInput: {
          label: "Postleitzahl"
        },
        cityInput: {
          label: "Stadt"
        },
        emailInput: {
          label: "E-Mail"
        }
      },
      onlineLinksCard: {
        websiteInput: {
          label: "Website"
        },
        fbPageInput: {
          label: "Facebook-Seite"
        },
        igPageInput: {
          label: "Instagram-Seite"
        }
      },
      saveBtn: "Speichern"
    },
    locationsTab: {
      allLocationsSection: {
        title: "Standorte",
        description: "Verwalten Sie mehrere Standorte für Ihr Unternehmen.",
        createPlaceBtn: "Neuen Standort hinzufügen"
      },
      selectedPlaceSection: {
        dropdown: {
          toggleBtn: "Optionen",
          editBtn: "Standortdetails bearbeiten",
          deleteBtn: "Standort löschen"
        },
        detailsCard: {
          title: "Standortdetails",
          editBtn: "Bearbeiten",
          emailLabel: "E-Mail-Adresse des Standorts",
          phoneLabel: "Kontaktnummer des Standorts"
        },
        categoryCard: {
          title: "Unternehmenskategorie",
          editBtn: "Bearbeiten",
          mainCategoryLabel: "Hauptkategorie",
          additionalCategoryLabel: "Zusätzliche",
          addBtn: "+ hinzufügen"
        },
        openingHoursCard: {
          title: "Öffnungszeiten",
          editBtn: "Bearbeiten",
          closedLabel: "Geschlossen"
        },
        photosCard: {
          title: "Meine Fotos",
          editBtn: "Bearbeiten",
          label: "Zeigen Sie Ihre Arbeit",
          description: "Fügen Sie Bilder Ihrer Arbeit (vorher/nachher), Ihres Teams, Ihres Arbeitsplatzes oder Ihrer Ausrüstung hinzu"
        },
        mobileButtons: {
          editBtn: "Standortdetails bearbeiten",
          deleteBtn: "Standort löschen",
          closeBtn: "Schließen"
        }
      }
    },
    billingTab: {
      planCard: {
        title: "Plan",
        planName: "Team",
        priceDescription: "/Verwendung/Monat",
        planInfo: "monatlich in Rechnung gestellt",
        changePlanBtn: "Plan ändern"
      },
      paymentMethodCard: {
        title: "Zahlungsmethode",
        addBtn: "+ hinzufügen",
        expireLabel: "Läuft ab am"
      },
      billingHistoryCard: {
        title: "Abrechnungsverlauf",
        table: {
          columns: {
            date: "Datum",
            description: "Beschreibung",
            amount: "Betrag"
          },
          viewBtn: "Rechnung anzeigen"
        }
      }
    },
    profile: {
      title: "Profil",
      saveBtn: "Speichern",
      guide: {
        personalDetails: {
          title: "Persönliche Details",
          description: "Geben Sie Ihren Namen und Ihre Kontaktdaten ein. Die hier angegebene E-Mail-Adresse wird für den Zugang zur Anmeldung verwendet."
        },
        changePassword: {
          title: "Passwort ändern",
          description: "Geben Sie zum Aktualisieren Ihr vorhandenes Passwort gefolgt von einem neuen ein. Wenn Sie Ihr vorhandenes Passwort nicht kennen, melden Sie sich ab und verwenden Sie den Link „Passwort zurücksetzen“ auf der Anmeldeseite."
        },
        notificationSettings: {
          title: "Meine Benachrichtigungseinstellungen",
          description: "Erhalten Sie Benachrichtigungen über wichtige Aktivitäten in Ihrem Konto"
        }
      },
      personalDetailsCard: {
        uploadAvatarBtn: "Profilbild hochladen",
        firstNameInput: {
          label: "Vorname"
        },
        lastNameInput: {
          label: "Nachname"
        },
        phoneInput: {
          label: "Telefonnummer"
        },
        emailInput: {
          label: "E-Mail"
        },
        currentPasswordInput: {
          label: "Aktuelles Passwort"
        },
        newPasswordInput: {
          label: "Neues Passwort"
        }
      },
      notificationsCard: {
        notifyMeInput: {
          label: "Benachrichtigen Sie mich über",
          options: {
            allStaff: "Termine mit allen Mitarbeitern gebucht",
            meOnly: "Termine nur mit mir gebucht"
          }
        },
        bookedInput: {
          label: "Gebucht bei",
          allLocationsLabel: "Alle Standorte",
          changeBtn: "Ändern",
          newAppointmentsLabel: "Neue Termine",
          cancellationLabel: "Stornierungen"
        }
      },
      croperModal: {
        title: "Bild zuschneiden",
        saveBtn: "Anwenden"
      },
      locationsModal: {
        title: "Standorte auswählen",
        allPlacesLabel: "Alle auswählen",
        saveBtn: "Auswählen"
      }
    },
    locationInput: {
      title: "Standort des Unternehmens",
      label: "Wo befindet sich Ihr Unternehmen?"
    },
    cancelAppointmentModal: {
      title: "Buchung stornieren",
      messageInput: {
        label: "Nachricht",
        description: "(optional)"
      },
      submitBtn: "Stornieren"
    },
    placeFormModal: {
      previousStepBtn: "Zurück",
      nextStepBtn: "Nächster Schritt",
      saveBtn: "Speichern",
      stepLabel: "Schritt",
      stepOfLabel: "von",
      createPlaceMode: {
        step1: {
          title: "Über Ihr Unternehmen"
        },
        step2: {
          title: "Wählen Sie Ihre Hauptgeschäftskategorie"
        },
        step3: {
          title: "Wählen Sie sekundäre Geschäftskategorien aus",
          description: "Wenn Ihr Unternehmen durch weitere Typen beschrieben werden kann, wählen Sie alle aus, die zutreffen"
        },
        step4: {
          title: "Fügen Sie Ihren Standort hinzu"
        }
      },
      editInfoMode: {
        title: "Über Ihr Unternehmen"
      },
    editCategoriesMode: {
      step1: {
        title: "Wählen Sie Ihre Hauptgeschäftskategorie",
      },
      step2: {
        title: "Wählen Sie sekundäre Geschäftskategorien",
        description:
          "Wenn Ihr Unternehmen durch weitere Typen beschrieben werden kann, wählen Sie alle aus, die zutreffen",
      },
    },
    basicInfoCard: {
      title: "Grundlagen",
      logoInput: {
        label: "Logo hochladen (optional)",
        description: "Dieses Foto wird auf Ihrer Profilseite angezeigt.",
      },
      nameInput: {
        label: "Firmenname",
        description: "(auf Profilseite angezeigt)",
      },
      publicProfileInput: {
        label: "Link zum öffentlichen Profil",
      },
      phoneInput: {
        label: "Kontakttelefonnummer",
      },
      emailInput: {
        label: "E-Mail-Adresse des Standorts",
      },
      descriptionInput: {
        label: "Über uns",
      },
    },
    validation: {
      error: "Feld ist erforderlich",
      locationError:
        "Standort ist erforderlich. Sie müssen einen der angegebenen Standorte aus der Dropdown-Liste auswählen.",
      mainCategoryError: "Hauptkategorie ist erforderlich",
    },
    },
    placeImagesModal: {
    title: "Meine Fotos",
    card: {
      title: "Fotos hochladen",
    },
    saveBtn: "Speichern",
    },
    availabilityInput: {
    title: "Verfügbarkeit",
    closedLabel: "Geschlossen",
    addBtn: "+ Hinzufügen",
    saveBtn: "Speichern",
    },
    availabilityFormModal: {
    title: "Verfügbarkeit",
    fromInput: {
      label: "Von",
    },
    toInput: {
      label: "Bis",
    },
    closedLabel: "Geschlossen",
    saveBtn: "Speichern",
    closeBtn: "Schließen",
    },
    openingHoursForm: {
    title: "Öffnungszeiten",
    closedLabel: "Geschlossen",
    saveBtn: "Speichern",
    },
    mobileClientSelect: {
    title: "Klient auswählen",
    searchPlaceholder: "Nach Klientenname suchen",
    },
    multipleClientSelect: {
    title: "Klienten auswählen",
    searchPlaceholder: "Nach Klientenname suchen",
    selectAllLabel: "Alle Klienten auswählen",
    saveBtn: "Ausgewählte Klienten speichern",
    },
    mobileServiceSelect: {
    title: "Dienstleistung auswählen",
    searchPlaceholder: "Nach Dienstleistungsname suchen",
    },
    weekdays: {
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag",
    sunday: "Sonntag",
    },
    changeLanguageModal: {
    title: "Sprache ändern",
    languageInput: {
      label: "Sprache wählen",
    },
    saveBtn: "Speichern",
    },
    deleteClientModal: {
    title: "Klient löschen",
    message: "Sind Sie sicher, dass Sie den Klienten löschen möchten",
    confirmBtn: "Ja",
    cancelBtn: "Nein",
    },
    deleteStaffModal: {
    title: "Mitarbeiter löschen",
    message: "Sind Sie sicher, dass Sie den Mitarbeiter löschen möchten",
    confirmBtn: "Ja",
    cancelBtn: "Nein",
    },
    defaults: {
    checkboxGroup: {
      chooseAllLabel: "Alle auswählen",
    },
    select: {
      placeholder: "Option auswählen",
      searchPlaceholder: "Suchen",
      noOptionsText: "Keine verfügbaren Optionen",
    },
    dropdown: {
      toggleText: "Dropdown umschalten",
      description: "Platzieren Sie Ihre Elemente hier",
    },
    },
    subjects: {
        title: "Themen",
        searchInput: {
          placeholder: "Nach Gericht oder Themennummer suchen",
        },
        createSubjectBtn: "Neues Thema",
        subjectDropdown: {
          showBtn: "Thema anzeigen",
          editBtn: "Details bearbeiten",
          deleteBtn: "Thema löschen",
        },
        table: {
          columns: {
            type: "Typ",
            subjectNumber: "Themennummer",
            courtNumber: "Gerichtsnummer",
            courtName: "Gerichtsname",
            clientName: "Klient"
          },
          pagination: {
            show: "Anzeigen",
            of: "von",
          },
        },
        mobileSearchInput: {
            placeholder: "Nach Themennummer suchen",
        },
    },
    subjectFormModal: {
        createTitle: "Neues Thema",
        editTitle: "Thema bearbeiten",
        saveBtn: "Speichern",
        closeBtn: "Abbrechen",
        deleteBtn: "Thema löschen",
        subjectNumber: {
          label: "Themennummer",
        },
        courtNumber: {
          label: "Gerichtsnummer",
        },
        mobilePhoneInput: {
          label: "Mobiltelefon",
        },
        type: {
          label: "Typ",
          options: {
            culpability: "Schuld",
            litigation: "Rechtsstreit"
          }
        },
        courtName: {
          label: "Gericht",
        },
        subjectInput: {
          searchPlaceholder: "Nach Gerichtsnamen suchen",
        },
        clientInput: {
          label: "Klient auswählen",
          placeholder: "Klient auswählen",
          searchPlaceholder: "Klienten nach Namen suchen",
          noResultsText: "Keine Ergebnisse",
        },
        clientType: {
          label: "Kliententyp auswählen",
          options: {
            defendant: "Angeklagter",
            prosecutor: "Ankläger"
          },
        },
        otherClient: {
          label: "Informationen zum anderen Klienten",
          firstNameInput: "Vorname",
          lastNameInput: "Nachname",
          mobilePhoneInput: "Telefon",
        },
        validation: {
          error: "Feld ist erforderlich",
        },
    },
    deleteSubjectModal: {
        title: "Thema löschen",
        message: "Sind Sie sicher, dass Sie das Thema und alle seine Daten löschen möchten",
        confirmBtn: "Ja",
        cancelBtn: "Nein",
    },
    subject: {
    title: "Thema",
    createAppointmentDropdown: {
      toggleBtn: "Neu planen",
      appointmentBtn: "Neuer Termin",
      groupAppointmentBtn: "Neuer Gruppentermin"
    },
    dropdown: {
      editBtn: "Details bearbeiten",
      deleteBtn: "Thema löschen",
    },
    infoCard: {
        email: {
          label: "E-Mail",
        },
        phone: {
          label: "Telefonnummer",
        },
        type: {
          label: "Kliententyp",
          options: {
            culpability: "Schuld",
            litigation: "Rechtsstreit"
          }
        },
        clientType: {
          label: "Kliententyp",
          options: {
            defendant: "Angeklagter",
            prosecutor: "Ankläger"
          },
        },
        courtNumber: {
          label: "Gerichtsnummer",
        },
        courtName: {
          label: "Gerichtsname",
        },
        otherClient: {
          label: "Informationen zum anderen Klienten",
          firstNameInput: "Vorname",
          lastNameInput: "Nachname",
          mobilePhoneInput: "Telefon",
        },
    },
    salesCard: {
        title: "Gesamtumsatz",
        bookings: {
          all: "Alle Buchungen",
          completed: "Abgeschlossen",
          canceled: "Abgebrochen",
        },
    },
    mobileButtons: {
        editBtn: "Details bearbeiten",
        deleteBtn: "Thema löschen",
        closeBtn: "Schließen",
    },
    },
};