import _ from "lodash";

const searchEngineTypeMapper = {
  /**
   * Map array of search engine type objects
   * @param {Array} searchEngineTypes
   * @return {Array}
   */
  mapSearchEngineTypes(searchEngineTypes) {
    return searchEngineTypes.map(searchEngineType => {
      return this.mapSearchEngineType(searchEngineType);
    });
  },

  /**
   * Map search engine type object
   * @param {Object} searchEngineType
   * @return {Object}
   */
  mapSearchEngineType: function(searchEngineType) {
    return _.mapKeys(searchEngineType, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of search engine type objects to options for select component
   * @param {Array} searchEngineTypes
   * @return {Array}
   */
  mapmapSearchEngineTypesAsOptions(searchEngineTypes) {
    let options = [];
    searchEngineTypes.forEach(searchEngineType => {
      let option = {
        text: searchEngineType.name,
        value: searchEngineType.id
      };
      options.push(option);
    });
    return options;
  }
};

export default searchEngineTypeMapper;
