// SERBIAN
export const locale = {
  login: {
    title: "Dobrodošli",
    emailLabel: "E-mail",
    emailValidationError: "Email adresa je obavezna",
    passwordLabel: "Lozinka",
    passwordValidationError: "Lozinka je obavezna",
    keepMeLabel: "Ostavi me prijavljenog",
    forgotPassword: "Zaboravili ste lozinku?",
    loginBtn: "Prijavite se",
    dontHaveAccount: "Nemate nalog?",
    signUp: "Registrujte se",
  },
  forgotPassword: {
    title: "Zaboravili ste lozinku?",
    emailInput: {
      label: "Email",
      description:
        "Unesite Vašu email adresu da bi ste dobili link za promenu lozinke",
    },
    submitBtn: "Pošalji",
  },
  resetPassword: {
    title: "Promenite lozinku",
    passwordInput: {
      label: "Nova lozinka",
    },
    passwordConfirmationInput: {
      label: "Potvrdite novu lozinku",
    },
    submitBtn: "Promenite lozinku",
  },
  menu: {
    calendar: "Kalendar",
    clients: "Klijenti",
    services: "Usluge",
    staff: "Osoblje",
    statistics: "Statistika",
    settings: "Podešavanje",
    profile: "Profil",
    contactSupport: "Kontaktirajte podršku",
    logout: "Odjavite se",
  },
  calendar: {
    title: "Kalendar",
    alert: {
      title: "Dobrodošli na Vaš kalendar",
      text: "Zakazivanje koje klijent ili Vi zakažete prikazaće se ovde. Kliknite na zakazan termin da bi ste videli ili izmenili detalje.",
    },
    newEventActions: {
      appointment: "Novi termin",
      appointmentMobile: "Novi termin",
      groupAppointment: "Grupni termin",
      blockTime: "Vremenski blok",
      close: "Zatvori",
    },
    appointment: {
      cancelAppointment: "Poništi termin",
      editAppointment: "Izmeni termin",
      notesLabel: "Napomena",
      noNotesText: "Nema napomene za ovaj termin",
      employee: "Osoblje",
    },
    blockTime: {
      removeBlockTime: "Ukloni vremenski blok",
      editBlockTime: "Izmeni vremenski blok",
    },
    calendarEventCard: {
      clientsLabel: "klijenta"
    },
  },
  calendarFilters: {
    placeFilter: {
      createPlace: "Dodajte novu lokaciju",
    },
    staffFilter: {
      allStaff: "Sve osoblje",
      createStaff: "Dodaj novo osoblje",
    },
    viewFilter: {
      week: "nedelja",
      day: "dan",
    },
    mobileViewFilter: {
      title: "Kalendarski pregled",
      day: "Dan",
      threeDays: "3 dana",
    },
    mobilePlaceAndStaffFilter: {
      title: "Filteri",
      placesLabel: "Lokacije",
      staffLabel: "Osoblje",
      allStaffLabel: "Sve osoblje",
    },
  },
  appointmentForm: {
    createTitle: "Novi termin",
    createGroupTitle: "Novi grupni termin",
    editTitle: "Izmeni termin",
    editGroupTitle: "Izmeni grupni termin",
    createSubmitBtn: "Zakaži termin",
    editSubmitBtn: "Zapamti",
    noDateLabel: "Dodaj datum",
    clientInput: {
      label: "Klijent",
      placeholder: "Dodaj klijenta",
      searchPlaceholder: "Pretraga klijenata",
      noResultsText: "Nema dostupnih klijenata",
      editBtn: "Izmeni detalje",
      removeBtn: "Ukloni iz termina",
      createBtn: "Dodaj novog klijenta",
      details: {
        title: "Detalji klijenta",
        emailLabel: "Email",
        phoneLabel: "Broj telefona",
        notesLabel: "Napomena klijenta",
      },
    },
    clientsInputBtn: "Izaberite klijente",
    serviceInput: {
      label: "Usluge",
      placeholder: "Dodajte uslugu",
      searchPlaceholder: "Pretraži usluge",
      noResultsText: "Nema dostupnih usluga",
      createBtn: "Dodaj novu uslugu",
    },
    staffInput: {
      label: "Osoblje",
      placeholder: "Izaberi osoblje",
      searchPlaceholder: "Pretraži osoblje",
      noResultsText: "Nema dostupnog osoblja",
      createBtn: "Dodaj novo osoblje",
    },
    startTimeInput: {
      label: "Početak",
      placeholder: "Izaberi vreme",
      noResultsText: "Nema dostupnih termina",
    },
    durationLabel: "Trajanje",
    notesInput: {
      label: "Napomena termina",
      placeholder: "Dodaj napomenu (vidljiva samo za osoblje)",
    },
  },
  blockTimeForm: {
    title: "Vremenski blok",
    createSubmitBtn: "Napravite vremenski blok",
    editSubmitBtn: "Izmeni vremenski blok",
    dateLabel: "Dodaj datum",
    staffInput: {
      label: "Osoblje",
      placeholder: "Izaberi osoblje",
      searchPlaceholder: "Pretraži osoblje",
      noResultsText: "Nema dostupnog osoblja",
      createBtn: "Dodaj novo osoblje",
    },
    startTimeInput: {
      label: "Početak",
      placeholder: "Početak",
      noResultsText: "Nema slobodnog termina",
    },
    endTimeInput: {
      label: "Kraj",
      placeholder: "Kraj",
      noResultsText: "Nema slobodnog termina",
    },
    notesInput: {
      label: "Opis",
      placeholder: "Npr. ručak",
    },
  },
  dateInput: {
    appointmentTitle: "Zakazivanje datuma",
    blockTimeTitle: "Izaberi datum",
    mobileTitle: "Izaberi datum(e)",
    saveBtn: "Zapamti",
  },
  calendarForm: {
    createTitle: "Novo osoblje",
    editTitle: "Izmeni osoblje",
    saveBtn: "Zapamti",
    cancelBtn: "Poništi",
    mobileButtons: {
      sendResetPassword: "Pošalji email za promenu lozinke",
      delete: "Obriši osoblje",
      close: "Zatvori",
    },
    basicSettings: {
      title: "Osnovno podešavanje",
      profilePictureInput: {
        label:
          "Profilna slika (nije obavezna, ali preporučujemo da je postavite)",
        description: "Ova slika će biti prikazana na Vašem profilu",
        mobileLabel: "Profilna slika",
        mobileDescription: "(opciono, ali preporučujemo da je postavite)",
        uploadBtn: "Unesite profilnu sliku",
        deleteBtn: "Obriši",
      },
      nameInput: {
        label: "Ime",
        placeholder: "Npr. Sava",
      },
      titleInput: {
        label: "Naslov",
        placeholder: "Npr. Frizer",
      },
      personalDetails: {
        label: "Vaše informacije",
        description:
          "Ova informacija koristi se samo za internu komunikaciju i obaveštenja.",
      },
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      roleInput: {
        label: "Uloge",
        manageAccountLabel: "Podešavanje naloga",
        managePlaceLabel: "Podešavanje lokacije",
        manageBookingsLabel: "Podešavanje termina za lokaciju",
        manageCalendarLabel: "Podešavanje kalendara",
      },
    },
    availability: {
      title: "Dostupnost",
      description:
        "Izaberite u kom vremenskom okviru klijent može zakazati termin na ovom kalendaru",
      editBtn: "Izmeni",
      removeBtn: "Ukloni",
      addDayOffBtn: "+ Dodajte specifičan neradni dan",
      closedLabel: "Zatvoreno",
    },
    placeAndServices: {
      title: "Lokacije i usluge",
      placeInput: {
        label: "Kojoj lokaciji pripada ovaj kalendar?",
      },
      servicesInput: {
        label: "Koja usluga može biti zakazana na ovom kalendaru?",
      },
    },
    advancedSettings: {
      title: "Napredna podešavanja",
      requiredInfoInput: {
        label: "Koje informacije su obavezne za klijenta pri zakazivanju?",
        firstNameLabel: "Ime",
        lastNameLabel: "Prezime",
        emailLabel: "Email",
        phoneLabel: "Broj telefona",
        addressLabel: "Adresa",
        zipCodeLabel: "Poštanski broj",
        cityLabel: "Grad",
        otherLabel: "Drugo",
      },
      otherInput: {
        label: "Ime koje će biti prikazano",
        placeholder: "Drugo",
      },
      cancellationMarginInput: {
        label: "Rok za otkazivanje termina",
        description: "Koliko vremenski pre termina je moguće otkazivanje?",
        locationDescription:
          "Odaberite lokaciju za koju želite izmeniti termine otkazivanja.",
        daysLabel: "dani",
        hoursLabel: "sati",
        minutesLabel: "minuti",
      },
      bookingMarginInput: {
        label: "Rok zakazivanja",
        description:
          "Minimalna vremeska distanca od slobodnog termina u kojoj je moguće izvršiti zakazivanje?",
        daysLabel: "dani",
        hoursLabel: "sati",
        minutesLabel: "minuti",
      },
      tocInput: {
        label: "Pravila korišćenja (opciono)",
        description:
          "Ukoliko dodate pravila korišćenja, kako bi mogao da zakaže termin, klijent će biti obavezan da se složi sa njima tako što će potvrditi da ih je pročitao i da se slaže s pravilima.",
      },
    },
    notifications: {
      title: "Obaveštenje",
      toServiceProviderInput: {
        label: "Za pružaoca usluge",
        description:
          'Obaveštenje će biti poslate na email koji je upisan u sekciji "Vaše informacije"',
        bookingConfirmationLabel: "Potvrda termina",
        cancellationConfirmationLabel: "Potvrda o otkazivanju",
      },
      toCustomerInput: {
        label: "Za klijenta",
        description:
          "Obaveštenje će biti poslato na email adresu koju je klijent uneo",
        bookingConfirmationLabel: "Potvrda termina",
        cancellationConfirmationLabel: "Potvrda o otkazivanju",
        reminderLabel: "Podsetnik za termin",
        followUpLabel: "Budite u toku",
      },
      followUpInput: {
        label: "Budite u toku",
        description:
          'Povećajte šansu ponovnog zakazivanja slanjem "Budite u toku" email-a',
        daysLabel: "dani nakon zakazanog termina",
      },
    },
  },
  calendarFormModal: {
    createTitle: "Dodati novog radnika",
    editTitle: "Izmeni radnika",
    createBtn: "Kreirati radnika",
    editBtn: "Sačuvati",
    basicInfoCard: {
      title: "Osnovne Informacije",
      nameInput: {
        label: "Ime",
      },
      titleInput: {
        label: "Naslov Radnika",
        description: "Ovaj naslov će biti vidljiv klijentima",
      },
      notesInput: {
        label: "Beleške",
        labelDescription: "(opciono)",
        placeholder:
          "Dodajte privatne beleške koje se mogu videti samo u podešavanjima osoblja",
      },
      contactTitle: "Kontakt",
      contactDescription:
        "Kontakti osoblja su poverljivi i neće se deliti sa vašim klijentima.",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      employmentTitle: "Radni odnos",
      employmentStartInput: {
        label: "Datum početka",
      },
      employmentEndInput: {
        label: "Datum završetka",
      },
    },
    placesCard: {
      title: "Radi u",
      description: "Izaberite lokacije na kojima ovaj član osoblja radi.",
      placesInput: {
        allLabel: "Sve lokacije",
      },
    },
    bookingCard: {
      title: "Zakazivanja",
      bookingsEnabledInput: {
        label: "Dozvolite rezervacije u kalendaru",
        description:
          "Dozvolite ovom članu osoblja da prima rezervacije u kalendaru.",
      },
    },
    colorCard: {
      title: "Boja kalendara",
      description:
        "Izaberite boju da biste videli termine ovog osoblja u kalendaru",
      colorInput: {
        label: "Izaberite boju",
      },
    },
    servicesCard: {
      title: "Usluge",
      description: "Dodajte usluge koje ovaj član osoblja može ponuditi.",
      servicesInput: {
        allSelectedLabel: "Sve usluge",
        countSelectedLabel: "Odabrane usluge",
        noSelectedLabel: "Ne odabrane usluge",
        editBtn: "Uredi",
      },
    },
    clientsCard: {
      title: "Klijenti",
      description:
        "Dodaj klijente koje ce radnik moci da vidi iako ima samo osnovne dozvole",
      clientsInput: {
        allSelectedLabel: "Svi klijenti",
        countSelectedLabel: "Odabranih klijenata",
        noSelectedLabel: "Nema odabranih klijenata",
        editBtn: "Uredi",
      },
    },
    permissionsCard: {
      title: "Dozvole osoblja",
    },
    selectServicesModal: {
      title: "Izaberite usluge",
      searchInput: {
        placeholder: "Usluge pretrage",
      },
      allServicesLabel: "Sve usluge",
      closeBtn: "Otkaži",
      saveBtn: "Sačuvaj",
    },
    selectClientsModal: {
      title: "Izaberite klijente",
      searchInput: {
        placeholder: "Pretraga klijenata",
      },
      allClientsLabel: "Svi klijenti",
      closeBtn: "Otkaži",
      saveBtn: "Sačuvaj",
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  clients: {
    title: "Klijenti",
    searchInput: {
      placeholder: "Pretraga po imenu, email adresi ili broju telefona",
    },
    createClientBtn: "Novi klijent",
    clientDropdown: {
      showBtn: "Pogledaj profil",
      editBtn: "Izmeni informacije",
      deleteBtn: "Obriši klijenta",
    },
    table: {
      columns: {
        name: "Ime",
        phone: "Broj telefona",
        email: "Email",
      },
      pagination: {
        show: "Prikazivanje",
        of: "od",
      },
    },
    mobileSearchInput: {
      placeholder: "Pretraga po imenu klijenta",
    },
  },
  clientProfile: {
    title: "Profil klijenta",
    createAppointmentDropdown: {
      toggleBtn: "Zakazivanje",
      appointmentBtn: "Novi termin",
      groupAppointmentBtn: "Novi grupni termin"
    },
    dropdown: {
      editBtn: "Izmenite informacije",
      deleteBtn: "Obrišite klijenta",
    },
    infoCard: {
      email: {
        label: "Email",
      },
      phone: {
        label: "Broj telefona",
      },
      notes: {
        label: "Napomena klijenta",
      },
      notifications: {
        label: "Obaveštenja",
        enabledLabel: "Omogućeno",
        disabledLabel: "Onemogućeno",
        viaLabel: "Tip Obaveštenja",
        emailLabel: "Putem Email-a",
        unknownLabel: "Nepoznato",
      },
    },
    salesCard: {
      title: "Ukupna prodaja",
      bookings: {
        all: "Sva zakazivanja",
        completed: "Izvršeno",
        canceled: "Otkazano",
      },
    },
    mobileButtons: {
      editBtn: "Promeni informacije",
      deleteBtn: "Obriši klijenta",
      closeBtn: "Zatvori",
    },
  },
  appointmentList: {
    title: "Prethodna zakazivanja",
    noAppointmentsLabel: "Nema prethodnih zakazivanja",
    status: {
      active: "Aktivan",
      finished: "Završen",
      canceled: "Otkazan",
    },
  },
  clientForm: {
    createTitle: "Napravi novog klijenta",
    editTitle: "Izmeni klijenta",
    saveBtn: "Upamti",
    cancelBtn: "Odbaci",
    contactDetails: {
      title: "Kontakt informacije",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      notificationsInput: {
        label: "Obaveštenja",
        description:
          "Obaveštenje će biti poslato na gore navedenu email adresu",
        notificationsLabel: "Obaveštenja",
      },
    },
    aditionalInfo: {
      title: "Dodatne informacije (opciono)",
      addressInput: {
        label: "Adresa",
      },
      cityInput: {
        label: "Grad",
      },
      zipCodeInput: {
        label: "Poštanski broj",
      },
      notesInput: {
        label: "Napomena klijenta",
        placeholder: "Npr. Alergičan na orahe",
      },
    },
  },
  clientFormModal: {
    createTitle: "Novi klijent",
    editTitle: "Uredi klijenta",
    saveBtn: "Sačuvaj",
    closeBtn: "Otkaži",
    deleteBtn: "Obriši",
    tabs: {
      personalInfo: "Lični Podaci",
      address: "Adresa",
    },
    firstNameInput: {
      label: "Ime",
    },
    lastNameInput: {
      label: "Prezime",
    },
    mobilePhoneInput: {
      label: "Telefon",
    },
    phoneInput: {
      label: "Broj telefona",
    },
    emailInput: {
      label: "Email",
    },
    sendNotificationsByInput: {
      label: "Pošaljite obaveštenja do",
      options: {
        email: "Email",
        dontSend: "Ne šalji obaveštenja",
      },
    },
    notificationsEnabledInput: {
      label: "Prihvata marketinška obaveštenja",
    },
    genderInput: {
      label: "Pol",
      options: {
        unknown: "Nepoznato",
        male: "Muško",
        female: "Žensko",
      },
    },
    birthdayInput: {
      label: "Rodjendan",
      monthPlaceholder: "Mesec",
      dayPlaceholder: "Dan",
      yearPlaceholder: "Godina",
    },
    notesInput: {
      label: "Napomena klijenta",
    },
    displayNotesInput: {
      label: "Prikaz na svim rezervacijama",
    },
    addressInput: {
      label: "Adresa",
    },
    cityInput: {
      label: "Grad",
    },
    stateInput: {
      label: "Država",
    },
    zipCodeInput: {
      label: "Poštanski broj",
    },
    mobileSection: {
      notesSection: {
        title: "Napomena klijenta",
        notesInput: {
          label: "Napomena klijenta",
        },
        displayNotesInput: {
          label: "Prikaz na svim rezervacijama",
        },
      },
      additionalInfoSection: {
        title: "Dodatne Informacije",
        personalInfoBtn: "Lični podaci",
        addressDetailsBtn: "Detalji adrese",
      },
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  addressDetailsMobileModal: {
    title: "Detalji adrese",
    addressInput: {
      label: "Adresa",
    },
    cityInput: {
      label: "Grad",
    },
    stateInput: {
      label: "Država",
    },
    zipCodeInput: {
      label: "Poštanski broj",
    },
    saveBtn: "Sačuvaj",
  },
  personalInfoMobileModal: {
    title: "Lični podaci",
    genderInput: {
      label: "Pol",
    },
    birthdayInput: {
      label: "Rodjendan",
      monthPlaceholder: "Mesec",
      dayPlaceholder: "Dan",
      yearPlaceholder: "Godina",
    },
    saveBtn: "Sačuvaj",
  },
  services: {
    title: "Usluge",
    createServiceBtn: "Dodaj novu uslugu",
    table: {
      createServiceBtn: "Dodaj novu uslugu",
      dropdown: {
        createServiceBtn: "Dodaj novu uslugu",
        editCategoryNameBtn: "Promeni naziv kategorije",
        deleteCategoryBtn: "Obriši kategoriju",
      },
      editBtn: "Izmeni",
      minutesLabel: "Minuti",
      noDescriptionLabel: "Nema opisa usluge",
      groupServiceLabel: "Klasa",
    },
    mobileButtons: {
      createServiceBtn: "Dodaj novu uslugu",
      editCategoryNameBtn: "Izmeni naziv kategorije",
      deleteCategoryBtn: "Obriši kategoriju",
      closeBtn: "Zatvori",
    },
    mobileServiceButtons: {
      editServiceBtn: "Uredi uslugu",
      deleteServiceBtn: "Izbriši uslugu",
      closeBtn: "Zatvori",
    },
    tableFilter: {
      services: "Usluge",
      categories: "Kategorije",
    },
  },
  mobileServicesFilter: {
    title: "Filteri",
    services: "Usluge",
    categories: "Kategorije",
  },
  serviceForm: {
    createTitle: "Dodaj usluge",
    editTitle: "Izmeni uslugu",
    cancelBtn: "Poništi",
    saveBtn: "Upamti",
    deleteBtn: "Obriši",
    nameInput: {
      label: "Naziv usluge",
      placeholder: "Npr. Tretman tela, anticelulit masaža",
    },
    categoryInput: {
      label: "Kategorija",
      description: "Usluga je dodata u ovoj kategoriji na vašem profilu",
      placeholder: "Dodajte kategoriju kucanjem ili biranjem iz prethodnih",
    },
    descriptionInput: {
      label: "Opis (opciono)",
      placeholder:
        "Npr. Tretman tela koji obuhvata zatezanje algama i anticelulit masažu",
    },
    priceInput: {
      label: "Cena",
      showPublicLabel: "Prikaži na profilu",
    },
    durationInput: {
      label: "Trajanje (u minutima)",
      showPublicLabel: "Prikaži na profilu",
    },
    serviceTypeInput: {
      label: "Tip usluge",
      description:
        "Dozvoli pretraživačima da pruže klijentima preciznije informacije",
    },
    sameTimePeopleInput: {
      label: "Broj osoba koji mogu zakazati u istom terminu",
      description: "Npr. 20 osoba može zakazati u ovom terminu fitnes trening",
      inputLabel: "osobe",
      toggleBtn: "+ Dozvoli da još osoba mogu zakazati u istom terminu",
    },
    breakInput: {
      label: "Pauza nakon zakazivanja ove usluge",
      description:
        "U trajanju od toliko minuta, niko neće moći da zakaže termin nakon završetka ove usluge",
      inputLabel: "minuti",
      toggleBtn: "+ Dodaj pauzu nakon usluge",
    },
  },
  serviceFormModal: {
    titles: {
      chooseType: "Dodajte novu uslugu u svoj servisni meni",
      singleService: "Dodajte novu uslugu",
    },
    serviceTypes: {
      single: {
        name: "Usluge",
        description: "Usluge koje rezerviše jedan klijent u jednoj poseti",
      },
      session: {
        name: "Klasa",
        description:
          "Usluge koje rezerviše više klijenata u zakazanim sesijama",
      },
    },
    comingSoonLabel: "Uskoro dostupno",
    saveBtn: "Sačuvaj",
    basicInfoCard: {
      title: "Osnovni podaci",
      description: "Dodajte naziv usluge i izaberite tip tretmana.",
      nameInput: {
        label: "Ime usluge",
      },
      typeInput: {
        label: "Vrsta tretmana",
        placeholder: "Odaberite vrstu tretmana",
        description:
          "Izaberite najrelevantniju vrstu tretmana. Ovo neće biti vidljivo vašim klijentima.",
      },
      categoryInput: {
        label: "Kategorija",
        description:
          "Usluga je stavljena u ovu kategoriju na vašem javnom profilu",
        placeholder:
          "Dodajte kategoriju kucanjem ili izaberite neku od prethodnih",
      },
      descriptionInput: {
        label: "Opis usluge",
        placeholder: "Dodajte kratak opis",
      },
    },
    onlineBookingsCard: {
      title: " Online rezervacije",
      description:
        "Omogućite onlajn rezervacije, odaberite kome je usluga dostupna i dodajte kratak opis",
      onlineBookingsInput: {
        label: "Omogućite online rezervacije",
      },
    },
    staffCard: {
      title: "Osoblje",
      staffInput: {
        allLabel: "Izaberi sve",
      },
    },
    pricingCard: {
      title: "Cene i trajanje",
      description: "Dodajte opcije cena i trajanje usluge.",
      priceTitle: "Opcija cene",
      durationInput: {
        label: "Trajanje",
      },
      priceTypeInput: {
        label: "Tip cene",
      },
      priceInput: {
        label: "Cena",
      },
      extraTimeTitle: "Dodatno vreme",
      extraTimeDescription: "Omogućite dodatno vreme nakon usluge",
      enableExtraTimeInput: {
        label: "Omogućite dodatno vreme",
      },
      extraTimeCard: {
        title: "Blokirano vreme",
        description: "Blokirajte vreme između sastanaka",
      },
      breakDurationInput: {
        label: "Trajanje",
      },
      priceTypeDropdown: {
        free: "Besplatno",
        fixed: "Fiksno",
        from: "Od",
      },
    },
    sameTimePeopleCard: {
      title: "Broj ljudi u jednoj klasi",
      description: "Podesite maksimalan broj ljudi koji mogu da rezervišu ovu uslugu u isto vreme.",
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  staff: {
    title: "Osoblje",
    createStaffBtn: "Novo osoblje",
    table: {
      columns: {
        name: "Ime",
        phone: "Broj telefona",
        email: "Email",
      },
      dropdown: {
        editBtn: "Izmeni informacije",
        deleteBtn: "Obriši osoblje ",
      },
      pagination: {
        show: "Prikaži",
        of: "od",
      },
    },
    mobileButtons: {
      editStaffBtn: "Uredi osoblje",
      editWorkingHoursBtn: "Uredite radno vreme",
      deleteStaffBtn: "Izbrišite osoblje",
      closeBtn: "Zatvori",
    },
  },
  mobileStaffFilter: {
    title: "Filteri",
    placesLabel: "Lokacije",
  },
  statistics: {
    title: "Statistika",
    totalSalesLabel: "Ukupna prodaja",
    totalAppointmentsLabel: "Ukupno zakazivanja",
    completedAppointmentsLabel: "Završena zakazivanja",
    canceledAppointmentsLabel: "Otkazana zakazivanja",
    staffSalesLabel: "Prodaja od strane osoblja",
    noDataLabel: "Nema dostupnih podataka",
    topServicesCard: {
      title: "Najprodavanije usluge",
      serviceNameLabel: "Naziv usluge",
      totalSalesLabel: "Ukupna prodaja (rsd)",
    },
    charts: {
      currentIncomeLabel: "Trenutni prihod",
      pastYearLabel: "Prošla godina",
    },
  },
  statisticsFilters: {
    placeFilter: {
      allLocationsLabel: "Sve lokacije",
    },
    periodFilter: {
      dayLabel: "Dan",
      monthLabel: "Mesec",
      yearLabel: "Godina",
    },
    mobilePlaceFilter: {
      title: "Filteri",
      allLocationsLabel: "Sve lokacije",
    },
    mobilePeriodFilter: {
      title: "Izaberi datum",
    },
  },
  settings: {
    title: "Podešavanje",
    tabs: {
      widget: "Vidžet podešavanje",
      account: "Podešavanje profila",
      locations: "Lokacije",
      billing: "Plaćanje",
    },
    mobileTabsSelect: {
      widget: {
        title: "Vidžet podešavanje",
        description: "Prilagodite Vaš vidžet.",
      },
      account: {
        title: "Podešavanje profila",
        description:
          "Upravljate podešavanjem Vaših biznis informacija i linkova.",
      },
      locations: {
        title: "Lokacije",
        description: "Postavite više lokacija za Vaš biznis.",
      },
      billing: {
        title: "Plaćanje",
        description:
          "Upravljajte Vašim planom, načinom plaćanja i vidite istoriju plaćanja.",
      },
    },
  },
  widgetTab: {
    previewBtn: "Pregled vidžeta",
    saveBtn: "Upamti",
    backBtn: "Vrati se na podešavanje vidžeta",
    appearance: {
      title: "Prikazivanje",
      logoInput: {
        label: "Logo kompanije:",
        description: "Učitaj",
      },
      colorInput: {
        label: "Boja pozadine:",
      },
      statusInput: {
        label: "Status:",
      },
      messageInput: {
        label: "Poruka:",
      },
      showReviewsInput: {
        label: "Prikaži recenzije:",
      },
    },
    installation: {
      title: "Instalacije",
      description:
        "Izgleda da niste instalirali chat kod još uvek. Izberite jednu od instalacija ispod: ",
      jsCard: {
        instructions: {
          step1:
            "Nalepite ovaj kod tik ispred zatvarenog </body> taga, koji se nalazi pri dnu fajla.",
        },
        copyBtn: "Kopiraj",
        needHelpLabel: "Potrebna Vam je pomoć?",
        contactUsLabel: "Kontaktirajte nas",
      },
      wpCard: {
        instructions: {
          step1: "Ulogujte se na admin panel Vašeg Wordpress sajta",
          step2: 'Idite u "Appearance" > "Theme Editor"',
          step3:
            'Ispod "Theme Files" (desno), click on “Theme Footer” (footer.php)',
          step4:
            "Nalepite ovaj kod tik ispred zatvarenog </body> taga, koji se nalazi pri dnu fajla.",
          step5: 'Kliknite "Update file" da zapamtite promene.',
        },
        copyBtn: "Kopiraj",
        needHelpLabel: "Potrebna Vam je pomoć?",
        contactUsLabel: "Kontaktirajte nas",
      },
    },
    widget: {
      startBookingLabel: "Pokreni zakazivanje",
      nextFreeTimeLabel: "Sledeće ostalo slobodno vreme",
      bookingBtn: "Zakažite termin sa nama",
      reviewsLabel: "Pogledajte šta naši klijenti kažu o nama",
      poweredByLabel: "Powered by",
    },
  },
  accountTab: {
    guide: {
      businessInfo: {
        title: "Biznis Info",
        description:
          "Ove informacije se koriste samo za internu komunikaciju. Neće biti prikazane na Vašem profilu.",
      },
      onlineLinks: {
        title: "Web linkovi",
        description:
          "Dodajte adresu Vašeg web sajta i socijalnih mreža koje će biti prikazane klijentima",
      },
    },
    companyDetailsCard: {
      title: "Informacije o kompaniji",
      nameInput: {
        label: "Ime firme",
      },
      organisationNumberInput: {
        label: "PIB",
      },
      addressInput: {
        label: "Adresa",
      },
      postalNumberInput: {
        label: "Poštanski broj",
      },
      cityInput: {
        label: "Grad",
      },
      emailInput: {
        label: "Email",
      },
    },
    onlineLinksCard: {
      websiteInput: {
        label: "Web sajt",
      },
      fbPageInput: {
        label: "Facebook stranica",
      },
      igPageInput: {
        label: "Instagram stranica",
      },
    },
    saveBtn: "Upamti",
  },
  locationsTab: {
    allLocationsSection: {
      title: "Lokacije",
      description: "Upravljajte sa više lokacija Vašeg poslovanja",
      createPlaceBtn: "Dodajte novu lokaciju",
    },
    selectedPlaceSection: {
      dropdown: {
        toggleBtn: "Opcije",
        editBtn: "Izmeni informacije o lokaciji",
        deleteBtn: "Obriši lokaciju",
      },
      detailsCard: {
        title: "Informacije o lokaciji",
        editBtn: "Izmeni",
        emailLabel: "Email adresa lokacije",
        phoneLabel: "Kontakt telefon lokacije",
      },
      categoryCard: {
        title: "Kategorija poslovanja",
        editBtn: "Izmeni",
        mainCategoryLabel: "Glavna",
        additionalCategoryLabel: "Dodatna",
        addBtn: "+ dodaj",
      },
      openingHoursCard: {
        title: "Radno vreme",
        editBtn: "Izmeni",
        closedLabel: "Zatvori",
      },
      photosCard: {
        title: "Moje slike",
        editBtn: "Izmeni",
        label: "Prikaži svoj rad",
        description:
          "Prikaži slike Vašeg rada (pre/posle), Vašeg tima, lokala, opreme itd.",
      },
      mobileButtons: {
        editBtn: "Izmeni informacije o lokaciji",
        deleteBtn: "Obriši lokaciju",
        closeBtn: "Zatvori",
      },
    },
  },
  billingTab: {
    planCard: {
      title: "Plan",
      planName: "Tim",
      priceDescription: "/korisnik/mesec",
      planInfo: "Mesečni račun",
      changePlanBtn: "Izmeni plan",
    },
    paymentMethodCard: {
      title: "Način plaćanja",
      addBtn: "+ dodaj",
      expireLabel: "Datum isteka",
    },
    billingHistoryCard: {
      title: "Istorija plaćanja",
      table: {
        columns: {
          date: "Datum",
          description: "Opis",
          amount: "Cena",
        },
        viewBtn: "Pogledajte račun",
      },
    },
  },
  profile: {
    title: "Profil",
    saveBtn: "Upamti",
    guide: {
      personalDetails: {
        title: "Personalne informacije",
        description:
          "Postavi svoje ime i kontakt informacije. Ova email adresa se koristi za prijavljivanje.",
      },
      changePassword: {
        title: "Promeni lozinku",
        description:
          "Da izvršite promenu, unesite Vašu trenutnu lozinku, a zatim novu lozinku. Ukoliko ste zaboravili trenutnu lozinku, odjavite se i kliknite na link za resetovanje lozinke na stranici za prijavljivanje.",
      },
      notificationSettings: {
        title: "Podešavanje obaveštenja",
        description:
          "Primajte obaveštenja o bitnim aktivnostima na Vašem nalogu.",
      },
      changeCancellation: {
        title: "Podešavanja polise otkazivanja",
        description:
          "Podesite koliko vremena pre početka termina je moguće otkazivanje samog termina. Vremenski period unosite posebno za svaku lokaciju na kojoj radite.",
      },
    },
    personalDetailsCard: {
      uploadAvatarBtn: "Ubacite profilnu sliku",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      emailInput: {
        label: "Email",
      },
      currentPasswordInput: {
        label: "Trenutna lozinka",
      },
      newPasswordInput: {
        label: "Nova lozinka",
      },
    },
    notificationsCard: {
      notifyMeInput: {
        label: "Obavesti me o",
        options: {
          allStaff: "Zakazanim terminima sa svim osobljem",
          meOnly: "Zakazaninim terminima samnom",
        },
      },
      bookedInput: {
        label: "Zakazano u",
        allLocationsLabel: "Sve lokacije",
        changeBtn: "Izmeni",
        newAppointmentsLabel: "Novi termini",
        cancellationLabel: "Otkazano",
      },
    },
    croperModal: {
      title: "Isecite sliku",
      saveBtn: "Primeni",
    },
    locationsModal: {
      title: "Izaberite lokaciju",
      allPlacesLabel: "Izaberite sve",
      saveBtn: "Izaberite",
    },
  },
  locationInput: {
    title: "Lokacija",
    label: "Koja je lokacija Vašeg biznisa?",
  },
  cancelAppointmentModal: {
    title: "Poništi termin",
    messageInput: {
      label: "Poruka",
      description: "(opciono)",
    },
    submitBtn: "Otkazati termin",
  },
  placeFormModal: {
    previousStepBtn: "Prethodno",
    nextStepBtn: "Sledeći korak",
    saveBtn: "Upamti",
    stepLabel: "Korak",
    stepOfLabel: "od",
    createPlaceMode: {
      step1: {
        title: "O tvom biznisu",
      },
      step2: {
        title: "Odaberite glavnu kategoriju Vašeg biznisa",
      },
      step3: {
        title: "Izaberite sekundarnu kategoriju Vašeg biznisa",
        description:
          "Ako Vaš biznes može biti opisan sa više kategorija, izaberite ih i primenite",
      },
      step4: {
        title: "Dodajte Vašu lokaciju",
      },
    },
    editInfoMode: {
      title: "O Vašem biznisu",
    },
    editCategoriesMode: {
      step1: {
        title: "Odaberite glavnu kategoriju Vašeg biznisa",
      },
      step2: {
        title: "Izaberite sekundarnu kategoriju Vašeg biznisa",
        description:
          "Ako Vaš biznes može biti opisan sa više kategorija, izaberite ih i primenite",
      },
    },
    basicInfoCard: {
      title: "Osnovno",
      logoInput: {
        label: "Izaberite logo (opciono)",
        description: "Ova slika biće prikazana na Vašem profilu",
      },
      nameInput: {
        label: "Ime kompanije",
        description: "(prikazano na profilnoj strani)",
      },
      publicProfileInput: {
        label: "Link na javnom profilu",
      },
      phoneInput: {
        label: "Kontakt telefon lokacije",
      },
      emailInput: {
        label: "Email adresa lokacije",
      },
      descriptionInput: {
        label: "O nama",
      },
      maxReservationInput: {
        label: "Maksimalan broj zakazanih usluga",
        description: "(broj usluga u jednom vremenskom terminu)",
        shortDescription: "po terminu",
      },
    },
    validation: {
      error: "Polje je obavezno",
      locationError:
        "Lokacija je obavezna. Morate izabrati jednu od ponudjenih lokacija.",
      mainCategoryError: "Glavna kategorija je obavezna",
    },
  },
  placeImagesModal: {
    title: "Moje slike",
    card: {
      title: "Ubacite slike",
    },
    saveBtn: "Upamti",
  },
  availabilityInput: {
    title: "Dostupnost",
    closedLabel: "Zatvoreno",
    addBtn: "+ Dodaj",
    saveBtn: "Upamti",
  },
  availabilityFormModal: {
    title: "Dostupnost",
    fromInput: {
      label: "Od",
    },
    toInput: {
      label: "Do",
    },
    closedLabel: "Zatvoreno",
    saveBtn: "Sačuvaj",
    closeBtn: "Zatvori",
  },
  openingHoursForm: {
    title: "Vreme otvaranja",
    closedLabel: "Zatvoreno",
    saveBtn: "Upamti",
  },
  mobileClientSelect: {
    title: "Izaberite klijenta",
    searchPlaceholder: "Pretraga po imenu klijenta",
  },
  multipleClientSelect: {
    title: "Izaberite klijente",
    searchPlaceholder: "Pretraga po imenu klijenta",
    selectAllLabel: "Izaberite sve klijente",
    saveBtn: "Sačuvaj izabrane klijente",
  },
  mobileServiceSelect: {
    title: "Izaberite uslugu",
    searchPlaceholder: "Pretraga po nazivu usluge",
  },
  weekdays: {
    monday: "Ponedeljak",
    tuesday: "Utorak",
    wednesday: "Sreda",
    thursday: "Četvrtak",
    friday: "Petak",
    saturday: "Subota",
    sunday: "Nedelja",
  },
  changeLanguageModal: {
    title: "Izmenite jezik",
    languageInput: {
      label: "Izaberite jezik",
    },
    saveBtn: "Upamti",
  },
  deleteClientModal: {
    title: "Obriši klijenta",
    message: "Da li ste sigurni da želite da obrišete klijenta",
    confirmBtn: "Da",
    cancelBtn: "Ne",
  },
  deleteStaffModal: {
    title: "Obriši osoblje",
    message: "Da li ste sigurni da želite da obrišete radnika",
    confirmBtn: "Da",
    cancelBtn: "Ne",
  },
  defaults: {
    checkboxGroup: {
      chooseAllLabel: "Izaberite sve",
    },
    select: {
      placeholder: "Izaberite opciju",
      searchPlaceholder: "Pretraga",
      noOptionsText: "Nema dostupnih opcija",
    },
    dropdown: {
      toggleText: "Menjaj padajući meni",
      description: "Postavite Vaše stavke ovde",
    },
  },
};


export const law = {
  login: {
    title: "Dobrodošli",
    emailLabel: "E-mail",
    emailValidationError: "Email adresa je obavezna",
    passwordLabel: "Lozinka",
    passwordValidationError: "Lozinka je obavezna",
    keepMeLabel: "Ostavi me prijavljenog",
    forgotPassword: "Zaboravili ste lozinku?",
    loginBtn: "Prijavite se",
    dontHaveAccount: "Nemate nalog?",
    signUp: "Registrujte se",
  },
  forgotPassword: {
    title: "Zaboravili ste lozinku?",
    emailInput: {
      label: "Email",
      description:
        "Unesite Vašu email adresu da bi ste dobili link za promenu lozinke",
    },
    submitBtn: "Pošalji",
  },
  resetPassword: {
    title: "Promenite lozinku",
    passwordInput: {
      label: "Nova lozinka",
    },
    passwordConfirmationInput: {
      label: "Potvrdite novu lozinku",
    },
    submitBtn: "Promenite lozinku",
  },
  menu: {
    calendar: "Kalendar",
    clients: "Klijenti",
    services: "Usluge",
    staff: "Osoblje",
    statistics: "Statistika",
    settings: "Podešavanje",
    profile: "Profil",
    contactSupport: "Kontaktirajte podršku",
    logout: "Odjavite se",
    subjects: "Predmeti"
  },
  calendar: {
    title: "Kalendar",
    alert: {
      title: "Dobrodošli na Vaš kalendar advokate",
      text: "Zakazivanje koje klijent ili Vi zakažete prikazaće se ovde. Kliknite na zakazan termin da bi ste videli ili izmenili detalje.",
    },
    newEventActions: {
      appointment: "Novi termin",
      appointmentMobile: "Novi termin",
      groupAppointment: "Grupni termin",
      blockTime: "Vremenski blok",
      close: "Zatvori",
    },
    appointment: {
      cancelAppointment: "Poništi termin",
      editAppointment: "Izmeni termin",
      notesLabel: "Napomena",
      noNotesText: "Nema napomene za ovaj termin",
      employee: "Osoblje",
    },
    blockTime: {
      removeBlockTime: "Ukloni vremenski blok",
      editBlockTime: "Izmeni vremenski blok",
    },
    calendarEventCard: {
      clientsLabel: "klijenta"
    },
  },
  calendarFilters: {
    placeFilter: {
      createPlace: "Dodajte novu lokaciju",
    },
    staffFilter: {
      allStaff: "Sve osoblje",
      createStaff: "Dodaj novo osoblje",
    },
    viewFilter: {
      week: "nedelja",
      day: "dan",
    },
    mobileViewFilter: {
      title: "Kalendarski pregled",
      day: "Dan",
      threeDays: "3 dana",
    },
    mobilePlaceAndStaffFilter: {
      title: "Filteri",
      placesLabel: "Lokacije",
      staffLabel: "Osoblje",
      allStaffLabel: "Sve osoblje",
    },
  },
  appointmentForm: {
    createTitle: "Novi termin",
    createGroupTitle: "Novi grupni termin",
    editTitle: "Izmeni termin",
    editGroupTitle: "Izmeni grupni termin",
    createSubmitBtn: "Zakaži termin",
    editSubmitBtn: "Zapamti",
    noDateLabel: "Dodaj datum",
    clientInput: {
      label: "Klijent",
      placeholder: "Dodaj klijenta",
      searchPlaceholder: "Pretraga klijenata",
      noResultsText: "Nema dostupnih klijenata",
      editBtn: "Izmeni detalje",
      removeBtn: "Ukloni iz termina",
      createBtn: "Dodaj novog klijenta",
      details: {
        title: "Detalji klijenta",
        emailLabel: "Email",
        phoneLabel: "Broj telefona",
        notesLabel: "Napomena klijenta",
      },
    },
    clientsInputBtn: "Izaberite klijente",
    subjectInput: {
      label: "Predmet",
      placeholder: "Dodaj predmet",
      searchPlaceholder: "Pretraga predmeta",
      noResultsText: "Nema dostupnih predmeta",
      editBtn: "Edit details",
      removeBtn: "Ukloni iz termina",
      createBtn: "Dodaj novi predmet",
      details: {
        title: "Detalji predmeta",
        subjectNumber: "Iterni broj predmeta",
        courtNumber: "Sudski broj predmeta",
        courtName: "Nadležni sud",
      },
    },
    serviceInput: {
      label: "Usluge",
      placeholder: "Dodajte uslugu",
      searchPlaceholder: "Pretraži usluge",
      noResultsText: "Nema dostupnih usluga",
      createBtn: "Dodaj novu uslugu",
    },
    staffInput: {
      label: "Osoblje",
      placeholder: "Izaberi osoblje",
      searchPlaceholder: "Pretraži osoblje",
      noResultsText: "Nema dostupnog osoblja",
      createBtn: "Dodaj novo osoblje",
    },
    startTimeInput: {
      label: "Početak",
      placeholder: "Izaberi vreme",
      noResultsText: "Nema dostupnih termina",
    },
    durationLabel: "Trajanje",
    notesInput: {
      label: "Napomena termina",
      placeholder: "Dodaj napomenu (vidljiva samo za osoblje)",
    },
  },
  blockTimeForm: {
    title: "Vremenski blok",
    createSubmitBtn: "Napravite vremenski blok",
    editSubmitBtn: "Izmeni vremenski blok",
    dateLabel: "Dodaj datum",
    staffInput: {
      label: "Osoblje",
      placeholder: "Izaberi osoblje",
      searchPlaceholder: "Pretraži osoblje",
      noResultsText: "Nema dostupnog osoblja",
      createBtn: "Dodaj novo osoblje",
    },
    startTimeInput: {
      label: "Početak",
      placeholder: "Početak",
      noResultsText: "Nema slobodnog termina",
    },
    endTimeInput: {
      label: "Kraj",
      placeholder: "Kraj",
      noResultsText: "Nema slobodnog termina",
    },
    notesInput: {
      label: "Opis",
      placeholder: "Npr. ručak",
    },
  },
  dateInput: {
    appointmentTitle: "Zakazivanje datuma",
    blockTimeTitle: "Izaberi datum",
    mobileTitle: "Izaberi datum(e)",
    saveBtn: "Zapamti",
  },
  calendarForm: {
    createTitle: "Novo osoblje",
    editTitle: "Izmeni osoblje",
    saveBtn: "Zapamti",
    cancelBtn: "Poništi",
    mobileButtons: {
      sendResetPassword: "Pošalji email za promenu lozinke",
      delete: "Obriši osoblje",
      close: "Zatvori",
    },
    basicSettings: {
      title: "Osnovno podešavanje",
      profilePictureInput: {
        label:
          "Profilna slika (nije obavezna, ali preporučujemo da je postavite)",
        description: "Ova slika će biti prikazana na Vašem profilu",
        mobileLabel: "Profilna slika",
        mobileDescription: "(opciono, ali preporučujemo da je postavite)",
        uploadBtn: "Unesite profilnu sliku",
        deleteBtn: "Obriši",
      },
      nameInput: {
        label: "Ime",
        placeholder: "Npr. Sava",
      },
      titleInput: {
        label: "Naslov",
        placeholder: "Npr. Frizer",
      },
      personalDetails: {
        label: "Vaše informacije",
        description:
          "Ova informacija koristi se samo za internu komunikaciju i obaveštenja.",
      },
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      roleInput: {
        label: "Uloge",
        manageAccountLabel: "Podešavanje naloga",
        managePlaceLabel: "Podešavanje lokacije",
        manageBookingsLabel: "Podešavanje termina za lokaciju",
        manageCalendarLabel: "Podešavanje kalendara",
      },
    },
    availability: {
      title: "Dostupnost",
      description:
        "Izaberite u kom vremenskom okviru klijent može zakazati termin na ovom kalendaru",
      editBtn: "Izmeni",
      removeBtn: "Ukloni",
      addDayOffBtn: "+ Dodajte specifičan neradni dan",
      closedLabel: "Zatvoreno",
    },
    placeAndServices: {
      title: "Lokacije i usluge",
      placeInput: {
        label: "Kojoj lokaciji pripada ovaj kalendar?",
      },
      servicesInput: {
        label: "Koja usluga može biti zakazana na ovom kalendaru?",
      },
    },
    advancedSettings: {
      title: "Napredna podešavanja",
      requiredInfoInput: {
        label: "Koje informacije su obavezne za klijenta pri zakazivanju?",
        firstNameLabel: "Ime",
        lastNameLabel: "Prezime",
        emailLabel: "Email",
        phoneLabel: "Broj telefona",
        addressLabel: "Adresa",
        zipCodeLabel: "Poštanski broj",
        cityLabel: "Grad",
        otherLabel: "Drugo",
      },
      otherInput: {
        label: "Ime koje će biti prikazano",
        placeholder: "Drugo",
      },
      cancellationMarginInput: {
        label: "Rok za otkazivanje termina",
        description: "Koliko vremenski pre termina je moguće otkazivanje?",
        locationDescription:
          "Odaberite lokaciju za koju želite izmeniti termine otkazivanja.",
        daysLabel: "dani",
        hoursLabel: "sati",
        minutesLabel: "minuti",
      },
      bookingMarginInput: {
        label: "Rok zakazivanja",
        description:
          "Minimalna vremeska distanca od slobodnog termina u kojoj je moguće izvršiti zakazivanje?",
        daysLabel: "dani",
        hoursLabel: "sati",
        minutesLabel: "minuti",
      },
      tocInput: {
        label: "Pravila korišćenja (opciono)",
        description:
          "Ukoliko dodate pravila korišćenja, kako bi mogao da zakaže termin, klijent će biti obavezan da se složi sa njima tako što će potvrditi da ih je pročitao i da se slaže s pravilima.",
      },
    },
    notifications: {
      title: "Obaveštenje",
      toServiceProviderInput: {
        label: "Za pružaoca usluge",
        description:
          'Obaveštenje će biti poslate na email koji je upisan u sekciji "Vaše informacije"',
        bookingConfirmationLabel: "Potvrda termina",
        cancellationConfirmationLabel: "Potvrda o otkazivanju",
      },
      toCustomerInput: {
        label: "Za klijenta",
        description:
          "Obaveštenje će biti poslato na email adresu koju je klijent uneo",
        bookingConfirmationLabel: "Potvrda termina",
        cancellationConfirmationLabel: "Potvrda o otkazivanju",
        reminderLabel: "Podsetnik za termin",
        followUpLabel: "Budite u toku",
      },
      followUpInput: {
        label: "Budite u toku",
        description:
          'Povećajte šansu ponovnog zakazivanja slanjem "Budite u toku" email-a',
        daysLabel: "dani nakon zakazanog termina",
      },
    },
  },
  calendarFormModal: {
    createTitle: "Dodati novog radnika",
    editTitle: "Izmeni radnika",
    createBtn: "Kreirati radnika",
    editBtn: "Sačuvati",
    basicInfoCard: {
      title: "Osnovne Informacije",
      nameInput: {
        label: "Ime",
      },
      titleInput: {
        label: "Naslov Radnika",
        description: "Ovaj naslov će biti vidljiv klijentima",
      },
      notesInput: {
        label: "Beleške",
        labelDescription: "(opciono)",
        placeholder:
          "Dodajte privatne beleške koje se mogu videti samo u podešavanjima osoblja",
      },
      contactTitle: "Kontakt",
      contactDescription:
        "Kontakti osoblja su poverljivi i neće se deliti sa vašim klijentima.",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      employmentTitle: "Radni odnos",
      employmentStartInput: {
        label: "Datum početka",
      },
      employmentEndInput: {
        label: "Datum završetka",
      },
    },
    placesCard: {
      title: "Radi u",
      description: "Izaberite lokacije na kojima ovaj član osoblja radi.",
      placesInput: {
        allLabel: "Sve lokacije",
      },
    },
    bookingCard: {
      title: "Zakazivanja",
      bookingsEnabledInput: {
        label: "Dozvolite rezervacije u kalendaru",
        description:
          "Dozvolite ovom članu osoblja da prima rezervacije u kalendaru.",
      },
    },
    colorCard: {
      title: "Boja kalendara",
      description:
        "Izaberite boju da biste videli termine ovog osoblja u kalendaru",
      colorInput: {
        label: "Izaberite boju",
      },
    },
    servicesCard: {
      title: "Usluge",
      description: "Dodajte usluge koje ovaj član osoblja može ponuditi.",
      servicesInput: {
        allSelectedLabel: "Sve usluge",
        countSelectedLabel: "Odabrane usluge",
        noSelectedLabel: "Ne odabrane usluge",
        editBtn: "Uredi",
      },
    },
    clientsCard: {
      title: "Klijenti",
      description:
        "Dodaj klijente koje ce radnik moci da vidi iako ima samo osnovne dozvole",
      clientsInput: {
        allSelectedLabel: "Svi klijenti",
        countSelectedLabel: "Odabranih klijenata",
        noSelectedLabel: "Nema odabranih klijenata",
        editBtn: "Uredi",
      },
    },
    permissionsCard: {
      title: "Dozvole osoblja",
    },
    selectServicesModal: {
      title: "Izaberite usluge",
      searchInput: {
        placeholder: "Usluge pretrage",
      },
      allServicesLabel: "Sve usluge",
      closeBtn: "Otkaži",
      saveBtn: "Sačuvaj",
    },
    selectClientsModal: {
      title: "Izaberite klijente",
      searchInput: {
        placeholder: "Pretraga klijenata",
      },
      allClientsLabel: "Svi klijenti",
      closeBtn: "Otkaži",
      saveBtn: "Sačuvaj",
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  clients: {
    title: "Klijenti",
    searchInput: {
      placeholder: "Pretraga po imenu, email adresi ili broju telefona",
    },
    createClientBtn: "Novi klijent",
    clientDropdown: {
      showBtn: "Pogledaj profil",
      editBtn: "Izmeni informacije",
      deleteBtn: "Obriši klijenta",
    },
    table: {
      columns: {
        name: "Ime",
        phone: "Broj telefona",
        email: "Email",
      },
      pagination: {
        show: "Prikazivanje",
        of: "od",
      },
    },
    mobileSearchInput: {
      placeholder: "Pretraga po imenu klijenta",
    },
  },
  clientProfile: {
    title: "Profil klijenta",
    createAppointmentDropdown: {
      toggleBtn: "Zakazivanje",
      appointmentBtn: "Novi termin",
      groupAppointmentBtn: "Novi grupni termin"
    },
    dropdown: {
      editBtn: "Izmenite informacije",
      deleteBtn: "Obrišite klijenta",
    },
    infoCard: {
      email: {
        label: "Email",
      },
      phone: {
        label: "Broj telefona",
      },
      notes: {
        label: "Napomena klijenta",
      },
      notifications: {
        label: "Obaveštenja",
        enabledLabel: "Omogućeno",
        disabledLabel: "Onemogućeno",
        viaLabel: "Tip Obaveštenja",
        emailLabel: "Putem Email-a",
        unknownLabel: "Nepoznato",
      },
    },
    salesCard: {
      title: "Ukupna prodaja",
      bookings: {
        all: "Sva zakazivanja",
        completed: "Izvršeno",
        canceled: "Otkazano",
      },
    },
    mobileButtons: {
      editBtn: "Promeni informacije",
      deleteBtn: "Obriši klijenta",
      closeBtn: "Zatvori",
    },
  },
  appointmentList: {
    title: "Prethodna zakazivanja",
    noAppointmentsLabel: "Nema prethodnih zakazivanja",
    status: {
      active: "Aktivan",
      finished: "Završen",
      canceled: "Otkazan",
    },
  },
  clientForm: {
    createTitle: "Napravi novog klijenta",
    editTitle: "Izmeni klijenta",
    saveBtn: "Upamti",
    cancelBtn: "Odbaci",
    contactDetails: {
      title: "Kontakt informacije",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      emailInput: {
        label: "Email",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      notificationsInput: {
        label: "Obaveštenja",
        description:
          "Obaveštenje će biti poslato na gore navedenu email adresu",
        notificationsLabel: "Obaveštenja",
      },
    },
    aditionalInfo: {
      title: "Dodatne informacije (opciono)",
      addressInput: {
        label: "Adresa",
      },
      cityInput: {
        label: "Grad",
      },
      zipCodeInput: {
        label: "Poštanski broj",
      },
      notesInput: {
        label: "Napomena klijenta",
        placeholder: "Npr. Alergičan na orahe",
      },
    },
  },
  clientFormModal: {
    createTitle: "Novi klijent",
    editTitle: "Uredi klijenta",
    saveBtn: "Sačuvaj",
    closeBtn: "Otkaži",
    deleteBtn: "Obriši",
    tabs: {
      personalInfo: "Lični Podaci",
      address: "Adresa",
    },
    firstNameInput: {
      label: "Ime",
    },
    lastNameInput: {
      label: "Prezime",
    },
    mobilePhoneInput: {
      label: "Telefon",
    },
    phoneInput: {
      label: "Broj telefona",
    },
    emailInput: {
      label: "Email",
    },
    sendNotificationsByInput: {
      label: "Pošaljite obaveštenja do",
      options: {
        email: "Email",
        dontSend: "Ne šalji obaveštenja",
      },
    },
    notificationsEnabledInput: {
      label: "Prihvata marketinška obaveštenja",
    },
    genderInput: {
      label: "Pol",
      options: {
        unknown: "Nepoznato",
        male: "Muško",
        female: "Žensko",
      },
    },
    birthdayInput: {
      label: "Rodjendan",
      monthPlaceholder: "Mesec",
      dayPlaceholder: "Dan",
      yearPlaceholder: "Godina",
    },
    notesInput: {
      label: "Napomena klijenta",
    },
    displayNotesInput: {
      label: "Prikaz na svim rezervacijama",
    },
    addressInput: {
      label: "Adresa",
    },
    cityInput: {
      label: "Grad",
    },
    stateInput: {
      label: "Država",
    },
    zipCodeInput: {
      label: "Poštanski broj",
    },
    mobileSection: {
      notesSection: {
        title: "Napomena klijenta",
        notesInput: {
          label: "Napomena klijenta",
        },
        displayNotesInput: {
          label: "Prikaz na svim rezervacijama",
        },
      },
      additionalInfoSection: {
        title: "Dodatne Informacije",
        personalInfoBtn: "Lični podaci",
        addressDetailsBtn: "Detalji adrese",
      },
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  addressDetailsMobileModal: {
    title: "Detalji adrese",
    addressInput: {
      label: "Adresa",
    },
    cityInput: {
      label: "Grad",
    },
    stateInput: {
      label: "Država",
    },
    zipCodeInput: {
      label: "Poštanski broj",
    },
    saveBtn: "Sačuvaj",
  },
  personalInfoMobileModal: {
    title: "Lični podaci",
    genderInput: {
      label: "Pol",
    },
    birthdayInput: {
      label: "Rodjendan",
      monthPlaceholder: "Mesec",
      dayPlaceholder: "Dan",
      yearPlaceholder: "Godina",
    },
    saveBtn: "Sačuvaj",
  },
  services: {
    title: "Usluge",
    createServiceBtn: "Dodaj novu uslugu",
    table: {
      createServiceBtn: "Dodaj novu uslugu",
      dropdown: {
        createServiceBtn: "Dodaj novu uslugu",
        editCategoryNameBtn: "Promeni naziv kategorije",
        deleteCategoryBtn: "Obriši kategoriju",
      },
      editBtn: "Izmeni",
      minutesLabel: "Minuti",
      noDescriptionLabel: "Nema opisa usluge",
      groupServiceLabel: "Klasa",
    },
    mobileButtons: {
      createServiceBtn: "Dodaj novu uslugu",
      editCategoryNameBtn: "Izmeni naziv kategorije",
      deleteCategoryBtn: "Obriši kategoriju",
      closeBtn: "Zatvori",
    },
    mobileServiceButtons: {
      editServiceBtn: "Uredi uslugu",
      deleteServiceBtn: "Izbriši uslugu",
      closeBtn: "Zatvori",
    },
    tableFilter: {
      services: "Usluge",
      categories: "Kategorije",
    },
  },
  mobileServicesFilter: {
    title: "Filteri",
    services: "Usluge",
    categories: "Kategorije",
  },
  serviceForm: {
    createTitle: "Dodaj usluge",
    editTitle: "Izmeni uslugu",
    cancelBtn: "Poništi",
    saveBtn: "Upamti",
    deleteBtn: "Obriši",
    nameInput: {
      label: "Naziv usluge",
      placeholder: "Npr. Tretman tela, anticelulit masaža",
    },
    categoryInput: {
      label: "Kategorija",
      description: "Usluga je dodata u ovoj kategoriji na vašem profilu",
      placeholder: "Dodajte kategoriju kucanjem ili biranjem iz prethodnih",
    },
    descriptionInput: {
      label: "Opis (opciono)",
      placeholder:
        "Npr. Tretman tela koji obuhvata zatezanje algama i anticelulit masažu",
    },
    priceInput: {
      label: "Cena",
      showPublicLabel: "Prikaži na profilu",
    },
    durationInput: {
      label: "Trajanje (u minutima)",
      showPublicLabel: "Prikaži na profilu",
    },
    serviceTypeInput: {
      label: "Tip usluge",
      description:
        "Dozvoli pretraživačima da pruže klijentima preciznije informacije",
    },
    sameTimePeopleInput: {
      label: "Broj osoba koji mogu zakazati u istom terminu",
      description: "Npr. 20 osoba može zakazati u ovom terminu fitnes trening",
      inputLabel: "osobe",
      toggleBtn: "+ Dozvoli da još osoba mogu zakazati u istom terminu",
    },
    breakInput: {
      label: "Pauza nakon zakazivanja ove usluge",
      description:
        "U trajanju od toliko minuta, niko neće moći da zakaže termin nakon završetka ove usluge",
      inputLabel: "minuti",
      toggleBtn: "+ Dodaj pauzu nakon usluge",
    },
  },
  serviceFormModal: {
    titles: {
      chooseType: "Dodajte novu uslugu u svoj servisni meni",
      singleService: "Dodajte novu uslugu",
    },
    serviceTypes: {
      single: {
        name: "Usluge",
        description: "Usluge koje rezerviše jedan klijent u jednoj poseti",
      },
      session: {
        name: "Klasa",
        description:
          "Usluge koje rezerviše više klijenata u zakazanim sesijama",
      },
    },
    comingSoonLabel: "Uskoro dostupno",
    saveBtn: "Sačuvaj",
    basicInfoCard: {
      title: "Osnovni podaci",
      description: "Dodajte naziv usluge i izaberite tip tretmana.",
      nameInput: {
        label: "Ime usluge",
      },
      typeInput: {
        label: "Vrsta tretmana",
        placeholder: "Odaberite vrstu tretmana",
        description:
          "Izaberite najrelevantniju vrstu tretmana. Ovo neće biti vidljivo vašim klijentima.",
      },
      categoryInput: {
        label: "Kategorija",
        description:
          "Usluga je stavljena u ovu kategoriju na vašem javnom profilu",
        placeholder:
          "Dodajte kategoriju kucanjem ili izaberite neku od prethodnih",
      },
      descriptionInput: {
        label: "Opis usluge",
        placeholder: "Dodajte kratak opis",
      },
    },
    onlineBookingsCard: {
      title: " Online rezervacije",
      description:
        "Omogućite onlajn rezervacije, odaberite kome je usluga dostupna i dodajte kratak opis",
      onlineBookingsInput: {
        label: "Omogućite online rezervacije",
      },
    },
    staffCard: {
      title: "Osoblje",
      staffInput: {
        allLabel: "Izaberi sve",
      },
    },
    pricingCard: {
      title: "Cene i trajanje",
      description: "Dodajte opcije cena i trajanje usluge.",
      priceTitle: "Opcija cene",
      durationInput: {
        label: "Trajanje",
      },
      priceTypeInput: {
        label: "Tip cene",
      },
      priceInput: {
        label: "Cena",
      },
      extraTimeTitle: "Dodatno vreme",
      extraTimeDescription: "Omogućite dodatno vreme nakon usluge",
      enableExtraTimeInput: {
        label: "Omogućite dodatno vreme",
      },
      extraTimeCard: {
        title: "Blokirano vreme",
        description: "Blokirajte vreme između sastanaka",
      },
      breakDurationInput: {
        label: "Trajanje",
      },
      priceTypeDropdown: {
        free: "Besplatno",
        fixed: "Fiksno",
        from: "Od",
      },
    },
    sameTimePeopleCard: {
      title: "Broj ljudi u jednoj klasi",
      description: "Podesite maksimalan broj ljudi koji mogu da rezervišu ovu uslugu u isto vreme.",
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  staff: {
    title: "Osoblje",
    createStaffBtn: "Novo osoblje",
    table: {
      columns: {
        name: "Ime",
        phone: "Broj telefona",
        email: "Email",
      },
      dropdown: {
        editBtn: "Izmeni informacije",
        deleteBtn: "Obriši osoblje ",
      },
      pagination: {
        show: "Prikaži",
        of: "od",
      },
    },
    mobileButtons: {
      editStaffBtn: "Uredi osoblje",
      editWorkingHoursBtn: "Uredite radno vreme",
      deleteStaffBtn: "Izbrišite osoblje",
      closeBtn: "Zatvori",
    },
  },
  mobileStaffFilter: {
    title: "Filteri",
    placesLabel: "Lokacije",
  },
  statistics: {
    title: "Statistika",
    totalSalesLabel: "Ukupna prodaja",
    totalAppointmentsLabel: "Ukupno zakazivanja",
    completedAppointmentsLabel: "Završena zakazivanja",
    canceledAppointmentsLabel: "Otkazana zakazivanja",
    staffSalesLabel: "Prodaja od strane osoblja",
    noDataLabel: "Nema dostupnih podataka",
    topServicesCard: {
      title: "Najprodavanije usluge",
      serviceNameLabel: "Naziv usluge",
      totalSalesLabel: "Ukupna prodaja (rsd)",
    },
    charts: {
      currentIncomeLabel: "Trenutni prihod",
      pastYearLabel: "Prošla godina",
    },
  },
  statisticsFilters: {
    placeFilter: {
      allLocationsLabel: "Sve lokacije",
    },
    periodFilter: {
      dayLabel: "Dan",
      monthLabel: "Mesec",
      yearLabel: "Godina",
    },
    mobilePlaceFilter: {
      title: "Filteri",
      allLocationsLabel: "Sve lokacije",
    },
    mobilePeriodFilter: {
      title: "Izaberi datum",
    },
  },
  settings: {
    title: "Podešavanje",
    tabs: {
      widget: "Vidžet podešavanje",
      account: "Podešavanje profila",
      locations: "Lokacije",
      billing: "Plaćanje",
    },
    mobileTabsSelect: {
      widget: {
        title: "Vidžet podešavanje",
        description: "Prilagodite Vaš vidžet.",
      },
      account: {
        title: "Podešavanje profila",
        description:
          "Upravljate podešavanjem Vaših biznis informacija i linkova.",
      },
      locations: {
        title: "Lokacije",
        description: "Postavite više lokacija za Vaš biznis.",
      },
      billing: {
        title: "Plaćanje",
        description:
          "Upravljajte Vašim planom, načinom plaćanja i vidite istoriju plaćanja.",
      },
    },
  },
  widgetTab: {
    previewBtn: "Pregled vidžeta",
    saveBtn: "Upamti",
    backBtn: "Vrati se na podešavanje vidžeta",
    appearance: {
      title: "Prikazivanje",
      logoInput: {
        label: "Logo kompanije:",
        description: "Učitaj",
      },
      colorInput: {
        label: "Boja pozadine:",
      },
      statusInput: {
        label: "Status:",
      },
      messageInput: {
        label: "Poruka:",
      },
      showReviewsInput: {
        label: "Prikaži recenzije:",
      },
    },
    installation: {
      title: "Instalacije",
      description:
        "Izgleda da niste instalirali chat kod još uvek. Izberite jednu od instalacija ispod: ",
      jsCard: {
        instructions: {
          step1:
            "Nalepite ovaj kod tik ispred zatvarenog </body> taga, koji se nalazi pri dnu fajla.",
        },
        copyBtn: "Kopiraj",
        needHelpLabel: "Potrebna Vam je pomoć?",
        contactUsLabel: "Kontaktirajte nas",
      },
      wpCard: {
        instructions: {
          step1: "Ulogujte se na admin panel Vašeg Wordpress sajta",
          step2: 'Idite u "Appearance" > "Theme Editor"',
          step3:
            'Ispod "Theme Files" (desno), click on “Theme Footer” (footer.php)',
          step4:
            "Nalepite ovaj kod tik ispred zatvarenog </body> taga, koji se nalazi pri dnu fajla.",
          step5: 'Kliknite "Update file" da zapamtite promene.',
        },
        copyBtn: "Kopiraj",
        needHelpLabel: "Potrebna Vam je pomoć?",
        contactUsLabel: "Kontaktirajte nas",
      },
    },
    widget: {
      startBookingLabel: "Pokreni zakazivanje",
      nextFreeTimeLabel: "Sledeće ostalo slobodno vreme",
      bookingBtn: "Zakažite termin sa nama",
      reviewsLabel: "Pogledajte šta naši klijenti kažu o nama",
      poweredByLabel: "Powered by",
    },
  },
  accountTab: {
    guide: {
      businessInfo: {
        title: "Biznis Info",
        description:
          "Ove informacije se koriste samo za internu komunikaciju. Neće biti prikazane na Vašem profilu.",
      },
      onlineLinks: {
        title: "Web linkovi",
        description:
          "Dodajte adresu Vašeg web sajta i socijalnih mreža koje će biti prikazane klijentima",
      },
    },
    companyDetailsCard: {
      title: "Informacije o kompaniji",
      nameInput: {
        label: "Ime firme",
      },
      organisationNumberInput: {
        label: "PIB",
      },
      addressInput: {
        label: "Adresa",
      },
      postalNumberInput: {
        label: "Poštanski broj",
      },
      cityInput: {
        label: "Grad",
      },
      emailInput: {
        label: "Email",
      },
    },
    onlineLinksCard: {
      websiteInput: {
        label: "Web sajt",
      },
      fbPageInput: {
        label: "Facebook stranica",
      },
      igPageInput: {
        label: "Instagram stranica",
      },
    },
    saveBtn: "Upamti",
  },
  locationsTab: {
    allLocationsSection: {
      title: "Lokacije",
      description: "Upravljajte sa više lokacija Vašeg poslovanja",
      createPlaceBtn: "Dodajte novu lokaciju",
    },
    selectedPlaceSection: {
      dropdown: {
        toggleBtn: "Opcije",
        editBtn: "Izmeni informacije o lokaciji",
        deleteBtn: "Obriši lokaciju",
      },
      detailsCard: {
        title: "Informacije o lokaciji",
        editBtn: "Izmeni",
        emailLabel: "Email adresa lokacije",
        phoneLabel: "Kontakt telefon lokacije",
      },
      categoryCard: {
        title: "Kategorija poslovanja",
        editBtn: "Izmeni",
        mainCategoryLabel: "Glavna",
        additionalCategoryLabel: "Dodatna",
        addBtn: "+ dodaj",
      },
      openingHoursCard: {
        title: "Radno vreme",
        editBtn: "Izmeni",
        closedLabel: "Zatvori",
      },
      photosCard: {
        title: "Moje slike",
        editBtn: "Izmeni",
        label: "Prikaži svoj rad",
        description:
          "Prikaži slike Vašeg rada (pre/posle), Vašeg tima, lokala, opreme itd.",
      },
      mobileButtons: {
        editBtn: "Izmeni informacije o lokaciji",
        deleteBtn: "Obriši lokaciju",
        closeBtn: "Zatvori",
      },
    },
  },
  billingTab: {
    planCard: {
      title: "Plan",
      planName: "Tim",
      priceDescription: "/korisnik/mesec",
      planInfo: "Mesečni račun",
      changePlanBtn: "Izmeni plan",
    },
    paymentMethodCard: {
      title: "Način plaćanja",
      addBtn: "+ dodaj",
      expireLabel: "Datum isteka",
    },
    billingHistoryCard: {
      title: "Istorija plaćanja",
      table: {
        columns: {
          date: "Datum",
          description: "Opis",
          amount: "Cena",
        },
        viewBtn: "Pogledajte račun",
      },
    },
  },
  profile: {
    title: "Profil",
    saveBtn: "Upamti",
    guide: {
      personalDetails: {
        title: "Personalne informacije",
        description:
          "Postavi svoje ime i kontakt informacije. Ova email adresa se koristi za prijavljivanje.",
      },
      changePassword: {
        title: "Promeni lozinku",
        description:
          "Da izvršite promenu, unesite Vašu trenutnu lozinku, a zatim novu lozinku. Ukoliko ste zaboravili trenutnu lozinku, odjavite se i kliknite na link za resetovanje lozinke na stranici za prijavljivanje.",
      },
      notificationSettings: {
        title: "Podešavanje obaveštenja",
        description:
          "Primajte obaveštenja o bitnim aktivnostima na Vašem nalogu.",
      },
      changeCancellation: {
        title: "Podešavanja polise otkazivanja",
        description:
          "Podesite koliko vremena pre početka termina je moguće otkazivanje samog termina. Vremenski period unosite posebno za svaku lokaciju na kojoj radite.",
      },
    },
    personalDetailsCard: {
      uploadAvatarBtn: "Ubacite profilnu sliku",
      firstNameInput: {
        label: "Ime",
      },
      lastNameInput: {
        label: "Prezime",
      },
      phoneInput: {
        label: "Broj telefona",
      },
      emailInput: {
        label: "Email",
      },
      currentPasswordInput: {
        label: "Trenutna lozinka",
      },
      newPasswordInput: {
        label: "Nova lozinka",
      },
    },
    notificationsCard: {
      notifyMeInput: {
        label: "Obavesti me o",
        options: {
          allStaff: "Zakazanim terminima sa svim osobljem",
          meOnly: "Zakazaninim terminima samnom",
        },
      },
      bookedInput: {
        label: "Zakazano u",
        allLocationsLabel: "Sve lokacije",
        changeBtn: "Izmeni",
        newAppointmentsLabel: "Novi termini",
        cancellationLabel: "Otkazano",
      },
    },
    croperModal: {
      title: "Isecite sliku",
      saveBtn: "Primeni",
    },
    locationsModal: {
      title: "Izaberite lokaciju",
      allPlacesLabel: "Izaberite sve",
      saveBtn: "Izaberite",
    },
  },
  locationInput: {
    title: "Lokacija",
    label: "Koja je lokacija Vašeg biznisa?",
  },
  cancelAppointmentModal: {
    title: "Poništi termin",
    messageInput: {
      label: "Poruka",
      description: "(opciono)",
    },
    submitBtn: "Otkazati termin",
  },
  placeFormModal: {
    previousStepBtn: "Prethodno",
    nextStepBtn: "Sledeći korak",
    saveBtn: "Upamti",
    stepLabel: "Korak",
    stepOfLabel: "od",
    createPlaceMode: {
      step1: {
        title: "O tvom biznisu",
      },
      step2: {
        title: "Odaberite glavnu kategoriju Vašeg biznisa",
      },
      step3: {
        title: "Izaberite sekundarnu kategoriju Vašeg biznisa",
        description:
          "Ako Vaš biznes može biti opisan sa više kategorija, izaberite ih i primenite",
      },
      step4: {
        title: "Dodajte Vašu lokaciju",
      },
    },
    editInfoMode: {
      title: "O Vašem biznisu",
    },
    editCategoriesMode: {
      step1: {
        title: "Odaberite glavnu kategoriju Vašeg biznisa",
      },
      step2: {
        title: "Izaberite sekundarnu kategoriju Vašeg biznisa",
        description:
          "Ako Vaš biznes može biti opisan sa više kategorija, izaberite ih i primenite",
      },
    },
    basicInfoCard: {
      title: "Osnovno",
      logoInput: {
        label: "Izaberite logo (opciono)",
        description: "Ova slika biće prikazana na Vašem profilu",
      },
      nameInput: {
        label: "Ime kompanije",
        description: "(prikazano na profilnoj strani)",
      },
      publicProfileInput: {
        label: "Link na javnom profilu",
      },
      phoneInput: {
        label: "Kontakt telefon lokacije",
      },
      emailInput: {
        label: "Email adresa lokacije",
      },
      descriptionInput: {
        label: "O nama",
      },
      maxReservationInput: {
        label: "Maksimalan broj zakazanih usluga",
        description: "(broj usluga u jednom vremenskom terminu)",
        shortDescription: "po terminu",
      },
    },
    validation: {
      error: "Polje je obavezno",
      locationError:
        "Lokacija je obavezna. Morate izabrati jednu od ponudjenih lokacija.",
      mainCategoryError: "Glavna kategorija je obavezna",
    },
  },
  placeImagesModal: {
    title: "Moje slike",
    card: {
      title: "Ubacite slike",
    },
    saveBtn: "Upamti",
  },
  availabilityInput: {
    title: "Dostupnost",
    closedLabel: "Zatvoreno",
    addBtn: "+ Dodaj",
    saveBtn: "Upamti",
  },
  availabilityFormModal: {
    title: "Dostupnost",
    fromInput: {
      label: "Od",
    },
    toInput: {
      label: "Do",
    },
    closedLabel: "Zatvoreno",
    saveBtn: "Sačuvaj",
    closeBtn: "Zatvori",
  },
  openingHoursForm: {
    title: "Vreme otvaranja",
    closedLabel: "Zatvoreno",
    saveBtn: "Upamti",
  },
  mobileClientSelect: {
    title: "Izaberite klijenta",
    searchPlaceholder: "Pretraga po imenu klijenta",
  },
  multipleClientSelect: {
    title: "Izaberite klijente",
    searchPlaceholder: "Pretraga po imenu klijenta",
    selectAllLabel: "Izaberite sve klijente",
    saveBtn: "Sačuvaj izabrane klijente",
  },
  mobileServiceSelect: {
    title: "Izaberite uslugu",
    searchPlaceholder: "Pretraga po nazivu usluge",
  },
  weekdays: {
    monday: "Ponedeljak",
    tuesday: "Utorak",
    wednesday: "Sreda",
    thursday: "Četvrtak",
    friday: "Petak",
    saturday: "Subota",
    sunday: "Nedelja",
  },
  changeLanguageModal: {
    title: "Izmenite jezik",
    languageInput: {
      label: "Izaberite jezik",
    },
    saveBtn: "Upamti",
  },
  deleteClientModal: {
    title: "Obriši klijenta",
    message: "Da li ste sigurni da želite da obrišete klijenta",
    confirmBtn: "Da",
    cancelBtn: "Ne",
  },
  deleteStaffModal: {
    title: "Obriši osoblje",
    message: "Da li ste sigurni da želite da obrišete radnika",
    confirmBtn: "Da",
    cancelBtn: "Ne",
  },
  defaults: {
    checkboxGroup: {
      chooseAllLabel: "Izaberite sve",
    },
    select: {
      placeholder: "Izaberite opciju",
      searchPlaceholder: "Pretraga",
      noOptionsText: "Nema dostupnih opcija",
    },
    dropdown: {
      toggleText: "Menjaj padajući meni",
      description: "Postavite Vaše stavke ovde",
    },
  },
  subjects: {
    title: "Predmeti",
    searchInput: {
      placeholder: "Pretraga po sudskom ili iternom broju predmeta",
    },
    createSubjectBtn: "Dodaj predmet",
    subjectDropdown: {
      showBtn: "Pregled predmeta",
      editBtn: "Izmeni predmet",
      deleteBtn: "Obriši predmet",
    },
    table: {
      columns: {
        type: "Tip",
        subjectNumber: "Iterni broj predmeta",
        courtNumber: "Sudski broj predmeta",
        courtName: "Nadležni sud",
        clientName: "Klijent"
      },
      pagination: {
        show: "Prikaži",
        of: "od",
      },
    },
    mobileSearchInput: {
      placeholder: "Pretraga po sudskom ili iternom broju predmeta",
    },
  },
  subjectFormModal: {
    createTitle: "Dodaj nov predmet",
    editTitle: "Izmeni predmet",
    closeBtn: "Poništi",
    saveBtn: "Upamti",
    deleteBtn: "Obriši",
    subjectNumber: {
      label: "Sudski broj predmeta",
    },
    courtNumber: {
      label: "Interni broj predmeta",
    },
    type: {
      label: "Tip predmeta",
      options: {
        culpability: "Krivica",
        litigation: "Parnica"
      }
    },
    courtName: {
      label: "Odaberi nadležni sud / Tužilaštvo",
    },
    subjectInput: {
      searchPlaceholder: "Pretraži",
    },
    clientInput: {
      label: "Odaberi klijenta",
      placeholder: "Odaberi klijenta",
      searchPlaceholder: "Pretraži",
      noResultsText: "Nema rezultata",

    },
    clientType: {
      label: "Tip klijenta",
      options: {
        defendant: "Tužilac",
        prosecutor: "Tuženi"
      },
    },
    otherClient: {
      label: "Unesite informacije druge osobe",
      firstNameInput: "Ime",
      lastNameInput: "Prezime",
      mobilePhoneInput: "Kontakt telefon",
    },
    validation: {
      error: "Polje je obavezno",
    },
  },
  deleteSubjectModal: {
    title: "Obriši predmet",
    message: "Da li ste sigurni da želite da obrišete predmet i sve vezane podatke?",
    confirmBtn: "Da",
    cancelBtn: "Ne",
  },
  subject: {
    title: "Predmet",
    createAppointmentDropdown: {
      toggleBtn: "Novo Zakazivanje",
      appointmentBtn: "Novi termin",
      groupAppointmentBtn: "Novi grupni termin"
    },
    dropdown: {
      editBtn: "Izmenite informacije",
      deleteBtn: "Obrišite predmet",
    },
    infoCard: {
      fullname: {
        label: "Ime i prezime",
      },
      email: {
        label: "Email",
      },
      phone: {
        label: "Broj telefona",
      },
      type: {
        label: "Tip predmeta",
        options: {
          culpability: "Krivica",
          litigation: "Parnica"
        }
      },
      clientType: {
        label: "Tip klijenta",
        options: {
          defendant: "Tužilac",
          prosecutor: "Tuženi"
        },
      },
      courtNumber: {
        label: "Sudski broj predmeta",
      },
      courtName: {
        label: "Nadležni sud",
      },
      otherClient: {
        label: "Informacije o drugom klijentu",
        firstNameInput: "Ime",
        lastNameInput: "Prezime",
        mobilePhoneInput: "Kontakt telefon",
      },
    },
    salesCard: {
      title: "Ukupna prodaja",
      bookings: {
        all: "Sva zakazivanja",
        completed: "Izvršeno",
        canceled: "Otkazano",
      },
    },
    mobileButtons: {
      editBtn: "Promeni informacije",
      deleteBtn: "Obriši predmeta",
      closeBtn: "Zatvori",
    },
  },
};
