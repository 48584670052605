// action types
export const SET_ALERT_MESSAGES = "setAlertMessages";

// mutation types
export const PUSH_ALERT_MESSAGES = "pushAlertMessages";
export const CLEAR_ALERT_MESSAGES = "clearAlertMessages";

const state = {
  successMessages: [],
  errorMessages: []
};

const getters = {
  successMessages(state) {
    return state.successMessages;
  },
  errorMessages(state) {
    return state.errorMessages;
  }
};

const actions = {
  [SET_ALERT_MESSAGES](context, { successMessages, errorMessages }) {
    context.commit(PUSH_ALERT_MESSAGES, { successMessages, errorMessages });
    setTimeout(function() {
      context.commit(CLEAR_ALERT_MESSAGES);
    }, 5000);
  }
};

const mutations = {
  [PUSH_ALERT_MESSAGES](state, { successMessages, errorMessages }) {
    if (successMessages) {
      state.successMessages = successMessages;
    }
    if (errorMessages) {
      state.errorMessages = errorMessages;
    }
  },
  [CLEAR_ALERT_MESSAGES](state) {
    state.successMessages = [];
    state.errorMessages = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
