import httpService from "@/core/services/http.service.js";
import userMapper from "@/core/services/helpers/userMapper.js";
import i18nService from "../i18n.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const SET_COOKIE = "setCookie";
export const CLEAR_ERRORS = "clearErrors";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: [],
  user: {},
  isAuthenticated: localStorage.getItem("isAuth") === "true" ? true : false
  //isAuthenticated: true
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated && localStorage.getItem("isAuth") === "true"
      ? true
      : false;
  }
};

const actions = {
  [SET_COOKIE]() {
    return httpService.get("/sanctum/csrf-cookie");
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      context
        .dispatch(SET_COOKIE)
        .then(() => {
          httpService
            .post("api/b2b/login", credentials)
            .then(response => {
              context.dispatch(VERIFY_AUTH);
              resolve(response);
            })
            .catch(error => {
              context.commit(SET_ERROR, [error.response.data.message]);
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [VERIFY_AUTH](context) {
    return httpService
      .get("/api/user")
      .then(response => {
        context.commit(SET_AUTH, response.data.data);
      })
      .catch(() => {
        context.commit(PURGE_AUTH);
      });
  },
  [LOGOUT](context) {
    return new Promise((resolve, reject) => {
      httpService
        .post("/logout")
        .then(response => {
          context.commit(PURGE_AUTH);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CLEAR_ERRORS](context) {
    context.commit(SET_ERROR, {});
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    // Store authenticated user
    state.isAuthenticated = true;
    localStorage.setItem("isAuth", true);
    state.user = userMapper.mapUser(user);
    state.errors = {};
    
    // Set language based on user industry
    i18nService.loadLanguageAsync(
      i18nService.getActiveLanguage(), 
      state.user.companyIndustry
    );
  },
  [PURGE_AUTH](state) {
    localStorage.setItem("isAuth", false);
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
