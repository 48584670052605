import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import offcanvas from "./offcanvas.module";
import places from "./places.module";
import clients from "./clients.module";
import form from "./form.module";
import staff from "./staff.module";
import services from "./services.module";
import serviceCategories from "./serviceCategories.module";
import searchEngineTypes from "./searchEngineTypes.module";
import statistic from "./statistic.module";
import alert from "./alert.module";
import company from "./company.module";
import category from "./category.module";
import widget from "./widget.module";
import subjects from "./subjects.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    offcanvas,
    places,
    clients,
    form,
    staff,
    services,
    serviceCategories,
    searchEngineTypes,
    statistic,
    alert,
    company,
    category,
    widget,
    subjects,
  }
});
