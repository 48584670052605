import httpService from "@/core/services/http.service.js";
import serviceMapper from "@/core/services/helpers/serviceMapper.js";

// action types
export const GET_SERVICES = "getServices";

// mutations types
export const SET_SERVICES = "setServices";

const state = {
  services: []
};

const getters = {
  allServices(state) {
    return state.services;
  },
  getService: state => id => state.services.find(service => service.id === id),
  getPlaceServices: state => placeId =>
    state.services.filter(service => service.placeId === placeId)
};

const actions = {
  [GET_SERVICES](context) {
    return httpService
      .get("/services")
      .then(response => context.commit(SET_SERVICES, response.data));
  }
};

const mutations = {
  [SET_SERVICES](state, services) {
    state.services = serviceMapper.mapServices(services);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
