import _ from "lodash";

const widgetMapper = {
  /**
   * Map widget object
   * @param {Object} widget
   * @return {Object}
   */
  mapWidget: function(widget) {
    return _.mapKeys(widget, (value, key) => _.camelCase(key));
  }
};

export default widgetMapper;
