import httpService from "@/core/services/http.service.js";
import clientMapper from "@/core/services/helpers/clientMapper.js";

// action types
export const GET_CLIENTS = "getClients";

// mutations types
export const SET_CLIENTS = "setClients";

const state = {
  clients: []
};

const getters = {
  allClients(state) {
    return state.clients;
  },
  getClient: state => id => state.clients.find(client => client.id === id),
  clientsAsOptions(state) {
    return clientMapper.mapClientsAsOptions(state.clients);
  }
};

const actions = {
  [GET_CLIENTS](context) {
    httpService.get("/api/b2b/client-profiles").then(response => {
      context.commit(SET_CLIENTS, response.data.data);
    });
  }
};

const mutations = {
  [SET_CLIENTS](state, clients) {
    state.clients = clientMapper.mapClients(clients);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
