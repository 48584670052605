import httpService from "@/core/services/http.service.js";
import searchEngineTypeMapper from "@/core/services/helpers/searchEngineTypeMapper.js";

// actions
export const GET_SEARCH_ENGINE_TYPES = "getSearchEngineTypes";

// mutations
export const SET_SEARCH_ENGINE_TYPES = "setSearchEngineTypes";

const state = {
  searchEngineTypes: []
};

const getters = {
  allSearchEngineTypes(state) {
    return state.searchEngineTypes;
  }
};

const actions = {
  [GET_SEARCH_ENGINE_TYPES](context) {
    httpService
      .get("/api/b2b/services/search-engine-types")
      .then(response =>
        context.commit(SET_SEARCH_ENGINE_TYPES, response.data.data)
      );
  }
};

const mutations = {
  [SET_SEARCH_ENGINE_TYPES](state, searchEngineTypes) {
    state.searchEngineTypes = searchEngineTypeMapper.mapSearchEngineTypes(
      searchEngineTypes
    );
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
