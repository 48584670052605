import i18n from "../plugins/vue-i18n";

const i18nService = {
  defaultLanguage: "de",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "sr",
      name: "Serbian",
      flag: process.env.BASE_URL + "media/svg/flags/071-serbia.svg"
    },
    {
      lang: "de",
      name: "Deutsch",
      flag: process.env.BASE_URL + "media/svg/flags/017-germany.svg"
    },
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  },

  loadLanguageAsync(lang, type) {
  // If the language hasn't been loaded yet
  if(type == 'LAW') {
    return import(/* webpackChunkName: "lang-[request]" */ `@/core/config/i18n/${lang}.js`).then(
      messages => {
        i18n.setLocaleMessage(lang, messages.law)
      }
    )
  }

  return import(/* webpackChunkName: "lang-[request]" */ `@/core/config/i18n/${lang}.js`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.locale)
    }
  )
}
};

export default i18nService;
