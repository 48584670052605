import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as sr } from "@/core/config/i18n/sr";
import { locale as de } from "@/core/config/i18n/de";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, sr, de };

// get current selected language
const lang = localStorage.getItem("language") || "de";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;