import httpService from "@/core/services/http.service.js";
import companyMapper from "@/core/services/helpers/companyMapper.js";

// action types
export const GET_COMPANY = "getCompany";

// mutation types
export const SET_COMPANY = "setCompany";

const state = {
  company: null
};

const getters = {
  myCompany() {
    return state.company;
  }
};

const actions = {
  [GET_COMPANY](context) {
    httpService.get("/api/b2b/company").then(response => {
      context.commit(SET_COMPANY, response.data.data);
    });
  }
};

const mutations = {
  [SET_COMPANY](state, company) {
    state.company = companyMapper.mapCompany(company);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
