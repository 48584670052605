import _ from "lodash";

const serviceMapper = {
  /**
   * Map array of service objects
   * @param {Array} services
   * @return {Array}
   */
  mapServices: function(services) {
    return services.map(service => {
      return this.mapService(service);
    });
  },

  /**
   * Map service object
   * @param {Object} service
   * @return {Object}
   */
  mapService: function(service) {
    return _.mapKeys(service, (value, key) => _.camelCase(key));
  },

  /**
   * Map array of service objects to options for select component
   * @param {Array} services
   * @return {Array}
   */
  mapServicesAsOptions(services) {
    let options = [];
    services.forEach(service => {
      let option = {};
      option.text = service.name;
      option.value = service.id;
      option.description = service.duration + " minutes";
      option.price = service.price + " RSD";
      option.fullName =
        service.name +
        ", " +
        service.duration +
        " min, " +
        service.price +
        " RSD";
      options.push(option);
    });
    return options;
  }
};

export default serviceMapper;
