import httpService from "@/core/services/http.service.js";
import staffMapper from "@/core/services/helpers/staffMapper.js";

// action types
export const GET_STAFF = "getStaff";

// mutations types
export const SET_STAFF = "setStaff";

const state = {
  staff: []
};

const getters = {
  allStaff(state) {
    return state.staff;
  },
  getStaffMember: state => id =>
    state.staff.find(staffMember => staffMember.id === id)
};

const actions = {
  [GET_STAFF](context) {
    return httpService
      .get("/staff")
      .then(response => context.commit(SET_STAFF, response.data));
  }
};

const mutations = {
  [SET_STAFF](state, staff) {
    state.staff = staffMapper.mapStaff(staff);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
