import httpService from "@/core/services/http.service.js";
import statisticMapper from "@/core/services/helpers/statisticMapper.js";

// action types
export const GET_STATISTIC = "getStatistic";

// mutation types
export const SET_STATISTIC = "setStatistic";

const state = {
  statistic: {}
};

const getters = {
  currentStatistic() {
    return state.statistic;
  }
};

const actions = {
  [GET_STATISTIC](context, { placeId, year, month, day }) {
    const params = {
      place_id: placeId,
      year: year,
      month: month,
      day: day
    };
    return httpService
      .get("api/b2b/analytics", { params: params })
      .then(response => context.commit(SET_STATISTIC, response.data.data));
  }
};

const mutations = {
  [SET_STATISTIC](state, statistic) {
    state.statistic = statisticMapper.mapStatistic(statistic);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
