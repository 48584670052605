import httpService from "@/core/services/http.service.js";
import subjectMapper from "@/core/services/helpers/subjectMapper.js";

// action types
export const GET_SUBJECTS = "getSubjects";

// mutations types
export const SET_SUBJECTS = "setSubjects";

const state = {
  subjects: []
};

const getters = {
  allSubjects(state) {
    return state.subjects;
  },
  getSubject: state => id => state.subjects.find(
    subject => subject.id === id
  ),
  subjectsAsOptions(state) {
    return subjectMapper.mapSubjectsAsOptions(state.subjects);
  }
};

const actions = {
  [GET_SUBJECTS](context) {
    httpService.get("/api/b2b/subjects").then(response => {
      context.commit(SET_SUBJECTS, response.data.data);
    });
  }
};

const mutations = {
  [SET_SUBJECTS](state, subjects) {
    state.subjects = subjectMapper.mapSubjects(subjects);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
