// action types
export const OPEN_ELEMENT = "openElement";
export const CLOSE_ELEMENT = "closeElement";

// mutation types
export const OPEN_OFFCANVAS = "openOffcanvas";
export const CLOSE_OFFCANVAS = "closeOffcanvas";

const state = {
  isOffcanvasOpen: false,
  openedElements: []
};

const getters = {
  isOffcanvasOpen(state) {
    return state.isOffcanvasOpen;
  },
  openedElements(state) {
    return state.openedElements;
  }
};

const actions = {
  [OPEN_ELEMENT](context, elementId) {
    context.commit(OPEN_OFFCANVAS, elementId);
  },
  [CLOSE_ELEMENT](context, elementId) {
    context.commit(CLOSE_OFFCANVAS, elementId);
  }
};

const mutations = {
  [OPEN_OFFCANVAS](state, elementId) {
    state.openedElements.push(elementId);
    state.isOffcanvasOpen = true;
  },
  [CLOSE_OFFCANVAS](state, elementId) {
    let elements = state.openedElements.filter(
      element => element !== elementId
    );
    state.openedElements = elements;
    if (state.openedElements.length < 1) {
      state.isOffcanvasOpen = false;
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
