import _ from "lodash";

const userMapper = {
  /**
   * Map array of user objects
   * @param {Array} users
   * @return {Array}
   */
  mapUsers: function(users) {
    return users.map(user => {
      return this.mapClient(user);
    });
  },

  /**
   * Map user object
   * @param {Object} user
   * @return {Object}
   */
  mapUser: function(user) {
    user.fullName = user.first_name + " " + user.last_name;
    user.initials =
      user.first_name.charAt(0).toUpperCase() +
      user.last_name.charAt(0).toUpperCase();
    user.isInLawIndustry = user.company_industry == 'LAW'
    return _.mapKeys(user, (value, key) => _.camelCase(key));
  }
};

export default userMapper;
