import httpService from "@/core/services/http.service.js";
import widgetMapper from "@/core/services/helpers/widgetMapper.js";

// action types
export const GET_WIDGET = "getWidget";

// mutation types
export const SET_WIDGET = "setWidget";

const state = {
  widget: null
};

const getters = {
  myWidget() {
    return state.widget;
  }
};

const actions = {
  [GET_WIDGET](context) {
    httpService.get("/api/b2b/company/widget-settings").then(response => {
      context.commit(SET_WIDGET, response.data.data);
    });
  }
};

const mutations = {
  [SET_WIDGET](state, widget) {
    state.widget = widgetMapper.mapWidget(widget);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
